import * as React from 'react';
import { Form, Container } from '../../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, BackButton } from '../common';
import { addAccessibilityMessage } from '../../actions/app';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';

interface IDispatchProps {
  push: typeof push;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.noInventory')
    );
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.noInventory.title')} />
        <BackButton />
        <Form>
          <Heading>{props.t('screens.noInventory.heading')}</Heading>
          <p>{props.t('screens.noInventory.paragraph')}</p>
          <Button
            type="button"
            onClick={() => {
              props.push(props.t('paths.finish'));
            }}
          >
            {props.t('buttons.continue')}
          </Button>
        </Form>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ addAccessibilityMessage, push }, dispatch);
};

export const NoInventory = withNamespaces()(
  connect<{}, IDispatchProps>(null, mapDispatchToProps)(Component)
);
