import * as React from 'react';
import { IIsp } from '../actions/api';
import { WithNamespaces, withNamespaces } from 'react-i18next';

interface ISiteFirstCardProps {
  isp: IIsp;
  province: string;
  overrideLocale: string;
}

function SiteFirstCardImpl(props: ISiteFirstCardProps & WithNamespaces) {
  const { isp, province, overrideLocale, t: originalT } = props;
  const locale = overrideLocale === 'fr' ? 'fr' : 'en';
  const t = (key: string, options?: Record<string, string>) =>
    originalT(key, { ...options, lng: overrideLocale });

  const hideElement = province === 'QC' && isp.id === 'telus';
  return (
    <div>
      {!hideElement && (
        <p>
          <a href={isp.url[locale]}>
            {t(getSiteText(isp.id), {
              name: isp.name,
            })}
          </a>
        </p>
      )}
      <p>
        {!hideElement && t('screens.finish.or')}{' '}
        {!hideElement
          ? t('screens.finish.siteFirstCard.call')
          : t('screens.finish.numberFirstCard.call')}{' '}
        <b>
          <a href={`tel:+${isp.phone[locale]}`}>{isp.phone[locale]}</a>{' '}
        </b>
      </p>
    </div>
  );
}

function getSiteText(ispId: string) {
  switch (ispId) {
    case 'telus':
      return 'screens.finish.orderOnline';
    default:
      return 'screens.finish.siteFirstCard.visitWebsite';
  }
}

export const SiteFirstCard = withNamespaces()(SiteFirstCardImpl);
