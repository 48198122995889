import { combineEpics } from 'redux-observable';
import { changeAccessibilityMessageEpic } from './accessibility';
import {
  listAllIspsEpic,
  listIspsEpic,
  verifyPinEpic,
  verifyAddressEpic,
  saveApplicationEpic,
  notifyUsersEpic,
  freeComputerEpic,
} from './api';
import {
  verifyPinTransistion,
  verifyAddressTransistion,
  orderFreeComputerTransistion,
} from './transistions';
import { IAppState } from '../reducers';

export const rootEpic = combineEpics<
  IStandardAction,
  IStandardAction,
  IAppState
>(
  changeAccessibilityMessageEpic,
  listAllIspsEpic,
  listIspsEpic,
  saveApplicationEpic,
  verifyPinEpic,
  verifyAddressEpic,
  freeComputerEpic,
  verifyPinTransistion,
  verifyAddressTransistion,
  notifyUsersEpic,
  orderFreeComputerTransistion
);
