import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Container, Form, Message } from '../../semantic';
import MaskedInput from 'react-text-mask';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';

import { mask } from '../../constants';
import { Button, BackButton } from '../common';
import {
  verifyPin,
  verifyPinValid,
  verifyPinInvalid,
  verifyPinFail,
} from '../../actions/api';
import letterImage from './cfsc-letter-code.png';
import letterImage2x from './cfsc-letter-code@2x.png';
import { IAppState, IRequestState } from '../../reducers';
import { addAccessibilityMessage } from '../../actions/app';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';

interface IStateProps {
  pin?: string;
  verifyPinRequest: IRequestState;
}

interface IDispatchProps {
  addAccessibilityMessage: typeof addAccessibilityMessage;
  verifyPin: typeof verifyPin;
  verifyPinValid: typeof verifyPinValid;
  verifyPinInvalid: typeof verifyPinInvalid;
  verifyPinFail: typeof verifyPinFail;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

interface IFormValues {
  pin: string;
}

class Component extends React.Component<Props, {}> {
  public componentDidMount() {
    this.props.addAccessibilityMessage(this.props.t('aria.messages.pinEntry'));
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.pinEntry.title')} />
        <BackButton />

        <Formik<IFormValues>
          initialValues={{
            pin: props.pin || '',
          }}
          onSubmit={(values) => {
            props.addAccessibilityMessage(
              props.t('aria.messages.pinEntrySubmit')
            );
            props.verifyPin(values.pin);
          }}
          validate={(values) => {
            const errors: { pin?: string } = {};
            const pinWithNoSpaces = values.pin.replace(/ /g, '');

            if (!values.pin) {
              errors.pin = props.t('formValidation.mustEnterCode');
            } else if (
              pinWithNoSpaces.length !== 8 ||
              !['A', 'B'].includes(pinWithNoSpaces.slice(0, 1).toUpperCase())
            ) {
              errors.pin = props.t('formValidation.pinFormat');
            }
            return errors;
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <Form
                size="large"
                error={true}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Form.Field>
                  <Heading>
                    <label htmlFor="pin">
                      {props.t('screens.pinEntry.prompt.header')}
                    </label>
                  </Heading>
                  <ul>
                    <li>
                      <Trans i18nKey="screens.pinEntry.prompt.paragraph1">
                        If your code is 9 characters,{' '}
                        <b>only enter the first 8 characters</b>.
                      </Trans>
                    </li>
                    <li>{props.t('screens.pinEntry.prompt.paragraph2')}</li>
                  </ul>

                  <MaskedInput
                    mask={mask}
                    guide={false}
                    id="pin"
                    placeholder=""
                    type="text"
                    value={values.pin.toUpperCase()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-label={props.t('screens.pinEntry.aria.codeInput')}
                    aria-required={true}
                  />
                  <Message
                    error={true}
                    aria-live="polite"
                    content={errors.pin}
                  />
                </Form.Field>

                {!errors.pin && (
                  <Message
                    error={true}
                    aria-live="polite"
                    content={
                      props.verifyPinRequest.error
                        ? props.t('errors.serverErrorText')
                        : null
                    }
                  />
                )}
                {!errors.pin && (
                  <Message
                    error={true}
                    aria-live="polite"
                    content={
                      props.verifyPinRequest.invalid
                        ? props.t('formValidation.pinInvalid')
                        : null
                    }
                  />
                )}

                <Button loading={props.verifyPinRequest.loading} type="submit">
                  {props.t('buttons.continue')}
                </Button>
              </Form>
            );
          }}
        </Formik>

        <div className="letter-image-container">
          <img
            src={letterImage}
            srcSet={`${letterImage}, ${letterImage2x} 2x`}
            width="375"
            height="350"
            alt={props.t('general.letterImage')}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    pin: state.pin,
    verifyPinRequest: state.verifyPinRequest,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      addAccessibilityMessage,
      verifyPin,
      verifyPinFail,
      verifyPinInvalid,
      verifyPinValid,
    },
    dispatch
  );
};

export const PinEntry = withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
