import * as React from 'react';
import { withNamespaces, Trans } from 'react-i18next';

function Component() {
  // This component is extracted into a shared component because it is used on
  // the Computer screen as well as the Finish screen and the prettier auto-
  // formatting resulted in different element indices for the <b> tags between
  // the two pages. This could be fixed better using react-i18next 11.x.
  return (
    <Trans i18nKey="screens.computer.paragraph1">
      Through the <b>Connecting Families</b> initiative, you are also eligible
      to receive a free refurbished desktop computer from the{' '}
      <b>Government of Canada</b>.
    </Trans>
  );
}

export const FreeComputerParagraph = withNamespaces()(Component);
