import * as React from 'react';
import { IFreeComputerInfo } from '../actions/api';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import './AddressBubble.css';

interface IFreeComputerInfoBubbleProps {
  freeComputerInfo: IFreeComputerInfo;
}

export const FreeComputerInfoBubbleImpl = (
  props: IFreeComputerInfoBubbleProps & WithNamespaces
) => {
  const { freeComputerInfo, t } = props;
  const {
    firstName,
    lastName,
    line1,
    line2,
    city,
    province,
    postalCode,
  } = freeComputerInfo;

  const fullProvince = t(`provinces.${province}`);

  return (
    <div className="address-bubble">
      {firstName} {lastName}
      <br />
      {line1}
      <br />
      {line2 && (
        <React.Fragment>
          {line2}
          <br />
        </React.Fragment>
      )}
      {`${city}, `}
      <span aria-hidden="true">{province}</span>
      <span className="screen-reader-only">{fullProvince}</span>
      {`, ${postalCode}`}
    </div>
  );
};

export const FreeComputerInfoBubble = withNamespaces()(
  FreeComputerInfoBubbleImpl
);
