import { en, fr } from '../translations/translations';
import { IAddress } from '../actions/api';
import * as objectPath from 'object-path';
// The default include has a strange object structure so explicitly state the file we want
import { containsPoBox } from 'po-box/dist/pobox';
import { provincePostalPrefixes } from '../constants';

export function getAllTranslations(key: string): string[] {
  const englishTranslation: string = objectPath.get(en, key);
  const frenchTranslation: string = objectPath.get(fr, key);
  return [englishTranslation, frenchTranslation];
}

export function postalMatchesProvince(
  postalCode: string,
  province: string
): boolean {
  const firstChar = postalCode.slice(0, 1).toUpperCase();
  const postalPrefixes = provincePostalPrefixes[province];
  return postalPrefixes.includes(firstChar);
}

const FR_PO_BOX_PATTERN = /(?:c\.?\s*p\.?|case\s+postale)\s+\d+/i;
export function isPOBox(address: IAddress): boolean {
  return (
    containsPoBox(address.line1) ||
    FR_PO_BOX_PATTERN.test(address.line1) ||
    (!!address.line2 &&
      (containsPoBox(address.line2) || FR_PO_BOX_PATTERN.test(address.line2)))
  );
}

export function getTranslation(en: string, fr?: string) {
  return fr !== undefined ? { en, fr } : { en, fr: en };
}
