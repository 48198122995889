import * as React from 'react';
import { trackAnalyticsPageView } from '../util/analytics';

function WithGAPageView<P extends object>(
  Component: React.ComponentType<P>,
  pathname: string
) {
  return class extends React.Component<P> {
    public componentDidMount() {
      trackAnalyticsPageView(pathname);
    }
    public render() {
      return <Component {...this.props} />;
    }
  };
}

export default WithGAPageView;
