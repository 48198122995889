import * as React from 'react';
import { Component, ReactNode, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Heading } from './Heading';

interface IErrorBoundaryProps {
  children?: ReactNode;
}

interface IErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundaryImpl extends Component<
  IErrorBoundaryProps & WithNamespaces,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps & WithNamespaces) {
    super(props);
    this.state = { error: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, (errorInfo as any)[key]);
      });
    });
    Sentry.captureException(error);
  }

  public render() {
    if (this.state.error) {
      return (
        <div>
          <Heading>{this.props.t('errors.title')}</Heading>
          <p>{this.props.t('errors.unexpectedError')}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withNamespaces()(ErrorBoundaryImpl);
