import * as React from 'react';
import { Formik, FormikErrors } from 'formik';
import { isMobilePhone } from 'validator';
import { Form } from '../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IContactInfo } from '../actions/app';
import { Button } from './common';
import {
  ContactFormInputs,
  validateEmailAndConfirmationEmail,
} from './ContactFormInputs';
import { TFunction } from 'i18next';

type IFormValues = IContactInfo;

interface IProps extends WithNamespaces {
  initialValues: IFormValues;
  onSubmit: (values: IFormValues) => void;
  submitButtonText: string;
  requireInput?: boolean;
  confirmEmailError: any | undefined;
}

interface IState {
  showEmailPasteWarning: boolean;
}

class ContactFormImpl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showEmailPasteWarning: false,
    };
    this.handleEmailPaste = this.handleEmailPaste.bind(this);
  }

  public handleEmailPaste() {
    this.setState({ showEmailPasteWarning: true });
  }

  public render() {
    const { props } = this;
    const { showEmailPasteWarning } = this.state;

    return (
      <Formik<IFormValues>
        initialValues={props.initialValues}
        onSubmit={(values) => {
          props.onSubmit({
            ...values,
            confirmEmail: values.confirmEmail || undefined,
            email: values.email || undefined,
            phone: values.phone || undefined,
          });
        }}
        validate={(values) => {
          this.setState({ showEmailPasteWarning: false });

          const errors: FormikErrors<IFormValues> = validateEmailAndConfirmationEmail(
            props.t,
            values,
            props.confirmEmailError
          );

          if (values.phone && !isMobilePhone(values.phone, 'en-CA')) {
            errors.phone = props.t('formValidation.phoneFormat');
          }

          // If it's invalid number return early before checking if number is Canadian
          if (errors.phone !== undefined) {
            return errors;
          }

          // If there are no other phone errors we check the area code
          if (values.phone && errors.phone === undefined) {
            const countryValidationError = validateCanadianPhoneNumber(
              props.t,
              values.phone
            );
            if (countryValidationError) errors.phone = countryValidationError;
          }

          return errors;
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setFieldError,
        }) => {
          const disabled =
            props.requireInput && !Boolean(values.email || values.phone);

          return (
            <Form
              error={true}
              size="large"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <ContactFormInputs
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                onEmailPaste={() => {
                  // clear existing validation error on paste
                  setFieldError('confirmEmail', '');
                  this.handleEmailPaste();
                }}
                showEmailPasteWarning={showEmailPasteWarning}
              />
              <Button
                loading={isSubmitting}
                disabled={disabled || isSubmitting}
                type="submit"
              >
                {props.submitButtonText}
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

function validateCanadianPhoneNumber(
  t: TFunction,
  phone: string
): string | undefined {
  const areaCode = phone.startsWith('+1')
    ? phone.slice(2, 5)
    : phone.startsWith('1')
    ? phone.slice(1, 4)
    : phone.slice(0, 3);

  if (!CANADIAN_AREAS_CODES_LIST.includes(areaCode)) {
    return t('formValidation.phoneCountry');
  }
}

export const ContactForm = withNamespaces()(ContactFormImpl);

// source: https://areaphonecodes.com/canada/,
// https://www.allareacodes.com/canadian_area_codes.htm,
// https://www.cnac.ca/area_code_maps/canadian_area_codes.htm

const CANADIAN_AREAS_CODES_LIST = [
  //Alberta
  '368',
  '403',
  '587',
  '780',
  '825',
  //BC
  '236',
  '250',
  '604',
  '672',
  '778',
  //Manitoba
  '204',
  '431',
  '584',
  //NB
  '428',
  '506',
  //NewFoundland and Labrador
  '709',
  //NS, PEI
  '782',
  '902',
  //Nunavut, Yukon, Northwest Territories
  '867',
  //Ontario,
  '226',
  '249',
  '289',
  '343',
  '365',
  '382',
  '416',
  '437',
  '519',
  '548',
  '613',
  '647',
  '683',
  '705',
  '742',
  '753',
  '807',
  '905',
  //Quebec
  '263',
  '354',
  '367',
  '418',
  '438',
  '450',
  '468',
  '514',
  '579',
  '581',
  '819',
  '873',
  //SK
  '306',
  '474',
  '639',
];
