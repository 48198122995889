import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { LogoBubble } from './LogoBubble';
import computerImage from './computer.svg';
import { Language } from '../typings/Language';

interface IISPCardProps {
  locale: Language;
  url: string;
}

function ComputerCardImpl(props: IISPCardProps & WithNamespaces) {
  const { locale, url, t: originalT } = props;
  const t = (key: string, options?: Record<string, string>) =>
    originalT(key, { ...options, lng: locale });

  return (
    <LogoBubble
      logo={
        <img
          src={computerImage}
          width="70"
          height="64"
          alt={t('screens.finish.computerImage')}
        />
      }
      type="offer"
    >
      <h4>{t('screens.finish.needComputer')}</h4>
      <p>{t('screens.finish.computerAvailable')}</p>
      <p>
        <a href={url} target="_blank" rel="noreferrer">
          {t('screens.finish.visitComputerProvider')}
        </a>
      </p>
    </LogoBubble>
  );
}

export const ComputerCard = withNamespaces()(ComputerCardImpl);
