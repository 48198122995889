import * as React from 'react';

import './ButtonLink.css';

interface IProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
}

export function ButtonLink(props: IProps) {
  return (
    <button
      className={
        props.className ? `button-link ${props.className}` : 'button-link'
      }
      onClick={props.onClick}
      type="button"
    >
      {props.children}
    </button>
  );
}
