import * as React from 'react';
import './Heading.css';

interface IHeadingProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  autoFocus?: boolean;
  children?: React.ReactNode;
}

export class Heading extends React.Component<
  IHeadingProps & React.HTMLAttributes<{}>
> {
  private el: HTMLElement | null;

  constructor(props: IHeadingProps) {
    super(props);
    this.el = null;
  }

  public componentDidMount() {
    const { autoFocus = true } = this.props;

    // Timeout needed for Safari :/
    if (autoFocus) {
      setTimeout(() => {
        if (this.el !== null) {
          this.el.focus();
        }
      }, 0);
    }
  }

  public render() {
    const { as = 'h1', children, ...other } = this.props;
    const Wrapper = as;
    return (
      <Wrapper
        className="heading--screen-reader-focusable"
        tabIndex={-1}
        ref={(el: HTMLElement | null) => {
          this.el = el;
        }}
        {...other}
      >
        {children}
      </Wrapper>
    );
  }
}
