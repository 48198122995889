import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import {
  removeAccessibilityMessage,
  ActionKeys,
  ActionTypes,
  IAddAccessibilityMessage,
} from '../actions/app';

// Clear status message after a small delay so if the same status message is
// set, it is re-read to the user.
export const changeAccessibilityMessageEpic = (
  action$: ActionsObservable<ActionTypes>
): Observable<IStandardAction> =>
  action$
    .ofType<IAddAccessibilityMessage>(ActionKeys.ADD_ACCESSIBILITY_MESSAGE)
    .pipe(
      delay(5000),
      map((action: IAddAccessibilityMessage) =>
        removeAccessibilityMessage(action.message, action.key)
      )
    );
