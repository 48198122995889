import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { goBack, push, replace, go } from 'connected-react-router';
import { Container } from '../../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { BackButton } from '../common';
import { IAppState, IRequestState } from '../../reducers';
import {
  orderFreeComputer,
  IFreeComputerInfo,
  IOrderFreeComputerAPIResponse,
} from '../../actions/api';
import {
  addAccessibilityMessage,
  addDisabledComputerProvince,
} from '../../actions/app';
import { HeadTitle } from '../HeadTitle';
import { ComputerAddressConfirmationForm } from '../ComputerAddressConfirmationForm';

interface IStateProps {
  canOrderFreeComputer: boolean;
  freeComputerInfo?: IFreeComputerInfo;
  isFreeComputerAvailable: boolean;
  orderFreeComputerRequest: IRequestState;
  priority: boolean;
  sufficientState: boolean;
}

interface IDispatchProps {
  addDisabledComputerProvince: typeof addDisabledComputerProvince;
  go: typeof go;
  goBack: typeof goBack;
  orderFreeComputer: typeof orderFreeComputer;
  push: typeof push;
  replace: typeof replace;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

interface IState {
  hasInvalidEmail: boolean;
  hasInvalidPhone: boolean;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      hasInvalidEmail: false,
      hasInvalidPhone: false,
    };
  }

  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.computerAddressConfirmation')
    );

    // If you have already redeemed your free computer or computers are no longer available go back to internet service question
    if (
      !this.props.canOrderFreeComputer ||
      !this.props.isFreeComputerAvailable
    ) {
      this.props.go(-3);
    }

    return;
  }

  public render() {
    const { props, state } = this;

    return (
      <Container text={true}>
        <HeadTitle
          title={props.t('screens.computerAddressConfirmation.title')}
        />
        <BackButton />
        <ComputerAddressConfirmationForm
          onSubmit={async (_, actions) => {
            if (
              props.freeComputerInfo &&
              props.canOrderFreeComputer &&
              !props.orderFreeComputerRequest.loading
            ) {
              props.addAccessibilityMessage(
                props.t('aria.messages.computerAddressConfirmationSubmit')
              );

              this.setState({ hasInvalidEmail: false, hasInvalidPhone: false });

              const {
                city,
                email,
                firstName,
                lastName,
                line1,
                line2,
                locale,
                phone,
                postalCode,
                province,
              } = props.freeComputerInfo;

              const res = (await new Promise((resolve, reject) => {
                props.orderFreeComputer(
                  {
                    city,
                    firstName,
                    lastName,
                    line1,
                    line2,
                    postalCode,
                    province,
                  },
                  props.priority,
                  locale,
                  email,
                  phone,
                  { resolve, reject }
                );
              })) as IOrderFreeComputerAPIResponse;

              if (res.code === 'invalid' && res.errors) {
                if (res.errors.includes('email')) {
                  this.setState({ hasInvalidEmail: true });
                }
                if (res.errors.includes('phone')) {
                  this.setState({ hasInvalidPhone: true });
                }
                if (res.errors.includes('inventory')) {
                  props.addDisabledComputerProvince(province);
                  props.push(props.t('paths.noInventory'));
                }

                // If we got an error, set form state submitting to false. If
                // we succeeded, the redux-observable epic will trigger a
                // redirect and we won't be able to update the form state for
                // an unmounted form.
                actions.setSubmitting(false);
              }
            }
          }}
          freeComputerInfo={props.freeComputerInfo}
          addressEditPath={props.t('paths.computerAddressEntry')}
          hasInvalidPhone={state.hasInvalidPhone}
          hasInvalidEmail={state.hasInvalidEmail}
          isLoading={props.orderFreeComputerRequest.loading}
          push={props.push}
        />
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.freeComputerInfo);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  const province = state.freeComputerInfo
    ? state.freeComputerInfo.province
    : '';

  const canOrderFreeComputer =
    state.canOrderFreeComputer &&
    !state.disabledComputerProvinces.includes(province);

  return {
    canOrderFreeComputer,
    freeComputerInfo: state.freeComputerInfo,
    isFreeComputerAvailable: state.isFreeComputerAvailable,
    orderFreeComputerRequest: state.orderFreeComputerRequest,
    priority: true,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      addAccessibilityMessage,
      addDisabledComputerProvince,
      go,
      goBack,
      orderFreeComputer,
      push,
      replace,
    },
    dispatch
  );
};

export const ComputerAddressConfirmation = withNamespaces()(
  connect<IStateProps, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
