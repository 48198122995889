import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Form, Loader } from '../../semantic';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';

import { Button, BackButton } from '../common';
import { AddressBubble } from '../AddressBubble';
import { listIsps, IAddress, IIsp, saveApplication } from '../../actions/api';
import {
  addAccessibilityMessage,
  setProviderInternetService,
  setProvider,
} from '../../actions/app';
import { IAppState, IRequestState } from '../../reducers';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import './InternetService.css';

interface IStateProps {
  listIspsRequest: IRequestState;
  sufficientState: boolean;
  mailingAddress?: IAddress;
  serviceAddress?: IAddress;
  availableIsps: IIsp[];
  pin?: string;
  applicationId?: string;
}

interface IDispatchProps {
  push: typeof push;
  addAccessibilityMessage: typeof addAccessibilityMessage;
  saveApplication: typeof saveApplication;
  setProvider: typeof setProvider;
  setProviderInternetService: typeof setProviderInternetService;
  listIsps: typeof listIsps;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    const { pin, mailingAddress, serviceAddress } = this.props;
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    if (pin && serviceAddress && mailingAddress) {
      this.props.listIsps(pin, mailingAddress, serviceAddress);
    }

    // If there is already an applicationId it probably means the user is trying to correct their service address.
    // In that case, preemptively save the application here because they might bail before the final screen.
    if (this.props.applicationId) {
      this.props.saveApplication();
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.internetService')
    );
    return;
  }

  public render() {
    const { props } = this;

    if (props.listIspsRequest.loading) {
      return (
        <Loader
          inline="centered"
          active={true}
          role="alert"
          aria-label="loading"
        />
      );
    }

    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.internetService.title')} />
        <BackButton />
        <Form>
          <Heading>{props.t('screens.internetService.prompt')}</Heading>

          {props.serviceAddress && (
            <AddressBubble address={props.serviceAddress} />
          )}
          <p>
            <Trans i18nKey="screens.internetService.paragraph1">
              Low-cost Internet is available to
              <strong>
                both new subscribers and those with Internet already installed
              </strong>
              . You will need to communicate this address to the Internet
              Service Provider when you call. Communicate
              <strong>this address</strong> to the Internet Service Provider
              when you call for $10 Internet.
            </Trans>
          </p>

          <Button
            type="button"
            onClick={() => {
              props.setProviderInternetService(true);
              props.push(props.t('paths.provider'));
            }}
          >
            {props.t('screens.internetService.affirmative')}
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.setProviderInternetService(false);
              props.setProvider('noInternet');
              // If they don't have internet and no providers are available for
              // new subscribers we send them to the no-isps page
              if (
                props.availableIsps.find(
                  (isp) => isp.displayType === 'show-all'
                )
              ) {
                props.push(props.t('paths.computer'));
              } else {
                props.push(props.t('paths.noIsps'));
              }
            }}
          >
            {props.t('screens.internetService.negative')}
          </Button>
        </Form>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.mailingAddress && state.serviceAddress);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    applicationId: state.applicationId,
    availableIsps: state.availableIsps,
    listIspsRequest: state.listIspsRequest,
    mailingAddress: state.mailingAddress,
    pin: state.pin,
    serviceAddress: state.serviceAddress,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      addAccessibilityMessage,
      listIsps,
      push,
      saveApplication,
      setProvider,
      setProviderInternetService,
    },
    dispatch
  );
};

export const InternetService = withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
