import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/browser';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { PersistGate } from 'redux-persist/integration/react';
import ReactModal from 'react-modal';

import { App } from './components/App';
import { MultilingualErrorBoundary } from './components/MultilingualErrorBoundary';
import { rootReducer, IAppState } from './reducers';
import { rootEpic } from './epics';
import { createSentryMiddleware } from './middleware/sentryForRedux';

import 'semantic-ui-theme/semantic/dist/semantic.min.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || '',
  environment: process.env.REACT_APP_ENVIRONMENT_NAME || 'development',
  ignoreErrors: ['deprecation', 'track.uc.cn'],
});

interface IStandardAction {
  type: string | symbol | any;
  payload?: any;
  error?: boolean | any;
  meta?: any;
}

const persistConfig = {
  blacklist: [
    'accessibilityMessage',
    'listIspsRequest',
    'verifyAddressRequest',
    'verifyPinRequest',
  ],
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware<
  IStandardAction,
  IStandardAction,
  IAppState
>();
const composeEnhancers = composeWithDevTools({});
const store = createStore(
  connectRouter(history)(persistedReducer),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      epicMiddleware,
      createSentryMiddleware(Sentry)
    )
  )
);

export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

ReactModal.setAppElement('#root');

ReactDOM.render(
  <MultilingualErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App history={history} />
      </PersistGate>
    </Provider>
  </MultilingualErrorBoundary>,
  document.getElementById('root') as HTMLElement
);
