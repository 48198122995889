import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Container, Divider } from '../semantic';
import { Language } from '../typings/Language';

import './About.css';

interface IOwnProps {
  locale: Language;
}

type Props = IOwnProps & WithNamespaces;

class Component extends React.Component<Props> {
  public render() {
    const { locale, t } = this.props;

    function abbr(code: string) {
      return (
        <abbr title={t(`abbreviations.name.${code}`)}>
          {t(`abbreviations.code.${code}`)}
        </abbr>
      );
    }

    const cfsc = abbr('CFSC');
    const opec = abbr('OPEC');
    const isp = abbr('ISP');

    const en = (
      <React.Fragment>
        <h1>About Connecting Families</h1>
        <p>
          Connecting Families is an initiative of the Government of Canada. It
          aims to help bridge digital divides for those who may struggle to
          afford home Internet. In partnership with Internet Service Providers (
          {isp}), the initiative offers home Internet services to eligible
          beneficiaries.
        </p>

        <p>
          This portal was designed by Computers for Success Canada ({cfsc}
          ), a not-for-profit organization serving Government of Canada digital
          inclusion programs.
        </p>

        <p>
          If you have questions or if you want to know more about the
          initiative,{' '}
          <a
            href="https://www.ic.gc.ca/eic/site/111.nsf/eng/home"
            target="_blank"
            rel="noreferrer"
          >
            visit this website
          </a>
          .
        </p>
        <Divider />
        <div className="site-about__additional">
          <p>
            <strong>For general information about the initiative</strong>
          </p>
          <p>
            <a
              href="https://ised-isde.canada.ca/site/connecting-families/en"
              target="_blank"
              rel="noreferrer"
            >
              Innovation, Science and Economic Development Canada (ISED)
            </a>
          </p>
          <p>
            Telephone (toll-free in Canada):{' '}
            <a href="tel:+1-800-328-6189">1 800 328 6189</a>
          </p>
          <p>
            TTY (for hearing-impaired):{' '}
            <a href="tel:+1-866-694-8389">1 866 694 8389</a>
          </p>
        </div>
        <div className="site-about__additional">
          <p>
            <strong>For technical, website, and code support</strong>
          </p>
          <p>
            <a
              href="https://ywcavan.org/connecting-families-tech-support"
              target="_blank"
              rel="noreferrer"
            >
              YWCA Metro Vancouver
            </a>
          </p>
          <p>
            Telephone (toll-free in Canada):{' '}
            <a href="tel:+1-833-206-0599">1 833 206 0599</a>
          </p>
        </div>
      </React.Fragment>
    );

    const fr = (
      <React.Fragment>
        <h1>À propos de Familles branchées</h1>
        <p>
          Familles branchées est une initiative du gouvernement du Canada, créée
          afin d'aider à combler le fossé numérique des personnes qui peuvent
          avoir des difficultés à obtenir de l'Internet résidentiel. En
          partenariat avec des fournisseurs de services internet ({isp}),
          l'initiative offre des services d'Internet résidentiel abordables aux
          personnes admissibles.
        </p>
        <p>
          Ce portail a été conçu par Ordinateurs pour l’excellence Canada (
          {opec}
          ), une organisation à but non lucratif qui soutient des programmes
          d’inclusion numérique du Gouvernement du Canada.
        </p>
        <p>
          Si vous avez des questions ou pour vous informer sur cette initiative,{' '}
          <a
            href="https://www.ic.gc.ca/eic/site/111.nsf/fra/accueil"
            target="_blank"
            rel="noreferrer"
          >
            visitez ce site Web
          </a>
          .
        </p>
        <Divider />
        <div className="site-about__additional">
          <p>
            <strong>Pour des informations générales sur l'initiative</strong>
          </p>
          <p>
            <a
              href="https://ised-isde.canada.ca/site/connecting-families/fr"
              target="_blank"
              rel="noreferrer"
            >
              Innovation, sciences et développement économique Canada (ISDE)
            </a>
          </p>
          <p>
            Téléphone (gratuit au Canada):{' '}
            <a href="tel:+1-800-328-6189">1 800 328 6189</a>
          </p>
          <p>
            ATS (pour les malentendants):{' '}
            <a href="tel:+1-866-694-8389">1 866 694 8389</a>
          </p>
        </div>
        <div className="site-about__additional">
          <p>
            <strong>
              Pour de l'assistance technique, et de l'assistance concernant le
              site web et le code
            </strong>
          </p>
          <p>
            <a
              href="https://ywcavan.org/connecting-families-tech-support"
              target="_blank"
              rel="noreferrer"
            >
              YWCA Metro Vancouver
            </a>
          </p>
          <p>
            Téléphone (sans frais au Canada) :{' '}
            <a href="tel:+1-833-206-0599">1 833 206 0599</a>
          </p>
        </div>
      </React.Fragment>
    );

    return (
      <Container text={true} className="site-about">
        {locale === 'en' ? en : fr}
      </Container>
    );
  }
}

export const About = withNamespaces()(Component);
