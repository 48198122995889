import * as React from 'react';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { Trans, WithNamespaces, withNamespaces } from 'react-i18next';
import { Form, Message } from '../semantic';
import { Button } from './common';
import { Heading } from './Heading';
import { FreeComputerInfoBubble } from './FreeComputerInfoBubble';
import { Link } from 'react-router-dom';
import { IFreeComputerInfo } from '../actions/api';
import { push } from 'connected-react-router';

interface IProps extends WithNamespaces {
  freeComputerInfo?: IFreeComputerInfo;
  isLoading: boolean;
  onSubmit: (values: {}, formikBag: FormikActions<{}>) => void;
  hasInvalidEmail: boolean;
  hasInvalidPhone: boolean;
  addressEditPath: string;
  push: typeof push;
}

class ComputerAddressConfirmationFormImpl extends React.Component<IProps> {
  public render() {
    const { props } = this;

    return (
      <Formik<{}, {}> initialValues={{}} onSubmit={props.onSubmit}>
        {({ handleSubmit, isSubmitting }) => {
          // email for error message
          const email =
            (props.freeComputerInfo && props.freeComputerInfo.email) || '';

          // phone for error message
          const phone =
            (props.freeComputerInfo && props.freeComputerInfo.phone) || '';

          return (
            <Form onSubmit={handleSubmit} error={true}>
              <Heading>
                {props.t('screens.computerAddressConfirmation.prompt')}
              </Heading>
              <p>{props.t('screens.computerAddressConfirmation.paragraph1')}</p>
              {props.freeComputerInfo && (
                <FreeComputerInfoBubble
                  freeComputerInfo={props.freeComputerInfo}
                />
              )}
              {props.hasInvalidEmail && (
                <Message
                  error={true}
                  aria-live="polite"
                  content={
                    <Trans
                      i18nKey="errors.computerEmailInvalid"
                      values={{ email }}
                    >
                      We were unable to use the email address{' '}
                      <strong className="a">{{ email }}</strong> to place your
                      order. Please{' '}
                      <Link to={props.addressEditPath}>
                        verify your email address
                      </Link>{' '}
                      and try again.
                    </Trans>
                  }
                  role="alert"
                />
              )}
              {props.hasInvalidPhone && (
                <Message
                  error={true}
                  aria-live="polite"
                  content={
                    <Trans
                      i18nKey="errors.computerPhoneInvalid"
                      values={{ phone }}
                    >
                      We were unable to use the phone number{' '}
                      <strong>{{ phone }}</strong> to place your order. Please{' '}
                      <Link to={props.addressEditPath}>
                        verify your phone number
                      </Link>{' '}
                      and try again.
                    </Trans>
                  }
                  role="alert"
                />
              )}
              <Button
                loading={props.isLoading}
                disabled={isSubmitting}
                type="submit"
              >
                {props.t('screens.computerAddressConfirmation.affirmative')}
              </Button>
              <Button
                disabled={isSubmitting}
                type="button"
                onClick={() => {
                  props.push(props.addressEditPath);
                }}
              >
                {props.t('screens.computerAddressConfirmation.negative')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export const ComputerAddressConfirmationForm = withNamespaces()(
  ComputerAddressConfirmationFormImpl
);
