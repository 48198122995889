import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../reducers';

interface IStateProps {
  keys: string[];
  messages: string[];
}

type Props = IStateProps;

function AccessibilityMessageImpl(props: Props) {
  const { keys, messages } = props;
  return (
    <div
      className="screen-reader-only"
      aria-live="polite"
      aria-relevant="additions"
      role="status"
    >
      {messages.map((message, index) => (
        <p key={keys[index]}>{message}</p>
      ))}
    </div>
  );
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    keys: state.accessibilityMessageKeys,
    messages: state.accessibilityMessages,
  };
};

export const AccessibilityMessage = connect<IStateProps, {}, {}, IAppState>(
  mapStateToProps
)(AccessibilityMessageImpl);
