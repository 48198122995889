import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Container, Form } from '../../semantic';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { isPOBox } from '../../util';

import { Button, BackButton } from '../common';
import { IAddress } from '../../actions/api';
import { setServiceAddress, addAccessibilityMessage } from '../../actions/app';
import { IAppState } from '../../reducers';
import { postalCodeRegex } from '../../constants';
import { AddressFormInputs } from '../AddressFormInputs';
import { postalMatchesProvince } from '../../util';
import { HeadTitle } from '../HeadTitle';
import { Language } from '../../typings/Language';

interface IStateProps {
  address?: IAddress;
  locale: Language;
  sufficientState: boolean;
}

interface IDispatchProps {
  push: typeof push;
  setServiceAddress: typeof setServiceAddress;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

type IFormValues = IAddress;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.serviceAddressEntry')
    );
    return;
  }

  public render() {
    const { props } = this;

    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.termsAndConditions.title')} />
        <BackButton />
        <Formik<IFormValues>
          initialValues={{
            city: get(props, 'address.city', ''),
            line1: get(props, 'address.line1', ''),
            line2: get(props, 'address.line2', ''),
            postalCode: get(props, 'address.postalCode', ''),
            province: get(props, 'address.province', ''),
          }}
          onSubmit={(values) => {
            props.setServiceAddress(values);
            props.push(props.t('paths.internetService'));
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.line1) {
              errors.line1 = props.t('formValidation.line1Required');
            }
            if (isPOBox(values)) {
              errors.line2 = props.t(
                'formValidation.postOfficeBoxServiceExcluded'
              );
            }
            if (!values.city) {
              errors.city = props.t('formValidation.cityRequired');
            }
            if (!values.province) {
              errors.province = props.t('formValidation.provinceRequired');
            }
            if (!values.postalCode) {
              errors.postalCode = props.t('formValidation.postalCodeRequired');
            } else if (!postalCodeRegex.test(values.postalCode)) {
              errors.postalCode = props.t('formValidation.postalCodeInvalid');
            } else if (
              values.province &&
              !postalMatchesProvince(values.postalCode, values.province)
            ) {
              errors.postalCode = props.t(
                'formValidation.postalCodeMustMatchProvince'
              );
            }

            return errors;
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <Form
                size="large"
                error={true}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <h1>{props.t('screens.serviceAddressEntry.prompt')}</h1>

                <AddressFormInputs
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  noPoBox={false}
                  setFieldValue={setFieldValue}
                />

                <Button type="submit">
                  {props.t('screens.serviceAddressEntry.useThisAddress')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.mailingAddress);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    address: state.serviceAddress,
    locale: state.locale,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      addAccessibilityMessage,
      push,
      setServiceAddress,
    },
    dispatch
  );
};

export const ServiceAddressEntry = withNamespaces()(
  connect<IStateProps, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
