import access from './access.png';
import bell from './bell.png';
import bellAliant from './bell-aliant.png';
import bellMts from './bell-mts.png';
import ccap from './ccap.png';
import cogeco from './cogeco.png';
import rogers from './rogers.png';
import sasktel from './sasktel.png';
import shaw from './rogers.png';
import tbaytel from './tbaytel.png';
import telus from './telus.png';
import videotron from './videotron.png';
import hay from './hay.png';
import westman from './westman.png';
import novus from './novus.png';
import northwestel from './northwestel.png'; //tbu
import mornington from './mornington.png';
import csur from './csur.png';
import quadro from './quadro.png';
import beanfield from './beanfield.png';
import ruralNet from './rural-net.png';

import access2x from './access@2x.png';
import bell2x from './bell@2x.png';
import bellAliant2x from './bell-aliant@2x.png';
import bellMts2x from './bell-mts@2x.png';
import ccap2x from './ccap@2x.png';
import cogeco2x from './cogeco@2x.png';
import rogers2x from './rogers@2x.png';
import sasktel2x from './sasktel@2x.png';
import shaw2x from './rogers@2x.png';
import tbaytel2x from './tbaytel@2x.png';
import telus2x from './telus@2x.png';
import videotron2x from './videotron@2x.png';
import hay2x from './hay@2x.png';
import westman2x from './westman@2x.png';
import novus2x from './novus@2x.png';
import northwestel2x from './northwestel@2x.png';
import mornington2x from './mornington@2x.png';
import csur2x from './csur@2x.png';
import quadro2x from './quadro@2x.png';
import beanfield2x from './beanfield@2x.png';
import ruralNet2x from './rural-net@2x.png';

import videotronFrench from './videotron-french.png';
import videotron2xFrench from './videotron-french@2x.png';

export interface ILogosCollection {
  access: string;
  beanfield: string;
  bell: string;
  'bell-aliant': string;
  'bell-mts': string;
  ccap: string;
  cogeco: string;
  csur: string;
  hay: string;
  mornington: string;
  novus: string;
  northwestel: string;
  quadro: string;
  rogers: string;
  sasktel: string;
  shaw: string;
  tbaytel: string;
  telus: string;
  videotron: string;
  westman: string;
  ruralnet: string;
}

export const ispLogos: ILogosCollection = {
  access,
  beanfield,
  bell,
  'bell-aliant': bellAliant,
  'bell-mts': bellMts,
  ccap,
  cogeco,
  csur,
  hay,
  mornington,
  novus,
  northwestel,
  quadro,
  rogers,
  sasktel,
  shaw,
  tbaytel,
  telus,
  videotron,
  westman,
  ruralnet: ruralNet,
};

export const ispLogos2x: ILogosCollection = {
  access: access2x,
  beanfield: beanfield2x,
  bell: bell2x,
  'bell-aliant': bellAliant2x,
  'bell-mts': bellMts2x,
  ccap: ccap2x,
  cogeco: cogeco2x,
  csur: csur2x,
  hay: hay2x,
  mornington: mornington2x,
  novus: novus2x,
  northwestel: northwestel2x,
  quadro: quadro2x,
  rogers: rogers2x,
  sasktel: sasktel2x,
  shaw: shaw2x,
  tbaytel: tbaytel2x,
  telus: telus2x,
  videotron: videotron2x,
  westman: westman2x,
  ruralnet: ruralNet2x,
};

export const ispLogosFrench = {
  videotron: videotronFrench,
};

export const ispLogos2xFrench = {
  videotron: videotron2xFrench,
};
