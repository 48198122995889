import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { goBack, push, replace } from 'connected-react-router';
import { Container, Form } from '../../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { isPOBox } from '../../util';
import { Button, BackButton } from '../common';
import { IAppState } from '../../reducers';
import { IAddress } from '../../actions/api';
import { setServiceAddress } from '../../actions/app';
import { AddressBubble } from '../AddressBubble';
import { addAccessibilityMessage } from '../../actions/app';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';

interface IStateProps {
  address?: IAddress;
  sufficientState: boolean;
}

interface IDispatchProps {
  goBack: typeof goBack;
  push: typeof push;
  replace: typeof replace;
  setServiceAddress: typeof setServiceAddress;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.addressConfirmation')
    );

    // Dont allow access to this page if your address is a PO Box address
    if (this.props.address && isPOBox(this.props.address)) {
      this.props.replace(this.props.t('paths.serviceAddressEntry'));
    }
    return;
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.addressConfirmation.title')} />
        <BackButton />
        <Form>
          <Heading>{props.t('screens.addressConfirmation.prompt')}</Heading>
          {props.address && <AddressBubble address={props.address} />}
          <Button
            type="button"
            onClick={() => {
              if (props.address) {
                props.setServiceAddress(props.address);
              }
              props.push(props.t('paths.internetService'));
            }}
          >
            {props.t('screens.addressConfirmation.affirmative')}
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.push(props.t('paths.serviceAddressEntry'));
            }}
          >
            {props.t('screens.addressConfirmation.negative')}
          </Button>
        </Form>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.mailingAddress);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    address: state.mailingAddress,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    { addAccessibilityMessage, goBack, push, replace, setServiceAddress },
    dispatch
  );
};

export const AddressConfirmation = withNamespaces()(
  connect<IStateProps, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
