import * as React from 'react';
import { Icon } from '../../semantic';
import { ButtonLink } from './ButtonLink';

import './IconButtonLink.css';

interface IProps {
  iconName: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
}

export function IconButtonLink(props: IProps) {
  return (
    <ButtonLink
      className={
        props.className
          ? `icon-button-link ${props.className}`
          : 'icon-button-link'
      }
      onClick={props.onClick}
    >
      <Icon name={props.iconName as any} className="icon-link__icon" />
      <span className="icon-link__contents">{props.children}</span>
    </ButtonLink>
  );
}
