import * as React from 'react';
import { Container } from '../../semantic';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { BackButton } from '../common';
import { addAccessibilityMessage } from '../../actions/app';
import letterImage from './cfsc-letter.png';
import letterImage2x from './cfsc-letter@2x.png';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';

interface IDispatchProps {
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    this.props.addAccessibilityMessage(this.props.t('aria.messages.noLetter'));
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.noLetter.title')} />
        <BackButton />
        <div>
          <Heading>{props.t('screens.noLetter.heading')}</Heading>
          <p>{props.t('screens.noLetter.paragraph1')}</p>
        </div>
        <div className="letter-image-container">
          <img
            src={letterImage}
            srcSet={`${letterImage}, ${letterImage2x} 2x`}
            width="375"
            height="350"
            alt={props.t('general.letterImage')}
          />
        </div>
        <p>
          <Trans i18nKey="screens.noLetter.paragraph2">
            For more information, please visit the{' '}
            <a href={props.t('links.connectingFamiliesFaq')}>
              Government of Canada’s Connecting Families website
            </a>
            .
          </Trans>
        </p>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ addAccessibilityMessage }, dispatch);
};

export const NoLetter = withNamespaces()(
  connect<{}, IDispatchProps>(null, mapDispatchToProps)(Component)
);
