import * as React from 'react';
import { Container, Form } from '../../semantic';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { BackButton, Button } from '../common';
import { addAccessibilityMessage } from '../../actions/app';
import { IAppState } from '../../reducers';
import './Permission.css';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';

interface IStateProps {
  sufficientState: boolean;
}

interface IDispatchProps {
  push: typeof push;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.permission')
    );
    return;
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.permission.title')} />
        <BackButton />
        <Form>
          <Heading>{props.t('screens.permission.prompt')}</Heading>
          <p>{props.t('screens.permission.text.p1')}</p>
          <p>
            <Trans i18nKey="screens.permission.text.p2">
              On this website you will be able to find out if you are qualified
              to receive $10 or $20 per month Internet service. In order to
              determine if you are qualified, you will be asked to provide
              certain Personal Information*, as defined under
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>
              ’s Disclosure Policy.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="screens.permission.text.p3">
              At Computers For Success Canada - Ordinateurs pour l’excellence
              Canada (
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>
              ), a national nonprofit supported by the Government of Canada,
              your privacy is important to us, so we want you to know how we use
              your Personal Information.*
            </Trans>
          </p>

          <p>{props.t('screens.permission.text.p4')}</p>
          <p>{props.t('screens.permission.text.p5')}</p>
          <p>
            <Trans i18nKey="screens.permission.text.p6">
              All Personal Information you share with{' '}
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>{' '}
              will be used and disclosed with our Partner Organizations and
              Service Providers, as defined under{' '}
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>{' '}
              ’s Disclosure Policy (by “disclosed” we mean who we share this
              information with). If and when we share the information, we will
              always do that in compliance with our Disclosure Policy. You can
              review
              <a href={props.t('screens.permission.privacyLink')}>
                CFSC-OPEC’s full disclosure policy
              </a>
              .
            </Trans>
          </p>
          <p>
            <Trans i18nKey="screens.permission.text.p7">
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>{' '}
              follows the guidelines outlined in the Personal Information
              Protection and Electronics Document Act (
              <abbr title={props.t('abbreviations.name.PIPEDA')}>
                {props.t('abbreviations.code.PIPEDA')}
              </abbr>
              ) published by the Office of the Privacy Commissioner of Canada.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="screens.permission.text.p8">
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>
              has also adopted the guidelines outlined in General Data
              Protection Regulation 2016/679 (“GDPR”), which is a regulation in
              European Union law on data protection and privacy that applies in
              the European Union and the European Economic Area.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="screens.permission.text.p9">
              By agreeing, you signify agreement to the processing of Personal
              Information relating to yourself by{' '}
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>
              , and your agreement with{' '}
              <abbr title={props.t('abbreviations.name.CFSC')}>
                {props.t('abbreviations.code.CFSC')}
              </abbr>
              -
              <abbr title={props.t('abbreviations.name.OPEC')}>
                {props.t('abbreviations.code.OPEC')}
              </abbr>
              ’s Disclosure Policy and website{' '}
              <a href={props.t('screens.permission.privacyLink')}>
                Terms & Conditions
              </a>{' '}
              :
            </Trans>
          </p>

          <Button
            type="button"
            onClick={() => {
              props.push(props.t('paths.contactInfo'));
            }}
          >
            {props.t('screens.permission.affirmative')}
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.push(props.t('paths.noPermission'));
            }}
          >
            {props.t('screens.permission.negative')}
          </Button>
          <p className="personal-information-description">
            <small>{props.t('screens.permission.text.details')}</small>
          </p>
        </Form>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ addAccessibilityMessage, push }, dispatch);
};

export const Permission = withNamespaces()(
  connect<{}, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
