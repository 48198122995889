import * as React from 'react';

import './LogoBubble.css';

interface IProps {
  children?: any;
  logo?: any;
  type?: 'isp' | 'offer';
}

export const LogoBubble = (props: IProps) => {
  return (
    <div className="logo-bubble">
      {props.logo && (
        <div
          className={`logo-bubble__logo ${
            props.type === 'isp' && 'logo-bubble__logo--isp'
          }`}
        >
          {props.logo}
        </div>
      )}
      <div
        className={`logo-bubble__content ${
          props.type === 'isp' && 'logo-bubble__content--isp'
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};
