import { of, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import shuffleArray from 'shuffle-array';
import { ActionsObservable, StateObservable } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { replace } from 'connected-react-router';
import * as translations from '../translations/translations';
import { getAddressFromComputerInfo } from '../util/getAddressFromComputerInfo';

import { IAppState } from '../reducers';
import {
  ActionKeys,
  ActionTypes,
  IAPIError,
  INoMutationAPIResponse,
  IVerifyPinAPIResponse,
  IOrderFreeComputerAPIResponse,
  IListIspsAction,
  IIspAPIResponse,
  IVerifyPinAction,
  listIspsSuccess,
  listIspsFail,
  listIspsInvalid,
  verifyPinValid,
  verifyPinFail,
  verifyPinInvalid,
  IVerifyAddressAction,
  verifyAddressValid,
  verifyAddressFail,
  verifyAddressInvalid,
  ISaveApplicationAction,
  saveApplicationSuccess,
  saveApplicationFail,
  INotifyUsersResponse,
  notifyUsersSuccess,
  notifyUsersFail,
  IIsp,
  IOrderFreeComputerAction,
  orderFreeComputerValid,
  orderFreeComputerFail,
  orderFreeComputerInvalid,
  INotifyUsersAction,
  IListAllIspsAction,
} from '../actions/api';

function reportAPIError(error: IAPIError, endpoint: string): void {
  Sentry.withScope((scope) => {
    scope.setTag('endpoint', endpoint);
    scope.setFingerprint([endpoint, String(error.status), error.message]);
    Sentry.captureException(error);
  });
}

const VERIFY_PIN_PATH = '/pin/verify';
const verifyPinEndpoint = process.env.REACT_APP_API_BASE_URL + VERIFY_PIN_PATH;

export const verifyPinEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
): Observable<IStandardAction> =>
  action$.ofType<IVerifyPinAction>(ActionKeys.VERIFY_PIN).pipe(
    mergeMap((action: IVerifyPinAction) =>
      ajax
        .post(
          verifyPinEndpoint,
          { pin: action.pin },
          {
            'Accept-Language': state$.value.locale,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          delay(2000),
          map((event) => {
            const response: IVerifyPinAPIResponse = event.response;
            if (response.code === 'valid') {
              return verifyPinValid(response);
            }
            return verifyPinInvalid(response);
          }),
          catchError((error: IAPIError) => {
            reportAPIError(error, VERIFY_PIN_PATH);
            return of<ActionTypes>(verifyPinFail(error));
          })
        )
    )
  );

const VERIFY_ADDRESS_PATH = '/pin/verify-address';
const verifyAddressEndpoint =
  process.env.REACT_APP_API_BASE_URL + VERIFY_ADDRESS_PATH;

export const verifyAddressEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
): Observable<IStandardAction> =>
  action$.ofType<IVerifyAddressAction>(ActionKeys.VERIFY_ADDRESS).pipe(
    mergeMap((action: IVerifyAddressAction) =>
      ajax
        .post(
          verifyAddressEndpoint,
          { address: action.address, pin: state$.value.pin },
          {
            'Accept-Language': state$.value.locale,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          delay(2000),
          map((event) => {
            const response: INoMutationAPIResponse = event.response;
            if (response.code === 'valid') {
              return verifyAddressValid(response);
            }
            return verifyAddressInvalid(response);
          }),
          catchError((error: IAPIError) => {
            reportAPIError(error, VERIFY_ADDRESS_PATH);
            return of<ActionTypes>(verifyAddressFail(error));
          })
        )
    )
  );

const FREE_COMPUTER_PATH = '/pin/free-computer';
const freeComputerEndpoint =
  process.env.REACT_APP_API_BASE_URL + FREE_COMPUTER_PATH;

export const freeComputerEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
): Observable<IStandardAction> =>
  action$.ofType<IOrderFreeComputerAction>(ActionKeys.ORDER_FREE_COMPUTER).pipe(
    mergeMap((action: IOrderFreeComputerAction) =>
      ajax
        .post(
          freeComputerEndpoint,
          {
            address: action.address,
            email: action.email ? action.email : undefined,
            locale: action.locale + '-CA',
            phone: action.phone ? action.phone : undefined,
            pin: state$.value.pin,
            priority: action.priority,
          },
          {
            'Accept-Language': state$.value.locale,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          map((event) => {
            const response: IOrderFreeComputerAPIResponse = event.response;
            if (action.meta) {
              action.meta.resolve(response);
            }
            if (response.code === 'complete') {
              return orderFreeComputerValid(response);
            }
            return orderFreeComputerInvalid(response);
          }),
          catchError((error: IAPIError) => {
            reportAPIError(error, FREE_COMPUTER_PATH);
            if (action.meta) {
              action.meta.reject(error);
            }
            return of<ActionTypes>(orderFreeComputerFail(error));
          })
        )
    )
  );

const LIST_ALL_ISPS_PATH = '/application/list-all-isps';
const listAllIspsEndpoint =
  process.env.REACT_APP_API_BASE_URL + LIST_ALL_ISPS_PATH;

export const listAllIspsEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
): Observable<IStandardAction> =>
  action$.ofType<IListAllIspsAction>(ActionKeys.LIST_ALL_ISPS).pipe(
    mergeMap((action: IListAllIspsAction) =>
      ajax
        .post(listAllIspsEndpoint, {
          'Accept-Language': state$.value.locale,
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap((event) => {
            const response: IIspAPIResponse = event.response;
            return of<IStandardAction>(
              response.status === 'success'
                ? listIspsSuccess(response)
                : listIspsInvalid(response)
            );
          }),
          catchError((error: IAPIError) => {
            reportAPIError(error, LIST_ISPS_PATH);
            return of<ActionTypes>(listIspsFail(error));
          })
        )
    )
  );

const LIST_ISPS_PATH = '/application/list-isps';
const listIspsEndpoint = process.env.REACT_APP_API_BASE_URL + LIST_ISPS_PATH;

export const listIspsEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
): Observable<IStandardAction> =>
  action$.ofType<IListIspsAction>(ActionKeys.LIST_ISPS).pipe(
    mergeMap((action: IListIspsAction) =>
      ajax
        .post(
          listIspsEndpoint,
          {
            mailingAddress: action.mailingAddress,
            pin: action.pin,
            serviceAddress: action.serviceAddress,
          },
          {
            'Accept-Language': state$.value.locale,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          delay(2000),
          mergeMap((event) => {
            const response: IIspAPIResponse = event.response;
            if (response.status === 'success') {
              // randomize the order of the ISPs
              response.isps = shuffleArray(response.isps);
              const actions: any = [listIspsSuccess(response)];
              // If there are no available ISPs send user to no-isps page
              if (response.isps.length === 0) {
                actions.push(
                  replace(
                    translations[state$.value.locale === 'fr' ? 'fr' : 'en']
                      .paths.noIsps
                  )
                );
              }
              return of<IStandardAction>(...actions);
            }
            return of<IStandardAction>(listIspsInvalid(response));
          }),
          catchError((error: IAPIError) => {
            reportAPIError(error, LIST_ISPS_PATH);
            return of<ActionTypes>(listIspsFail(error));
          })
        )
    )
  );

const SAVE_APPLICATION_PATH = '/application/save';
const saveApplicationEndpoint =
  process.env.REACT_APP_API_BASE_URL + SAVE_APPLICATION_PATH;

export const saveApplicationEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
): Observable<IStandardAction> =>
  action$.ofType<ISaveApplicationAction>(ActionKeys.SAVE_APPLICATION).pipe(
    mergeMap((action: ISaveApplicationAction) =>
      ajax
        .post(
          saveApplicationEndpoint,
          {
            applicationId: state$.value.applicationId,
            // Strip out the extra fields on the IFreeComputerInfo interface.
            computerAddress: state$.value.freeComputerInfo
              ? getAddressFromComputerInfo(state$.value.freeComputerInfo)
              : undefined,
            contactInfo: state$.value.contactInfo || {},
            locale: state$.value.locale + '-CA',
            mailingAddress: state$.value.mailingAddress,
            pin: state$.value.pin,
            provider: state$.value.provider,
            providerInternet: state$.value.providerInternetService,
            serviceAddress: state$.value.serviceAddress,
          },
          {
            'Accept-Language': state$.value.locale,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          delay(2000),
          map((event) => {
            if (action.meta) {
              action.meta.resolve();
            }
            if (event.response.status === 'success') {
              return saveApplicationSuccess(event.response);
            }
            return saveApplicationFail();
          }),
          catchError((error: IAPIError) => {
            reportAPIError(error, SAVE_APPLICATION_PATH);
            if (action.meta) {
              action.meta.reject(error);
            }
            return of<ActionTypes>(saveApplicationFail());
          })
        )
    )
  );

const NOTIFY_USERS_PATH = '/application/notify';
const notifyUsersEndpoint =
  process.env.REACT_APP_API_BASE_URL + NOTIFY_USERS_PATH;

export const notifyUsersEpic = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
): Observable<IStandardAction> =>
  action$.ofType<INotifyUsersAction>(ActionKeys.NOTIFY_USERS).pipe(
    mergeMap((action: INotifyUsersAction) =>
      ajax
        .post(
          notifyUsersEndpoint,
          {
            address: state$.value.serviceAddress,
            availableProviders: state$.value.availableIsps.map(
              (provider: IIsp) => provider.id
            ),
            contactInfo: state$.value.contactInfo,
            locale: state$.value.locale + '-CA',
            pin: state$.value.pin,
            provider: state$.value.provider,
          },
          {
            'Accept-Language': state$.value.locale,
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          delay(2000),
          map((event) => {
            const response: INotifyUsersResponse = event.response;
            if (action.meta) {
              action.meta.resolve();
            }
            if (response.status === 'success') {
              return notifyUsersSuccess();
            }
            return notifyUsersFail(event.response);
          }),
          catchError((error) => {
            const response: INotifyUsersResponse = error.response;
            reportAPIError(error, NOTIFY_USERS_PATH);
            if (action.meta) {
              action.meta.reject(error);
            }
            return of<ActionTypes>(notifyUsersFail(response));
          })
        )
    )
  );
