import React from 'react';
import { Form, Message } from '../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  FormikErrors,
  FormikHandlers,
  FormikHelpers as FormikActions,
} from 'formik';
import { IContactInfo } from '../actions/app';
import { TFunction } from 'i18next';
import { isEmail } from 'validator';

interface IOwnProps {
  values: IContactInfo;
  errors: FormikErrors<IContactInfo>;
  onEmailPaste: () => void;
  showEmailPasteWarning: boolean;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  introMessage?: string;
  setFieldValue: FormikActions<IContactInfo>['setFieldValue'];
}

type Props = IOwnProps & WithNamespaces;

class ContactFormInputsImpl extends React.Component<Props> {
  public render() {
    const {
      values,
      handleChange,
      handleBlur,
      t,
      errors,
      introMessage = 'addressEntry.ifYouHaveEmail',
      onEmailPaste,
      showEmailPasteWarning,
    } = this.props;

    return (
      <React.Fragment>
        <p className="contact-intro">{t(introMessage)}</p>
        <p className="contact-spam-warning">
          {t('screens.contactInfo.spamWarning')}
        </p>
        <Form.Field>
          <label htmlFor="email">{t('screens.contactInfo.emailLabel')}</label>
          <input
            id="email"
            maxLength={255}
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.email ? errors.email : null}
            role="alert"
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="confirmEmail">
            {t('screens.contactInfo.confirmEmailLabel')}
          </label>
          <input
            id="confirmEmail"
            maxLength={255}
            type="email"
            value={values.confirmEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            onPaste={onEmailPaste}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.confirmEmail ? errors.confirmEmail : null}
            role="alert"
          />
          <Message
            error={false}
            aria-live="polite"
            content={t('screens.contactInfo.emailPaste')}
            warning={true}
            visible={showEmailPasteWarning}
            role="alert"
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="phone">{t('screens.contactInfo.phoneLabel')}</label>
          <input
            id="phone"
            type="tel"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.phone ? errors.phone : null}
            role="alert"
          />
        </Form.Field>
      </React.Fragment>
    );
  }
}

export const ContactFormInputs = withNamespaces()(ContactFormInputsImpl);

export function validateEmailAndConfirmationEmail(
  t: TFunction,
  values: IContactInfo,
  confirmEmailError: any | undefined
) {
  const errors: FormikErrors<Pick<IContactInfo, 'email' | 'confirmEmail'>> = {};

  if (values.email && !isEmail(values.email)) {
    errors.email = t('formValidation.emailFormat');
  } else if (values.email && !values.confirmEmail) {
    errors.confirmEmail = t('formValidation.confirmEmailRequired');
  } else if (
    values.confirmEmail &&
    values.email &&
    values.confirmEmail.toLowerCase() !== values.email.toLowerCase()
  ) {
    errors.confirmEmail = confirmEmailError;
  }

  return errors;
}
