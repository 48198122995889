import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { push } from 'connected-react-router';
import { Container, Form, Image } from '../../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import { IIsp } from '../../actions/api';
import { setProvider, addAccessibilityMessage } from '../../actions/app';

import { IAppState } from '../../reducers';
import { Button, BackButton } from '../common';

import {
  ispLogos,
  ispLogos2x,
  ispLogosFrench,
  ispLogos2xFrench,
  ILogosCollection,
} from '../logos';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import { Language } from '../../typings/Language';

interface IStateProps {
  availableIsps: IIsp[];
  provider?: string;
  pin?: string;
  sufficientState: boolean;
  locale: Language;
}

interface IDispatchProps {
  push: typeof push;
  setProvider: typeof setProvider;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

interface IFormValues {
  provider: string;
}

type Props = IDispatchProps & IStateProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(this.props.t('aria.messages.provider'));
    return;
  }

  public render() {
    const { props } = this;

    let logos = ispLogos;
    let logos2x = ispLogos2x;
    const isFrench = props.locale === 'fr';
    if (isFrench) {
      logos = { ...logos, ...ispLogosFrench };
      logos2x = { ...logos2x, ...ispLogos2xFrench };
    }

    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.provider.title')} />
        <BackButton />
        <Formik<IFormValues>
          initialValues={{ provider: props.provider || '' }}
          onSubmit={(values, { setSubmitting }) => {
            props.addAccessibilityMessage(
              props.t('aria.messages.providerSubmit')
            );
            setSubmitting(false);
            props.setProvider(values.provider);
            props.push(`/${this.props.locale}/${props.t('paths.finish')}`);
          }}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
            return (
              <Form size="large" onSubmit={handleSubmit}>
                <Heading>
                  {props.t(`screens.provider.prompts.internet`)}
                </Heading>
                <Form.Group grouped={true} role="radiogroup">
                  {props.availableIsps.map((isp) => {
                    return (
                      <Form.Field key={isp.shortName} inline={true}>
                        <Form.Radio
                          id={isp.shortName}
                          value={isp.id}
                          className="inline-block"
                          checked={values.provider === isp.id}
                          onChange={(_, { value }) => {
                            setFieldValue('provider', value);
                          }}
                          aria-label={isp.name}
                        />
                        <label htmlFor={isp.shortName}>
                          <Image
                            alt={props.t(`providerChoices.${isp.shortName}`)}
                            src={logos[isp.shortName as keyof ILogosCollection]}
                            srcSet={`${
                              logos[isp.shortName as keyof ILogosCollection]
                            }, ${
                              logos2x[isp.shortName as keyof ILogosCollection]
                            } 2x`}
                            verticalAlign="middle"
                          />
                        </label>
                      </Form.Field>
                    );
                  })}
                  <Form.Field>
                    <Form.Radio
                      id="noneOfTheAbove"
                      label={props.t(`providerChoices.noneOfTheAbove`)}
                      value="noneOfTheAbove"
                      checked={values.provider === 'noneOfTheAbove'}
                      onChange={(_, { value }) => {
                        setFieldValue('provider', value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Radio
                      id="unsure"
                      label={props.t(`providerChoices.unsure`)}
                      value="unsure"
                      checked={values.provider === 'unsure'}
                      onChange={(_, { value }) => {
                        setFieldValue('provider', value);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Button
                  disabled={!values.provider}
                  loading={isSubmitting}
                  type="submit"
                >
                  {props.t('buttons.continue')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.mailingAddress && state.serviceAddress);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    availableIsps: state.availableIsps,
    locale: state.locale,
    pin: state.pin,
    provider: state.provider,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    { addAccessibilityMessage, push, setProvider },
    dispatch
  );
};

export const Provider = connect<IStateProps, IDispatchProps, {}, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Component));
