import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IconButtonLink } from './IconButtonLink';

import './BackButton.css';

function Component(props: RouteComponentProps<{}> & WithNamespaces) {
  return (
    <IconButtonLink
      className="back-button"
      onClick={(e) => {
        e.preventDefault();
        props.history.goBack();
      }}
      iconName="chevron left"
    >
      {props.t('general.back')}
    </IconButtonLink>
  );
}

export const BackButton = withRouter(withNamespaces()(Component));
