import { map } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { push } from 'connected-react-router';
import { isPOBox } from '../util';
import { ActionKeys, ActionTypes } from '../actions/api';
import { setUserId } from '../util/analytics';
import { IAppState } from '../reducers';
import * as translations from '../translations/translations';

export const verifyPinTransistion = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$.ofType(ActionKeys.VERIFY_PIN_VALID).pipe(
    map((event) => {
      // We set userId after verifying address to ensure that the user's PIN + Address combo is correct
      // The gaTrackingId should always be defined by this point in the program, but we need the if-statement for TS
      if (state$.value.gaTrackingId) {
        setUserId(state$.value.gaTrackingId);
      }
      return push(
        translations[state$.value.locale === 'fr' ? 'fr' : 'en'].paths
          .permission
      );
    })
  );

export const verifyAddressTransistion = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$
    .ofType(ActionKeys.VERIFY_ADDRESS_VALID)
    .pipe(
      map(() =>
        state$.value.mailingAddress && isPOBox(state$.value.mailingAddress)
          ? push(
              translations[state$.value.locale === 'fr' ? 'fr' : 'en'].paths
                .serviceAddressEntry
            )
          : push(
              translations[state$.value.locale === 'fr' ? 'fr' : 'en'].paths
                .addressConfirmation
            )
      )
    );

export const orderFreeComputerTransistion = (
  action$: ActionsObservable<ActionTypes>,
  state$: StateObservable<IAppState>
) =>
  action$
    .ofType(ActionKeys.ORDER_FREE_COMPUTER_VALID)
    .pipe(
      map(() =>
        push(
          `/${
            translations[state$.value.locale === 'fr' ? 'fr' : 'en'].paths
              .finish
          }`
        )
      )
    );
