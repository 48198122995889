import * as React from 'react';
import { IIsp } from '../actions/api';
import { WithNamespaces, withNamespaces } from 'react-i18next';

interface INumberFirstCardProps {
  isp: IIsp;
  overrideLocale: string;
}

function NumberFirstCardImpl(props: INumberFirstCardProps & WithNamespaces) {
  const { isp, overrideLocale, t: originalT } = props;
  const locale = overrideLocale === 'fr' ? 'fr' : 'en';
  const t = (key: string, options?: Record<string, string>) =>
    originalT(key, { ...options, lng: overrideLocale });

  return (
    <div>
      <p>
        {t('screens.finish.numberFirstCard.call')}{' '}
        <b>
          <a href={`tel:+${isp.phone[locale]}`}>{isp.phone[locale]}</a>{' '}
        </b>
        {isp.phoneTTY && `(${t('screens.finish.voice')})`}
      </p>
      {isp.phoneTTY && (
        <p>
          <b>{isp.phoneTTY} </b>({t('abbreviations.code.TTY')})
        </p>
      )}
      {isp.url && (
        <p>
          <a href={isp.url[locale]}>
            {t('screens.finish.or')}{' '}
            {t('screens.finish.numberFirstCard.visitWebsite', {
              name: isp.name,
              suffix: isp.id !== 'rogers' ? '’s' : '',
            })}
          </a>
        </p>
      )}
    </div>
  );
}

export const NumberFirstCard = withNamespaces()(NumberFirstCardImpl);
