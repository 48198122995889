import * as React from 'react';
import { IIsp } from '../actions/api';
import { LogoBubble } from './LogoBubble';
import {
  ILogosCollection,
  ispLogos,
  ispLogos2x,
  ispLogos2xFrench,
  ispLogosFrench,
} from './logos';
import { SiteFirstCard } from './SiteFirstCard';
import { NumberFirstCard } from './NumberFirstCard';
import { Language } from '../typings/Language';

interface IISPCardProps {
  isp: IIsp;
  locale: Language;
  province: string;
}

export function ISPCard(props: IISPCardProps) {
  const { isp, locale, province } = props;

  const isEn = locale === 'en';

  const logos = isEn ? ispLogos : { ...ispLogos, ...ispLogosFrench };
  const logos2x = isEn ? ispLogos2x : { ...ispLogos2x, ...ispLogos2xFrench };

  return (
    <LogoBubble
      key={isp.shortName}
      type="isp"
      logo={
        <img
          src={logos[isp.shortName as keyof ILogosCollection]}
          srcSet={`${logos[isp.shortName as keyof ILogosCollection]}, ${
            logos2x[isp.shortName as keyof ILogosCollection]
          } 2x`}
          alt={isp.name}
        />
      }
    >
      {['bell', 'telus'].includes(isp.id) ? (
        <SiteFirstCard isp={isp} province={province} overrideLocale={locale} />
      ) : (
        <NumberFirstCard isp={isp} overrideLocale={locale} />
      )}
    </LogoBubble>
  );
}
