import ReactGA from 'react-ga';

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

interface IGAEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: string;
}

export function trackAnalyticsEvent(args: IGAEvent) {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.event(args);
  }
}

export function trackAnalyticsPageView(path: string) {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.pageview(path);
  }
}

export function setUserId(userId: string) {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.set({ userId });
  }
}
