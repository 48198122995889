import * as React from 'react';
import { Form, Message } from '../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  FormikErrors,
  FormikHandlers,
  FormikHelpers as FormikActions,
} from 'formik';
import MaskedInput from 'react-text-mask';
import { IAddress } from '../actions/api';
import { postalCodeMask } from '../constants';
import { provincesShortForm } from '../constants';

interface IOwnProps {
  values: IAddress;
  errors: FormikErrors<IAddress>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  noPoBox: boolean;
  setFieldValue: FormikActions<IAddress>['setFieldValue'];
  serviceAddress?: IAddress;
  allowTerritories?: boolean;
}

type Props = IOwnProps & WithNamespaces;

class AddressFormInputsImpl extends React.Component<Props> {
  public render() {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      noPoBox,
      setFieldValue,
      serviceAddress,
      allowTerritories,
      t,
    } = this.props;

    const provincesList = allowTerritories
      ? provincesShortForm
      : provincesShortForm.filter((province) => province !== 'NU');

    const dropdownProvinces = provincesList
      .filter(
        (shortName) => !serviceAddress || shortName === serviceAddress.province
      )
      .map((shortName) => ({
        text: t('provinces.' + shortName),
        value: shortName,
      }));

    return (
      <React.Fragment>
        <Form.Field>
          <label htmlFor="line1">{t('addressEntry.address')}</label>
          <input
            id="line1"
            maxLength={255}
            type="text"
            value={values.line1}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-required={true}
            aria-invalid={errors.line1 ? true : false}
          />
          {noPoBox ? (
            <div>
              <small>{t('addressEntry.poBoxNote')}</small>
            </div>
          ) : (
            ''
          )}
          <Message
            error={true}
            aria-live="polite"
            content={errors.line1 ? errors.line1 : null}
            role="alert"
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="line2">{t('addressEntry.addressLineTwo')}</label>
          <input
            id="line2"
            maxLength={255}
            type="text"
            value={values.line2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.line2 ? errors.line2 : null}
            role="alert"
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="city">{t('addressEntry.city')}</label>
          <input
            id="city"
            maxLength={255}
            type="text"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-required={true}
            aria-invalid={errors.city ? true : false}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.city ? errors.city : null}
            role="alert"
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="province">{t('addressEntry.province')}</label>
          <Form.Select
            id="province"
            options={dropdownProvinces}
            value={values.province}
            onChange={(e, { value }) => {
              setFieldValue('province', value);
            }}
            aria-required={true}
            aria-invalid={errors.province ? true : false}
            placeholder="Please Select Provice"
            selectOnBlur={false}
            search={true}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.province ? errors.province : null}
            role="alert"
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="postalCode">{t('addressEntry.postalCode')}</label>
          <MaskedInput
            mask={postalCodeMask}
            guide={false}
            id="postalCode"
            type="text"
            value={values.postalCode.toUpperCase()}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-label={t('addressEntry.aria.postalCodeInput')}
            aria-required={true}
            aria-invalid={errors.postalCode ? true : false}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.postalCode ? errors.postalCode : null}
            role="alert"
          />
        </Form.Field>
      </React.Fragment>
    );
  }
}

export const AddressFormInputs = withNamespaces()(AddressFormInputsImpl);
