import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IIsp, listAllIsps } from '../../actions/api';
import { IAppState, IRequestState } from '../../reducers';
import { Container, Form, Grid, Header, Loader } from '../../semantic';
import { ISPCard } from '../ISPCard';
import { provincesShortForm } from '../../constants';
import { Language, languages } from '../../typings/Language';

interface IStateProps {
  availableIsps: IIsp[];
  listIspsRequest: IRequestState;
  locale: Language;
}

interface IDispatchProps {
  listAllIsps: typeof listAllIsps;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;
type State = { value: string };

class IspShowcaseImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { value: 'CA' };
  }

  public componentDidMount() {
    this.props.listAllIsps();
  }

  public render() {
    const { props } = this;
    const { value } = this.state;
    const isAll = value === 'CA';

    const provinces = [
      {
        text: 'Canada',
        value: 'CA',
      },
      ...provincesShortForm
        .filter((province) => province !== 'NU')
        .map((shortName) => ({
          text: props.t('provinces.' + shortName),
          value: shortName,
        })),
    ];

    return (
      <>
        <Container text={false}>
          <Grid>
            <Grid.Column width="16">
              <Form.Select
                fluid={true}
                value={value}
                options={provinces}
                onChange={(e, { value }) => {
                  this.setState({ value: `${value}` });
                }}
              />
            </Grid.Column>
            {props.listIspsRequest.loading ? (
              <Grid.Column width="16">
                <Loader
                  inline="centered"
                  active={true}
                  role="alert"
                  aria-label="loading"
                />
              </Grid.Column>
            ) : (
              provinces
                .filter((province) =>
                  isAll ? province.value !== 'CA' : province.value === value
                )
                .map((province) => (
                  <>
                    {isAll && (
                      <Grid.Column width="16">
                        <Header as="h2" textAlign="center">
                          {props.t('provinces.' + province.value)}
                        </Header>
                      </Grid.Column>
                    )}
                    {props.availableIsps
                      .filter((isp) => isp.regionId === province.value)
                      .map((isp) =>
                        languages.map((locale) => (
                          <Grid.Column width="8">
                            <ISPCard
                              isp={isp}
                              locale={locale}
                              province={province.value}
                            />
                          </Grid.Column>
                        ))
                      )}
                  </>
                ))
            )}
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    availableIsps: state.availableIsps,
    listIspsRequest: state.listIspsRequest,
    locale: state.locale,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      listAllIsps,
    },
    dispatch
  );
};

export const IspShowcase = connect<IStateProps, IDispatchProps, {}, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(IspShowcaseImpl));
