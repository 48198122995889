import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Form, Grid, Container, Segment } from '../../semantic';
import { push } from 'connected-react-router';
import { setLocale } from '../../actions/app';
import { Button } from '../common/Button';
import { trackAnalyticsEvent } from '../../util/analytics';
import './LanguageSelection.css';

interface IDispatchProps {
  push: typeof push;
  setLocale: typeof setLocale;
}

type Props = IDispatchProps;

class Component extends React.Component<Props> {
  public render() {
    const { props } = this;
    return (
      <Container className="language-selection">
        <Form>
          <Grid centered={true} stackable={true}>
            <Grid.Column lang="en" textAlign="center" computer={7} tablet={8}>
              <Segment basic={true}>
                <h1 className="language-selection__title">
                  Connecting Families
                </h1>
                <p>
                  Helping connect eligible people with low-cost home Internet
                  services
                </p>
                <Button
                  type="button"
                  onClick={() => {
                    props.setLocale('en');
                    props.push('/en/welcome');
                    trackAnalyticsEvent({
                      action: 'Selected English',
                      category: 'Language',
                    });
                  }}
                >
                  English
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column lang="fr" textAlign="center" computer={7} tablet={8}>
              <Segment basic={true}>
                <h1 className="language-selection__title">
                  Familles branchées
                </h1>
                <p>
                  Aider les personnes admissibles à se connecter à des services
                  d'Internet résidentiel à faible coût
                </p>
                <Button
                  type="button"
                  onClick={() => {
                    props.setLocale('fr');
                    props.push('/fr/bienvenue');
                    trackAnalyticsEvent({
                      action: 'Selected French',
                      category: 'Language',
                    });
                  }}
                >
                  Français
                </Button>
              </Segment>
            </Grid.Column>
          </Grid>
        </Form>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      push,
      setLocale,
    },
    dispatch
  );
};

export const LanguageSelection = connect<{}, IDispatchProps>(
  null,
  mapDispatchToProps
)(Component);
