import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { Container, Form } from '../../semantic';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';

import { Button, BackButton } from '../common';
import { IAddress } from '../../actions/api';
import { IAppState } from '../../reducers';
import { addAccessibilityMessage } from '../../actions/app';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import { FreeComputerParagraph } from '../FreeComputerParagraph';

import './Computer.css';

interface IDispatchProps {
  push: typeof push;
  replace: typeof replace;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

interface IStateProps {
  address?: IAddress;
  isFreeComputerAvailable: boolean;
  canOrderFreeComputer: boolean;
  sufficientState: boolean;
}

type Props = IDispatchProps & IStateProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(this.props.t('aria.messages.computer'));

    // If you have already redeemed your free computer or computers are no longer available replace this step with the final page
    if (
      !this.props.canOrderFreeComputer ||
      !this.props.isFreeComputerAvailable
    ) {
      this.props.replace(this.props.t('paths.finish'));
    }
    return;
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.computer.title')} />
        <BackButton />

        <Form>
          <Heading>{props.t('screens.computer.prompt')}</Heading>
          <p>
            <FreeComputerParagraph />
          </p>
          <Button
            type="button"
            onClick={() => {
              props.push(props.t('paths.computerAddressEntry'));
            }}
          >
            {props.t('screens.computer.yesOption')}
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.push(props.t('paths.finish'));
            }}
          >
            {props.t('screens.computer.noOption')}
          </Button>
        </Form>
        <p className="free-shipping">
          <Trans i18nKey="screens.computer.paragraph2">
            The computer is yours to keep and will be shipped to you{' '}
            <strong>free of charge</strong>.
          </Trans>
        </p>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.mailingAddress && state.serviceAddress);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  const province = state.serviceAddress ? state.serviceAddress.province : '';
  const isFreeComputerAvailable =
    state.isFreeComputerAvailable &&
    !state.disabledComputerProvinces.includes(province);

  return {
    address: state.serviceAddress,
    canOrderFreeComputer: state.canOrderFreeComputer,
    isFreeComputerAvailable,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    { addAccessibilityMessage, push, replace },
    dispatch
  );
};

export const Computer = withNamespaces()(
  connect<IStateProps, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
