import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Message } from '../semantic';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { setLocale } from '../actions/app';
import { IAppState } from '../reducers';
import { trackAnalyticsEvent } from '../util/analytics';
import { Modal } from './Modal';
import { About } from './About';
import logo from './title-bar__logo.png';
import logo2x from './title-bar__logo@2x.png';
import mobileLogo from './title-bar__mobile-logo.png';
import mobileLogo2x from './title-bar__mobile-logo@2x.png';
import './TitleBar.css';
import { ButtonLink } from './common/ButtonLink';
import { replace } from 'connected-react-router';
import { getKeyFromTranslatedPath } from '../util/getKeyFromTranslatedPath';
import { Language } from '../typings/Language';
import { getAllTranslations } from '../util';

interface IOwnProps {
  hideLinks?: boolean;
}

interface IStateProps {
  locale: Language;
}

interface IDispatchProps {
  setLocale: typeof setLocale;
  replace: typeof replace;
}

interface IOwnState {
  aboutModalIsOpen: boolean;
}

type Props = IOwnProps &
  IStateProps &
  IDispatchProps &
  RouteComponentProps<{}> &
  WithNamespaces;

class Component extends React.Component<Props, IOwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      aboutModalIsOpen: false,
    };
  }

  public render() {
    const { props } = this;

    // Watch out using this Trans component. If the a tag isn't on the first line it fails :( !!!
    const disclaimer = (
      <Trans i18nKey="general.disclaimer">
        Fit <a href={props.t('links.connectingFamilies')}>one</a> line.
      </Trans>
    );

    return (
      <header className="title-bar">
        <Modal
          isOpen={this.state.aboutModalIsOpen}
          onRequestClose={() => this.setState({ aboutModalIsOpen: false })}
          closeButtonText={props.t('buttons.close')}
        >
          <About locale={props.locale} />
        </Modal>
        {process.env.REACT_APP_ENVIRONMENT_NAME === 'stage' && (
          <Container className="title-bar__disclaimer">
            <Message error>{disclaimer}</Message>
          </Container>
        )}
        <Container className="title-bar__container">
          {!props.hideLinks && (
            <div className="title-bar__about-link">
              <ButtonLink
                className="title-bar__about"
                onClick={(e) => {
                  this.setState({ aboutModalIsOpen: true });
                  e.preventDefault();
                }}
              >
                <span className="title-bar__desktop-about">
                  {props.t('general.aboutConnectingFamilies')}
                </span>
                <span className="title-bar__mobile-about">
                  {props.t('general.about')}
                </span>
              </ButtonLink>
            </div>
          )}

          <div className="title-bar__logo">
            <Link to="/">
              <img
                alt="Connecting Families - Familles Branchées"
                src={logo}
                width="250"
                height="55"
                srcSet={`${logo}, ${logo2x} 2x`}
                className="title-bar__desktop-logo"
              />
              <img
                alt="Connecting Families - Familles Branchées"
                src={mobileLogo}
                width="200"
                height="103"
                srcSet={`${mobileLogo}, ${mobileLogo2x} 2x`}
                className="title-bar__mobile-logo"
              />
            </Link>
          </div>
          {!props.hideLinks && (
            <div className="title-bar__language-link">
              {props.locale === 'en' ? (
                <ButtonLink
                  onClick={(e) => {
                    props.setLocale('fr');

                    const pageKey = getKeyFromTranslatedPath(
                      this.props.locale,
                      window.location.pathname.split('/')[2]
                    );
                    const translations = getAllTranslations(`paths.${pageKey}`);

                    this.props.replace(`/fr/${translations[1]}`);

                    trackAnalyticsEvent({
                      action: 'Selected French',
                      category: 'Language',
                    });
                    e.preventDefault();
                  }}
                >
                  Français
                </ButtonLink>
              ) : (
                <ButtonLink
                  onClick={(e) => {
                    props.setLocale('en');

                    const pageKey = getKeyFromTranslatedPath(
                      this.props.locale,
                      window.location.pathname.split('/')[2]
                    );
                    const translations = getAllTranslations(`paths.${pageKey}`);

                    this.props.replace(`/en/${translations[0]}`);

                    trackAnalyticsEvent({
                      action: 'Selected English',
                      category: 'Language',
                    });
                    e.preventDefault();
                  }}
                >
                  English
                </ButtonLink>
              )}
            </div>
          )}
        </Container>
      </header>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  locale: state.locale,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setLocale,
      replace,
    },
    dispatch
  );
};

export const TitleBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withNamespaces()(Component)));
