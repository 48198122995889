import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Helmet } from 'react-helmet';

interface IHeadTitleProps {
  title: string;
}

function HeadTitleImpl(props: IHeadTitleProps & WithNamespaces) {
  const { t, title } = props;
  return (
    <Helmet>
      <title>
        {title} - {t('application.title')}
      </title>
    </Helmet>
  );
}

export const HeadTitle = withNamespaces()(HeadTitleImpl);
