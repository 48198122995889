import * as React from 'react';
import { Form, Message } from '../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { FormikErrors, FormikHandlers } from 'formik';

interface IFormValues {
  firstName: string;
  lastName: string;
}

interface IOwnProps {
  values: IFormValues;
  errors: FormikErrors<IFormValues>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
}

type Props = IOwnProps & WithNamespaces;

class NameFormInputsImpl extends React.Component<Props> {
  public render() {
    const { values, errors, handleChange, handleBlur, t } = this.props;
    return (
      <React.Fragment>
        <Form.Field>
          <label htmlFor="firstName">{t('addressEntry.firstName')}</label>
          <input
            id="firstName"
            maxLength={255}
            type="text"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-required={true}
            aria-invalid={errors.firstName ? true : false}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.firstName ? errors.firstName : null}
            role="alert"
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="lastName">{t('addressEntry.lastName')}</label>
          <input
            id="lastName"
            maxLength={255}
            type="text"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-required={true}
            aria-invalid={errors.lastName ? true : false}
          />
          <Message
            error={true}
            aria-live="polite"
            content={errors.lastName ? errors.lastName : null}
            role="alert"
          />
        </Form.Field>
      </React.Fragment>
    );
  }
}

export const NameFormInputs = withNamespaces()(NameFormInputsImpl);
