export const en = {
  details:
    '* “Personal Information” means information about you that could be used to contact or individually identify you. For the “Connecting Families” initiative, this includes: the code you received in the mail, your name, your home address, an email address, and a phone number.',
  p1:
    'You are accessing this website in the context of the “Connecting Families” initiative.',
  p2:
    'On this website you will be able to find out if you are qualified to receive $10 or $20 per month Internet service. In order to determine if you are qualified, you will be asked to provide certain Personal Information*, as defined under <1>CFSC</1>-<3>OPEC</3>’s Disclosure Policy.',
  p3:
    'At Computers For Success Canada - Ordinateurs Pour l’Excellence Canada (<1>CFSC</1>-<3>OPEC</3>), a not-for-profit organization supported by the Government of Canada, your privacy is important to us. Therefore, we want you to know how we use your Personal Information.',
  p4:
    'If you choose to use this website, you are agreeing to provide Personal Information necessary to your participation in the initiative. Your Personal Information will remain completely confidential.',
  p5:
    'You may also be asked if you would like to participate in a study that will help us to understand whether this is a good, well-designed program, or not. Your participation in this evaluation is completely optional and solely on a voluntary basis, and shall be entitled at all times to say “Yes” or “No” as means of express consent towards participating in this study.',
  p6:
    'All Personal Information you share with <2>CFSC</2>-<4>OPEC</4> will be used and disclosed with our Partner Organizations and Service Providers, as defined under <8>CFSC</8>-<10>OPEC’s</10> Disclosure Policy (by “disclosed” we mean who we share this information with). If and when we share the information, we will always do that in compliance with our Disclosure Policy. You can review  <13>CFSC-OPEC’s full disclosure policy</13>.',
  p7:
    '<0>CFSC</0>-<2>OPEC</2> follows the guidelines outlined in the Personal Information Protection and Electronics Document Act (<5>PIPEDA</5>) published by the Office of the Privacy Commissioner of Canada, and its substantially similar provincial counterparts: the Alberta Personal Information Protection Act, overseen by the Office of the Information and Privacy Commissioner of Alberta; the British Columbia Personal Information Protection Act, overseen by the Office of the Information and Privacy Commissioner for British Columbia; and, the Quebec Act Respecting the Protection of Personal Information in the Private Sector, overseen by the Commission d’accès à l’information du Québec.',
  p8:
    '<0>CFSC</0>-<2>OPEC</2> has also adopted the guidelines outlined in General Data Protection Regulation 2016/679 (“GDPR”), which is a regulation in European Union law on data protection and privacy that applies in the European Union and the European Economic Area.',
  p9:
    'By agreeing, you signify agreement to the processing of Personal Information relating to yourself by <2>CFSC</2>-<4>OPEC</4>, and your agreement with <7>CFSC</7>-<9>OPEC</9>’s Disclosure Policy and <12>website Terms & Conditions</12>:',
};

export const fr = {
  details:
    '* « Renseignements personnels » représente des renseignements sur vous qui pourraient être utilisés afin de vous contacter ou vous identifier individuellement. Pour l’initiative Famille branchées, cela inclut : le code que vous avez reçu par la poste, votre nom, votre adresse de résidence, une adresse de courriel et un numéro de téléphone.',
  p1:
    'Vous accédez à ce site Web dans le cadre de l’initiative Familles branchées.',
  p2:
    'Avec ce site, vous saurez si vous êtes admissible à recevoir un service Internet à 10 $ ou 20 $ par mois. Afin de déterminer si vous êtes admissible, certains renseignements personnels*, tels que définis par la Politique de divulgation de <1>CFSC</1>-<3>OPEC</3>, vous seront demandés. ',
  p3:
    'Chez Computers For Success Canada - Ordinateurs pour l’excellence Canada (<1>CFSC</1>-<3>OPEC</3>), un organisme sans but lucratif soutenu par le gouvernement du Canada, votre vie privée est importante pour nous. Ainsi, nous voulons que vous sachiez comment nous utilisons vos Renseignements personnels.',
  p4:
    'En choisissant d’utiliser ce site Web, vous acceptez de fournir les renseignements personnels nécessaires pour participer à cette initiative. Vos renseignements personnels demeureront strictement confidentiels.',
  p5:
    'Ce site Web pourrait aussi vous proposer de participer à une étude qui nous aidera à comprendre si ce programme est efficace et bien conçu (ou non). Votre participation à cette évaluation n’est pas obligatoire, se fait uniquement de façon volontaire, et vous pourrez en tout temps accepter ou refuser d’y participer en répondant « oui » ou « non » en guise de consentement exprès vis-à-vis de votre participation à cette étude.',
  p6:
    'Tout renseignement personnel que vous partagez avec <2>CFSC</2>-<4>OPEC</4> sera utilisé et divulgué à nos Organisations partenaires et nos Fournisseurs de services, tels que définis dans la Politique de divulgation de <8>CFSC</8>-<10>OPEC’s</10> (par « divulgué », nous voulons dire ceux avec qui nous partageons cette information). Si et quand nous partageons ces renseignements, nous le ferons toujours conformément à notre Politique de divulgation. Vous pouvez examiner <13>ici toute la Politique de divulgation de CFSC-OPEC</13>.',
  p7:
    '<0>CFSC</0>-<2>OPEC</2> suit les directives exposées dans la Loi sur la protection des renseignements personnels et les documents électroniques (LPRPDE), publiée par le Commissariat à la protection de la vie privée du Canada, et ses contreparties provinciales substantiellement similaires: la Loi en Alberta sur la protection des renseignements personnels, par le Commissariat à l’information et à la protection de la vie privée de l’Alberta; a Loi en Colombie-Britannique sur la protection des renseignements personnels, supervisé par le Commissariat à l’information et à la protection de la vie privée de la Colombie-Britannique, et la Loi sur la protection des renseignements personnels dans le secteur privé, supervisée par la Commission d’accès à l’information du Québec.',
  p8:
    '<0>CFSC</0>-<2>OPEC</2> a également adopté les lignes directrices énoncées dans le Règlement général 2016/679 sur la protection des données (« RGPD ») de l’Union européenne, qui est un règlement du droit de l’Union européenne sur la protection des données et de la vie privée qui s’applique aux pays membres de l’Union européenne et à l’Espace économique européen. ',
  p9:
    'En acceptant, vous signifiez votre accord au traitement des Renseignements personnels vous concernant par <2>CFSC</2>-<4>OPEC</4> ainsi que votre accord avec la Politique de divulgation de <7>CFSC</7>-<9>OPEC</9>’s et <12>les Conditions d’utilisation de son site Web</12>:',
};
