import * as React from 'react';
import { Container, Form } from '../../semantic';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import { Button } from '../common';
import letterImage from './cfsc-letter.png';
import letterImage2x from './cfsc-letter@2x.png';
import { addAccessibilityMessage } from '../../actions/app';

interface IDispatchProps {
  push: typeof push;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    this.props.addAccessibilityMessage(this.props.t('aria.messages.welcome'));
  }

  public render() {
    const { props } = this;
    const { t } = props;

    return (
      <Container text={true}>
        <HeadTitle title={t('screens.welcome.title')} />
        <Form>
          <Heading>{t('screens.welcome.heading')}</Heading>
          <p>
            <Trans i18nKey="screens.welcome.paragraph1">
              The Government of Canada’s Connecting Families initiative, in
              partnership with Internet service providers (
              <abbr title={t('abbreviations.name.ISP')}>
                {t('abbreviations.code.ISP')}
              </abbr>
              s), helps eligible people get home low-cost Internet services.',
            </Trans>
          </p>
          <p>{t('screens.welcome.paragraph2')}</p>
          <p>
            <strong>{t('screens.welcome.prompt')}</strong>
          </p>
          <Button
            type="button"
            onClick={() => {
              props.push(t('paths.pinEntry'));
            }}
          >
            {t('screens.welcome.affirmative')}
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.push(t('paths.noLetter'));
            }}
          >
            {t('screens.welcome.negative')}
          </Button>
          <div className="letter-image-container">
            <img
              src={letterImage}
              srcSet={`${letterImage}, ${letterImage2x} 2x`}
              width="375"
              height="350"
              alt={props.t('general.letterImage')}
            />
          </div>
        </Form>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ addAccessibilityMessage, push }, dispatch);
};

export const Welcome = withNamespaces()(
  connect<{}, IDispatchProps>(null, mapDispatchToProps)(Component)
);
