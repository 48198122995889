import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container } from '../../semantic';
import { push, go } from 'connected-react-router';
import get from 'lodash/get';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { BackButton } from '../common';
import { IAddress, IFreeComputerInfo } from '../../actions/api';
import {
  IContactInfo,
  setFreeComputerInfo,
  addAccessibilityMessage,
} from '../../actions/app';
import { IAppState, IRequestState } from '../../reducers';
import { ComputerForm } from '../ComputerForm';
import { HeadTitle } from '../HeadTitle';
import { Language } from '../../typings/Language';

interface IStateProps {
  isFreeComputerAvailable: boolean;
  canOrderFreeComputer: boolean;
  contactInfo?: IContactInfo;
  freeComputerInfo?: IFreeComputerInfo;
  locale: Language;
  serviceAddress?: IAddress;
  orderFreeComputerRequest: IRequestState;
  sufficientState: boolean;
}

interface IDispatchProps {
  go: typeof go;
  push: typeof push;
  addAccessibilityMessage: typeof addAccessibilityMessage;
  setFreeComputerInfo: typeof setFreeComputerInfo;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.computerAddressEntry')
    );

    // If you have already redeemed your free computer or computers are no longer available go back to internet service question
    if (
      !this.props.canOrderFreeComputer ||
      !this.props.isFreeComputerAvailable
    ) {
      this.props.go(-2);
    }

    return;
  }

  public render() {
    const { props } = this;

    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.computerAddressEntry.title')} />
        <BackButton />
        <ComputerForm
          initialValues={{
            city: get(
              props,
              'freeComputerInfo.city',
              get(props, 'serviceAddress.city', '')
            ),
            confirmEmail: get(this.props, 'contactInfo.confirmEmail', ''),
            email: get(
              props,
              'freeComputerInfo.email',
              get(props, 'contactInfo.email', '')
            ),
            firstName: get(props, 'freeComputerInfo.firstName', ''),
            lastName: get(props, 'freeComputerInfo.lastName', ''),
            line1: get(
              props,
              'freeComputerInfo.line1',
              get(props, 'serviceAddress.line1', '')
            ),
            line2: get(
              props,
              'freeComputerInfo.line2',
              get(props, 'serviceAddress.line2', '')
            ),
            locale: get(
              props,
              'freeComputerInfo.locale',
              get(props, 'locale', 'en')
            ),
            phone: get(
              props,
              'freeComputerInfo.phone',
              get(props, 'contactInfo.phone', '')
            ),
            postalCode: get(
              props,
              'freeComputerInfo.postalCode',
              get(props, 'serviceAddress.postalCode', '')
            ),
            province: get(
              props,
              'freeComputerInfo.province',
              get(props, 'serviceAddress.province', '')
            ),
          }}
          onSubmit={(values, actions) => {
            if (!props.orderFreeComputerRequest.loading) {
              props.addAccessibilityMessage(
                props.t('aria.messages.computerInfoEntrySubmit')
              );
              props.setFreeComputerInfo(values);
              actions.setSubmitting(false);
              props.push(props.t('paths.computerAddressConfirmation'));
            }
          }}
          submitButtonText={props.t('buttons.continue')}
          isLoading={props.orderFreeComputerRequest.loading}
          serviceAddress={props.serviceAddress}
        />
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.mailingAddress && state.serviceAddress);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  const province = state.serviceAddress ? state.serviceAddress.province : '';
  const canOrderFreeComputer =
    state.canOrderFreeComputer &&
    !state.disabledComputerProvinces.includes(province);

  return {
    canOrderFreeComputer,
    contactInfo: state.contactInfo,
    freeComputerInfo: state.freeComputerInfo,
    isFreeComputerAvailable: state.isFreeComputerAvailable,
    locale: state.locale,
    orderFreeComputerRequest: state.orderFreeComputerRequest,
    serviceAddress: state.serviceAddress,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      addAccessibilityMessage,
      go,
      push,
      setFreeComputerInfo,
    },
    dispatch
  );
};

export const ComputerInfoEntry = withNamespaces()(
  connect<IStateProps, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
