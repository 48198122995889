import { RouterState } from 'connected-react-router';

import {
  ActionKeys as AppActionKeys,
  ActionTypes as AppActionTypes,
  IContactInfo,
} from '../actions/app';

import {
  ActionKeys as PinActionKeys,
  ActionTypes as PinActionTypes,
  IAddress,
  IIsp,
  IFreeComputerInfo,
} from '../actions/api';

import { mocks } from '../util/mocks';
import { Language } from '../typings/Language';

export interface IRequestState {
  loading: boolean;
  error?: string;
  valid?: boolean;
  invalid?: boolean;
}

export interface INotifyRequestState extends IRequestState {
  phone: boolean;
  email: boolean;
}

const isMockMode = process.env.REACT_APP_MOCK_MODE === 'true';

export interface IAppState {
  applicationId?: string;
  accessibilityMessageKeys: string[];
  accessibilityMessages: string[];
  isFreeComputerAvailable: boolean;
  availableIsps: IIsp[];
  canOrderFreeComputer: boolean;
  canOrderFreeNonPriorityComputer: boolean;
  contactInfo?: IContactInfo;
  currentYear: number;
  disabledComputerProvinces: string[];
  freeComputerInfo?: IFreeComputerInfo;
  locale: Language;
  listIspsRequest: IRequestState;
  orderFreeComputerRequest: IRequestState;
  pin?: string;
  provider?: string;
  providerInternetService: boolean;
  router?: RouterState;
  mailingAddress?: IAddress;
  saveApplicationRequest: IRequestState;
  notifyUserRequest: INotifyRequestState;
  serviceAddress?: IAddress;
  verifyPinRequest: IRequestState;
  verifyAddressRequest: IRequestState;
  gaTrackingId?: string;
}

let defaultState = {
  accessibilityMessageKeys: [],
  accessibilityMessages: [],
  availableIsps: [],
  canOrderFreeComputer: true,
  canOrderFreeNonPriorityComputer: true,
  currentYear: new Date().getFullYear(),
  disabledComputerProvinces: [],
  isFreeComputerAvailable: true,
  listIspsRequest: {
    loading: false,
  },
  locale: 'en' as Language,
  notifyUserRequest: {
    email: true,
    loading: false,
    phone: true,
    valid: false,
  },
  orderFreeComputerRequest: {
    loading: false,
  },
  providerInternetService: false,
  saveApplicationRequest: {
    loading: false,
  },
  verifyAddressRequest: {
    loading: false,
  },
  verifyPinRequest: {
    loading: false,
  },
};

if (isMockMode) {
  defaultState = Object.assign({}, defaultState, mocks);
}

export const rootReducer = (
  state: IAppState = defaultState,
  action: AppActionTypes | PinActionTypes
): IAppState => {
  switch (action.type) {
    case AppActionKeys.SET_LOCALE: {
      return {
        ...state,
        locale: action.locale,
      };
    }

    case AppActionKeys.CLEAR_SERVICE_ADDRESS: {
      return {
        ...state,
        serviceAddress: undefined,
      };
    }

    case AppActionKeys.SET_MAILING_ADDRESS: {
      return {
        ...state,
        mailingAddress: action.address,
      };
    }

    case AppActionKeys.SET_SERVICE_ADDRESS: {
      return {
        ...state,
        serviceAddress: action.address,
      };
    }

    case AppActionKeys.SET_FREE_COMPUTER_INFO: {
      return {
        ...state,
        freeComputerInfo: action.info,
      };
    }

    case AppActionKeys.SET_CONTACT_INFO: {
      return {
        ...state,
        contactInfo: action.contactInfo,
        // Set request state back to default so another notify request will be made
        // after updating contact info
        notifyUserRequest: {
          email: true,
          invalid: false,
          loading: false,
          phone: true,
          valid: false,
        },
      };
    }

    case AppActionKeys.SET_PROVIDER: {
      return {
        ...state,
        provider: action.provider,
      };
    }

    case AppActionKeys.ADD_DISABLED_COMPUTER_PROVINCE: {
      return {
        ...state,
        disabledComputerProvinces: [
          ...state.disabledComputerProvinces,
          action.province,
        ],
      };
    }

    case AppActionKeys.ADD_ACCESSIBILITY_MESSAGE: {
      return {
        ...state,
        accessibilityMessageKeys: [
          ...state.accessibilityMessageKeys,
          action.key,
        ],
        accessibilityMessages: [...state.accessibilityMessages, action.message],
      };
    }

    case AppActionKeys.REMOVE_ACCESSIBILITY_MESSAGE: {
      const index = state.accessibilityMessageKeys.indexOf(action.key);
      if (index !== -1) {
        const accessibilityMessages = state.accessibilityMessages.slice();
        accessibilityMessages.splice(index, 1);

        const accessibilityMessageKeys = state.accessibilityMessageKeys.slice();
        accessibilityMessageKeys.splice(index, 1);

        return {
          ...state,
          accessibilityMessageKeys,
          accessibilityMessages,
        };
      }

      return state;
    }

    case AppActionKeys.SET_PROVIDER_INTERNET_SERVICE: {
      return {
        ...state,
        providerInternetService: action.providerInternetService,
      };
    }

    case PinActionKeys.LIST_ISPS: {
      return {
        ...state,
        listIspsRequest: {
          loading: true,
        },
      };
    }

    case PinActionKeys.LIST_ISPS_SUCCESS: {
      return {
        ...state,
        availableIsps: action.isps,
        listIspsRequest: {
          invalid: false,
          loading: false,
          valid: true,
        },
      };
    }

    case PinActionKeys.LIST_ISPS_INVALID: {
      return {
        ...state,
        listIspsRequest: {
          invalid: true,
          loading: false,
          valid: false,
        },
      };
    }

    case PinActionKeys.LIST_ISPS_FAIL: {
      return {
        ...state,
        listIspsRequest: {
          error: action.error.message,
          loading: false,
        },
      };
    }

    case PinActionKeys.VERIFY_PIN: {
      return {
        ...state,
        verifyPinRequest: {
          loading: true,
        },
      };
    }

    case PinActionKeys.VERIFY_PIN_VALID: {
      // If we already have a pin in state which doesn't match the
      // newly received one we reset most of the app to default state
      const baseState =
        state.pin && state.pin !== action.response.normalizedPin
          ? defaultState
          : state;

      return {
        ...baseState,
        canOrderFreeComputer: action.response.canOrderFreeComputer,
        canOrderFreeNonPriorityComputer:
          action.response.canOrderFreeNonPriorityComputer,
        disabledComputerProvinces: action.response.disabledComputerProvinces,
        gaTrackingId: action.response.gaTrackingId,
        isFreeComputerAvailable: action.response.isFreeComputerAvailable,
        locale: state.locale, // Keep locale in either case
        pin: action.response.normalizedPin,
        verifyPinRequest: {
          invalid: false,
          loading: false,
          valid: true,
        },
      };
    }

    case PinActionKeys.VERIFY_PIN_INVALID: {
      return {
        ...state,
        verifyPinRequest: {
          invalid: true,
          loading: false,
          valid: false,
        },
      };
    }

    case PinActionKeys.VERIFY_PIN_FAIL: {
      return {
        ...state,
        verifyPinRequest: {
          error: action.error.message,
          loading: false,
        },
      };
    }

    case PinActionKeys.VERIFY_ADDRESS: {
      return {
        ...state,
        mailingAddress: action.address,
        verifyAddressRequest: {
          error: undefined,
          loading: true,
        },
      };
    }

    case PinActionKeys.VERIFY_ADDRESS_VALID: {
      return {
        ...state,
        verifyAddressRequest: {
          invalid: false,
          loading: false,
          valid: true,
        },
      };
    }

    case PinActionKeys.VERIFY_ADDRESS_INVALID: {
      return {
        ...state,
        serviceAddress: undefined,
        verifyAddressRequest: {
          invalid: true,
          loading: false,
          valid: false,
        },
      };
    }

    case PinActionKeys.VERIFY_ADDRESS_FAIL: {
      return {
        ...state,
        serviceAddress: undefined,
        verifyAddressRequest: {
          error: action.error.message,
          loading: false,
        },
      };
    }

    case PinActionKeys.VERIFY_ADDRESS_RESET: {
      return {
        ...state,
        verifyAddressRequest: {
          loading: false,
        },
      };
    }

    case PinActionKeys.SAVE_APPLICATION: {
      return {
        ...state,
        saveApplicationRequest: {
          loading: true,
        },
      };
    }

    case PinActionKeys.NOTIFY_USERS: {
      return {
        ...state,
        notifyUserRequest: {
          email: true,
          loading: true,
          phone: true,
        },
      };
    }

    case PinActionKeys.SAVE_APPLICATION_SUCCESS: {
      return {
        ...state,
        applicationId: action.applicationId,
        saveApplicationRequest: {
          invalid: false,
          loading: false,
          valid: true,
        },
      };
    }

    case PinActionKeys.NOTIFY_USERS_FAIL: {
      return {
        ...state,
        notifyUserRequest: {
          email: action.email,
          invalid: true,
          loading: false,
          phone: action.phone,
          valid: false,
        },
      };
    }

    case PinActionKeys.SAVE_APPLICATION_FAIL: {
      return {
        ...state,
        saveApplicationRequest: {
          invalid: true,
          loading: false,
          valid: false,
        },
      };
    }

    case PinActionKeys.NOTIFY_USERS_SUCCESS: {
      return {
        ...state,
        notifyUserRequest: {
          email: true,
          invalid: false,
          loading: false,
          phone: true,
          valid: true,
        },
      };
    }

    case PinActionKeys.ORDER_FREE_COMPUTER: {
      return {
        ...state,
        orderFreeComputerRequest: {
          loading: true,
        },
      };
    }

    case PinActionKeys.ORDER_FREE_COMPUTER_VALID: {
      return {
        ...state,
        canOrderFreeComputer: false,
        orderFreeComputerRequest: {
          invalid: false,
          loading: false,
          valid: true,
        },
      };
    }

    case PinActionKeys.ORDER_FREE_COMPUTER_INVALID: {
      return {
        ...state,
        orderFreeComputerRequest: {
          invalid: true,
          loading: false,
          valid: false,
        },
      };
    }

    case PinActionKeys.ORDER_FREE_COMPUTER_FAIL: {
      return {
        ...state,
        orderFreeComputerRequest: {
          error: action.error.message,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};
