import * as React from 'react';
import { Container } from '../../semantic';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { BackButton } from '../common';
import { addAccessibilityMessage } from '../../actions/app';
import { IAppState } from '../../reducers';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';

interface IDispatchProps {
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.applicationError')
    );
  }

  public render() {
    const { props } = this;

    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.applicationError.title')} />
        <BackButton />
        <div>
          <Heading>{props.t('screens.applicationError.heading')}</Heading>
          <p>
            <Trans i18nKey="screens.applicationError.paragraph1">
              We’re very sorry, but our site had an issue saving your
              information. The problem has been reported. We’re working to fix
              the issue. Please try again in 10 to 20 minutes. If you still see
              this message please wait and try again as it may take longer to
              fix.
            </Trans>
          </p>
          <Link to={props.t('paths.finish')} replace={true}>
            {props.t('screens.applicationError.tryAgain')}
          </Link>
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ addAccessibilityMessage }, dispatch);
};

export const ApplicationError = withNamespaces()(
  connect<{}, IDispatchProps, {}, IAppState>(
    null,
    mapDispatchToProps
  )(Component)
);
