import { IAddressWithName, IFreeComputerInfo } from '../actions/api';

export function getAddressFromComputerInfo({
  firstName,
  lastName,
  line1,
  line2,
  city,
  province,
  postalCode,
}: IFreeComputerInfo): IAddressWithName {
  return { firstName, lastName, line1, line2, city, province, postalCode };
}
