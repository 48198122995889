import { Language } from '../typings/Language';

export enum ActionKeys {
  LIST_ALL_ISPS = 'LIST_ALL_ISPS',
  LIST_ISPS = 'LIST_ISPS',
  LIST_ISPS_SUCCESS = 'LIST_ISPS_SUCCESS',
  LIST_ISPS_INVALID = 'LIST_ISPS_INVALID',
  LIST_ISPS_FAIL = 'LIST_ISPS_FAIL',
  VERIFY_PIN = 'VERIFY_PIN',
  VERIFY_PIN_VALID = 'VERIFY_PIN_VALID',
  VERIFY_PIN_INVALID = 'VERIFY_PIN_INVALID',
  VERIFY_PIN_FAIL = 'VERIFY_PIN_FAIL',
  VERIFY_ADDRESS = 'VERIFY_ADDRESS',
  VERIFY_ADDRESS_VALID = 'VERIFY_ADDRESS_VALID',
  VERIFY_ADDRESS_INVALID = 'VERIFY_ADDRESS_INVALID',
  VERIFY_ADDRESS_FAIL = 'VERIFY_ADDRESS_FAIL',
  VERIFY_ADDRESS_RESET = 'VERIFY_ADDRESS_RESET',
  SAVE_APPLICATION = 'SAVE_APPLICATION',
  SAVE_APPLICATION_SUCCESS = 'SAVE_APPLICATION_SUCCESS',
  SAVE_APPLICATION_FAIL = 'SAVE_APPLICATION_FAIL',
  NOTIFY_USERS = 'NOTIFY_USERS',
  NOTIFY_USERS_SUCCESS = 'NOTIFY_USERS_SUCCESS',
  NOTIFY_USERS_FAIL = 'NOTIFY_USERS_FAIL',
  ORDER_FREE_COMPUTER = 'ORDER_FREE_COMPUTER',
  ORDER_FREE_COMPUTER_VALID = 'ORDER_FREE_COMPUTER_VALID',
  ORDER_FREE_COMPUTER_INVALID = 'ORDER_FREE_COMPUTER_INVALID',
  ORDER_FREE_COMPUTER_FAIL = 'ORDER_FREE_COMPUTER_FAIL',
  ORDER_FREE_COMPUTER_RESET = 'ORDER_FREE_COMPUTER_RESET',
}

/* Various interfaces and types */

export enum ResponseCodes {
  VALID = 'valid',
  INVALID_PIN = 'invalid-pin',
  INVALID_VERIFICATION = 'invalid-verification',
}

export enum OrderFreeComputerResponseCodes {
  COMPLETE = 'complete',
  INVALID = 'invalid',
  LIMIT_REACHED = 'limit-reached',
}

export interface INoMutationAPIResponse {
  code: ResponseCodes;
  normalizedPin: string;
  reason: string;
  status?: string;
}

export interface IVerifyPinAPIResponse extends INoMutationAPIResponse {
  disabledComputerProvinces: string[];
  isFreeComputerAvailable: boolean;
  canOrderFreeComputer: boolean;
  canOrderFreeNonPriorityComputer: boolean;
  gaTrackingId: string;
}

export interface IIspAPIResponse {
  isps: IIsp[];
  status?: string;
}

export interface ISaveApplicationResponse {
  status?: string;
  id: string;
}

export interface INotifyUsersResponse {
  status?: string;
  phone: boolean;
  email: boolean;
}

export interface IOrderFreeComputerAPIResponse {
  code: OrderFreeComputerResponseCodes;
  reason: string;
  errors?: string[];
}

export interface IAPIError {
  message: string;
  status: number;
}

export interface IAddress {
  line1: string;
  line2?: string;
  city: string;
  province: string;
  postalCode: string;
}

export interface IAddressWithName extends IAddress {
  firstName: string;
  lastName: string;
}

export interface IFreeComputerInfo extends IAddressWithName {
  email?: string;
  locale: Language;
  phone?: string;
}

interface ITranslation {
  en: string;
  fr: string;
}

export interface IIsp {
  id: string;
  displayType: 'show-all' | 'show-existing' | 'hide';
  name: string;
  phone: ITranslation;
  phoneTTY?: string;
  regionId?: string;
  shortName: string;
  url: ITranslation;
}

export interface IContactInfo {
  phone?: string;
  email?: string;
  confirmEmail?: string;
}

interface IMeta {
  reject: any;
  resolve: any;
}

/* Action Types */
export interface IListAllIspsAction {
  type: ActionKeys.LIST_ALL_ISPS;
}

export interface IListIspsAction {
  type: ActionKeys.LIST_ISPS;
  pin: string;
  mailingAddress: IAddress;
  serviceAddress: IAddress;
}

export interface IListIspsSuccessAction {
  type: ActionKeys.LIST_ISPS_SUCCESS;
  response: IIspAPIResponse;
  isps: IIsp[];
}

export interface IListIspsFailAction {
  type: ActionKeys.LIST_ISPS_FAIL;
  error: IAPIError;
}

export interface IListIspsInvalidAction {
  response: IIspAPIResponse;
  type: ActionKeys.LIST_ISPS_INVALID;
}

export interface IVerifyPinAction {
  type: ActionKeys.VERIFY_PIN;
  pin: string;
}

export interface IVerifyPinValidAction {
  type: ActionKeys.VERIFY_PIN_VALID;
  response: IVerifyPinAPIResponse;
}

export interface IVerifyPinFailAction {
  type: ActionKeys.VERIFY_PIN_FAIL;
  error: IAPIError;
}

export interface IVerifyPinInvalidAction {
  response: INoMutationAPIResponse;
  type: ActionKeys.VERIFY_PIN_INVALID;
}

export interface IVerifyAddressAction {
  type: ActionKeys.VERIFY_ADDRESS;
  address: IAddress;
}

export interface IVerifyAddressValidAction {
  type: ActionKeys.VERIFY_ADDRESS_VALID;
  response: INoMutationAPIResponse;
}

export interface IVerifyAddressFailAction {
  type: ActionKeys.VERIFY_ADDRESS_FAIL;
  error: IAPIError;
}

export interface IVerifyAddressResetAction {
  type: ActionKeys.VERIFY_ADDRESS_RESET;
}

export interface IVerifyAddressInvalidAction {
  response: INoMutationAPIResponse;
  type: ActionKeys.VERIFY_ADDRESS_INVALID;
}

export interface ISaveApplicationAction {
  type: ActionKeys.SAVE_APPLICATION;
  meta?: IMeta;
}

export interface ISaveApplicationSuccessAction {
  type: ActionKeys.SAVE_APPLICATION_SUCCESS;
  applicationId: string;
}

export interface ISaveApplicationFailAction {
  type: ActionKeys.SAVE_APPLICATION_FAIL;
}

export interface INotifyUsersAction {
  type: ActionKeys.NOTIFY_USERS;
  meta?: IMeta;
}

export interface INotifyUsersSuccessAction {
  type: ActionKeys.NOTIFY_USERS_SUCCESS;
  phone: boolean;
  email: boolean;
}

export interface INotifyUsersFailAction {
  type: ActionKeys.NOTIFY_USERS_FAIL;
  phone: boolean;
  email: boolean;
}

export interface IOrderFreeComputerAction {
  email?: string;
  phone?: string;
  priority: boolean;
  locale: Language;
  address: IAddress;
  meta?: IMeta;
  type: ActionKeys.ORDER_FREE_COMPUTER;
}

export interface IOrderFreeComputerValidAction {
  response: IOrderFreeComputerAPIResponse;
  type: ActionKeys.ORDER_FREE_COMPUTER_VALID;
}

export interface IOrderFreeComputerFailAction {
  type: ActionKeys.ORDER_FREE_COMPUTER_FAIL;
  error: IAPIError;
}

export interface IOrderFreeComputerResetAction {
  type: ActionKeys.ORDER_FREE_COMPUTER_RESET;
}

export interface IOrderFreeComputerInvalidAction {
  response: IOrderFreeComputerAPIResponse;
  type: ActionKeys.ORDER_FREE_COMPUTER_INVALID;
}

export type ActionTypes =
  | IListAllIspsAction
  | IListIspsAction
  | IListIspsSuccessAction
  | IListIspsFailAction
  | IListIspsInvalidAction
  | IVerifyPinAction
  | IVerifyPinValidAction
  | IVerifyPinFailAction
  | IVerifyPinInvalidAction
  | IVerifyAddressAction
  | IVerifyAddressValidAction
  | IVerifyAddressFailAction
  | IVerifyAddressInvalidAction
  | IVerifyAddressResetAction
  | ISaveApplicationAction
  | ISaveApplicationSuccessAction
  | ISaveApplicationFailAction
  | INotifyUsersAction
  | INotifyUsersSuccessAction
  | INotifyUsersFailAction
  | IOrderFreeComputerAction
  | IOrderFreeComputerValidAction
  | IOrderFreeComputerFailAction
  | IOrderFreeComputerInvalidAction
  | IOrderFreeComputerResetAction;

/* Action Creators */
export const listAllIsps = (): IListAllIspsAction => ({
  type: ActionKeys.LIST_ALL_ISPS,
});

export const listIspsSuccess = (
  response: IIspAPIResponse
): IListIspsSuccessAction => ({
  isps: response.isps,
  response,
  type: ActionKeys.LIST_ISPS_SUCCESS,
});

export const listIspsFail = (error: IAPIError): IListIspsFailAction => ({
  error,
  type: ActionKeys.LIST_ISPS_FAIL,
});

export const listIspsInvalid = (
  response: IIspAPIResponse
): IListIspsInvalidAction => ({
  response,
  type: ActionKeys.LIST_ISPS_INVALID,
});

export const listIsps = (
  pin: string,
  mailingAddress: IAddress,
  serviceAddress: IAddress
): IListIspsAction => ({
  mailingAddress,
  pin,
  serviceAddress,
  type: ActionKeys.LIST_ISPS,
});

export const verifyPin = (pin: string): IVerifyPinAction => ({
  pin,
  type: ActionKeys.VERIFY_PIN,
});

export const verifyPinValid = (
  response: IVerifyPinAPIResponse
): IVerifyPinValidAction => ({
  response,
  type: ActionKeys.VERIFY_PIN_VALID,
});

export const verifyPinFail = (error: IAPIError): IVerifyPinFailAction => ({
  error,
  type: ActionKeys.VERIFY_PIN_FAIL,
});

export const verifyPinInvalid = (
  response: INoMutationAPIResponse
): IVerifyPinInvalidAction => ({
  response,
  type: ActionKeys.VERIFY_PIN_INVALID,
});

export const verifyAddress = (address: IAddress): IVerifyAddressAction => ({
  address,
  type: ActionKeys.VERIFY_ADDRESS,
});

export const verifyAddressValid = (
  response: INoMutationAPIResponse
): IVerifyAddressValidAction => ({
  response,
  type: ActionKeys.VERIFY_ADDRESS_VALID,
});

export const verifyAddressFail = (
  error: IAPIError
): IVerifyAddressFailAction => ({
  error,
  type: ActionKeys.VERIFY_ADDRESS_FAIL,
});

export const verifyAddressInvalid = (
  response: INoMutationAPIResponse
): IVerifyAddressInvalidAction => ({
  response,
  type: ActionKeys.VERIFY_ADDRESS_INVALID,
});

export const verifyAddressReset = (): IVerifyAddressResetAction => ({
  type: ActionKeys.VERIFY_ADDRESS_RESET,
});

export const saveApplication = (meta?: IMeta): ISaveApplicationAction => {
  return {
    meta,
    type: ActionKeys.SAVE_APPLICATION,
  };
};

export const saveApplicationSuccess = (
  response: ISaveApplicationResponse
): ISaveApplicationSuccessAction => {
  return {
    applicationId: response.id,
    type: ActionKeys.SAVE_APPLICATION_SUCCESS,
  };
};

export const saveApplicationFail = (): ISaveApplicationFailAction => {
  return {
    type: ActionKeys.SAVE_APPLICATION_FAIL,
  };
};
export const notifyUsers = (meta?: IMeta): INotifyUsersAction => ({
  meta,
  type: ActionKeys.NOTIFY_USERS,
});

export const notifyUsersSuccess = (): INotifyUsersSuccessAction => ({
  email: true,
  phone: true,
  type: ActionKeys.NOTIFY_USERS_SUCCESS,
});

export const notifyUsersFail = (
  response: INotifyUsersResponse
): INotifyUsersFailAction => ({
  email: response.email,
  phone: response.phone,
  type: ActionKeys.NOTIFY_USERS_FAIL,
});

export const orderFreeComputer = (
  address: IAddressWithName,
  priority: boolean,
  locale: Language,
  email?: string,
  phone?: string,
  meta?: IMeta
): IOrderFreeComputerAction => ({
  address,
  email,
  locale,
  meta,
  phone,
  priority,
  type: ActionKeys.ORDER_FREE_COMPUTER,
});

export const orderFreeComputerValid = (
  response: IOrderFreeComputerAPIResponse
): IOrderFreeComputerValidAction => ({
  response,
  type: ActionKeys.ORDER_FREE_COMPUTER_VALID,
});

export const orderFreeComputerFail = (
  error: IAPIError
): IOrderFreeComputerFailAction => ({
  error,
  type: ActionKeys.ORDER_FREE_COMPUTER_FAIL,
});

export const orderFreeComputerInvalid = (
  response: IOrderFreeComputerAPIResponse
): IOrderFreeComputerInvalidAction => ({
  response,
  type: ActionKeys.ORDER_FREE_COMPUTER_INVALID,
});

export const orderFreeComputerReset = (): IOrderFreeComputerResetAction => ({
  type: ActionKeys.ORDER_FREE_COMPUTER_RESET,
});
