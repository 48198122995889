import * as React from 'react';
import { Container } from '../../semantic';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { BackButton } from '../common';
import { addAccessibilityMessage } from '../../actions/app';
import { IAppState } from '../../reducers';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import { Language } from '../../typings/Language';

interface IStateProps {
  locale: Language;
}

interface IDispatchProps {
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    this.props.addAccessibilityMessage(this.props.t('aria.messages.noIsps'));
  }

  public render() {
    const { props } = this;

    const link =
      props.locale === 'en'
        ? 'https://www.ic.gc.ca/eic/site/111.nsf/eng/home'
        : 'https://www.ic.gc.ca/eic/site/111.nsf/fra/accueil';

    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.noIsps.title')} />
        <BackButton />
        <div>
          <Heading>{props.t('screens.noIsps.heading')}</Heading>
          <p>
            <Trans i18nKey="screens.noIsps.paragraph1">
              To learn more about the Connecting Families Initiative please
              contact{' '}
              <strong>
                <a href="tel:+1-800-328-6189">1 800 328 6189</a>
              </strong>{' '}
              (voice), <a href="tel:+1-866-694-8389">1 866 694 8389</a> (TTY) or
              visit the{' '}
              <a href={link} target="_blank" rel="noreferrer">
                Government of Canada’s Connecting Families website
              </a>
              .
            </Trans>
          </p>
          <p>{props.t('screens.noIsps.paragraph2')}</p>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    locale: state.locale,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ addAccessibilityMessage }, dispatch);
};

export const NoIsps = withNamespaces()(
  connect<{}, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
