import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { HeadTitle } from '../HeadTitle';
import { Container } from '../../semantic';
import { i18n } from '../../translations/i18n';
import { Heading } from '../Heading';
import { Language } from '../../typings/Language';

interface IOwnProps {
  locale: Language;
}

type Props = IOwnProps & WithNamespaces;

class Component extends React.Component<Props> {
  public render() {
    const { props } = this;
    const t = props.locale
      ? i18n.getFixedT(props.locale, 'translations')
      : props.t;

    const cfscCode = t('abbreviations.code.CFSC');
    const cfscName = t('abbreviations.name.CFSC');
    const opecCode = t('abbreviations.code.OPEC');
    const opecName = t('abbreviations.name.OPEC');

    const cfscOpec = (
      <React.Fragment>
        <abbr title={cfscName}>{cfscCode}</abbr>-
        <abbr title={opecName}>{opecCode}</abbr>
      </React.Fragment>
    );

    const en = (
      <React.Fragment>
        <Heading>Terms and Conditions ("Terms")</Heading>
        <p>
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the{' '}
          <a
            href={`https://connecting-families.ca`}
            target="_blank"
            rel="noreferrer"
          >
            connecting-families.ca
          </a>{' '}
          or{' '}
          <a href={`https://cfsc-opec.org`} target="_blank" rel="noreferrer">
            cfsc-opec.org
          </a>{' '}
          websites (the "Service") operated by Computers for Success Canada (
          {cfscOpec}, “ us", "we", or "our"). The Terms describe the terms and
          conditions applicable to your access and use of the Service. You
          accept these Terms by using the Service or accessing any content
          available through the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>
        <p>
          By accessing or using this Service you accept and agree to be bound by
          the Terms of this agreement. Any participation in this service will
          constitute acceptance of this agreement. If you do not agree to the
          above statement or with any part of the Terms, please do not use this
          Service.
        </p>
        <p>
          Each time you use this Service you signify your acceptance and
          agreement, and the acceptance and agreement of any person you purport
          to represent (and for purposes of these Terms, "person" includes
          natural persons and any type of incorporated or unincorporated
          entity), without limitation or qualification, to be bound by these
          Terms, and you represent and warrant that you have the legal authority
          to agree to and accept these Terms on behalf of yourself and any
          person you purport to represent.
        </p>
        <h2>Links to Other Web Sites</h2>
        <p>
          Our Service may contain links to other websites or services that are
          not owned or controlled by {cfscOpec}.
        </p>
        <p>
          {cfscOpec} has no control over, and assumes no responsibility for the
          content, privacy policies, or practices of any other websites or
          services. You further acknowledge and agree that {cfscOpec} will not
          be held responsible or liable, directly or indirectly, for any damage
          or loss caused or alleged to be caused by or in connection with use of
          or reliance on any such content or services available on or through
          any such web sites or services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party websites or services that you visit.
        </p>
        <h2>Governing Law</h2>
        <p>
          These Terms shall be governed and defined in accordance with the
          applicable laws of Canada.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
        <h2>Changes</h2>
        <p>
          {cfscOpec} has the right to modify or replace these Terms at any time.
          When any change or modification has happened, we will draw your
          attention to the updated Terms on our websites. Your choice to
          continue using our websites after such changes have been made signals
          your formal acceptance of the new Terms. If you do not agree to abide
          by the above or with any part of the Terms, please do not use this
          Service.
        </p>
        <h2>Disclaimers and Limitation of Liability.</h2>
        <ol type="a">
          <li>
            THE SERVICE AND ALL CONTENT ON ITS WEBSITES ARE PROVIDED TO YOU ON
            AN “AS IS” “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND EITHER
            EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. CFSC-OPEC MAKES NO WARRANTY AS TO THE ACCURACY,
            COMPLETENESS OR RELIABILITY OF ANY CONTENT AVAILABLE THROUGH THE
            WEBSITE. YOU ARE RESPONSIBLE FOR VERIFYING ANY INFORMATION BEFORE
            RELYING ON IT. USE OF THE SERVICE AND THE CONTENT AVAILABLE ON ITS
            WEBSITES IS AT YOUR SOLE RISK.
          </li>
          <li>
            CFSC-OPEC MAKES NO REPRESENTATIONS OR WARRANTIES THAT USE OF THE
            WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE. YOU ARE RESPONSIBLE FOR
            TAKING ALL NECESSARY PRECAUTIONS TO ENSURE THAT ANY CONTENT YOU MAY
            OBTAIN FROM THE WEBSITE IS FREE OF VIRUSES OR OTHER HARMFUL CODE.
          </li>
          <li>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CFSC-OPEC
            DISCLAIMS ALL LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND FURTHER DISCLAIMS
            ALL LOSSES, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL,
            CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY
            CONNECTED WITH ACCESS TO OR USE OF THE WEBSITE, THE CONTENT, OR THE
            GOODS OFFERED OR SOLD THROUGH THE WEBSITE, EVEN IF DENTONS HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </li>
        </ol>
        <h2>Indemnity</h2>
        <p>
          You agree to indemnify, defend and hold CFSC-OPEC, its subsidiaries,
          and affiliates, and their respective officers, agents, members,
          partners and employees, harmless from any loss, liability, claim, or
          demand, including reasonable attorneys' fees, due to or arising out of
          your use of the Service and/or breach of these Terms.
        </p>
        <h2>Entire Agreement; Severability</h2>
        <p>
          These Terms incorporate by reference any policies (including CFSC’s
          Privacy Policy) contained on its websites and constitute the entire
          agreement with respect to your access to and use of the Service. If
          any provision of these Terms is unlawful, void or unenforceable, then
          that provision shall be deemed severable from the remaining provisions
          and shall not affect their validity and enforceability.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at{' '}
          <a href="mailto:info@cfsc-opec.org">info@cfsc-opec.org</a>.
        </p>
      </React.Fragment>
    );

    const fr = (
      <React.Fragment>
        <Heading>
          Modalités et conditions d’utilisation (« Conditions »)
        </Heading>
        <p>
          Veuillez lire attentivement ces modalités et conditions d'utilisation
          (« Conditions », « Modalités et Conditions ») avant d'utiliser les
          sites Web{' '}
          <a
            href={`https://connecting-families.ca`}
            target="_blank"
            rel="noreferrer"
          >
            connecting-families.ca
          </a>{' '}
          ou{' '}
          <a href={`https://cfsc-opec.org`} target="_blank" rel="noreferrer">
            cfsc-opec.org
          </a>{' '}
          (le « Service »), exploités par Ordinateurs pour l'excellence Canada (
          {cfscOpec}, « nous », « notre »). Les Conditions décrivent les
          modalités et conditions d'utilisation qui s'appliquent à votre accès
          au Service et son utilisation. Vous acceptez ces Conditions en
          utilisant le Service ou en accédant à tout contenu qui est disponible
          par l'entremise du Service.
        </p>
        <p>
          Votre accès au Service et votre utilisation de celui-ci sont
          conditionnels à votre acceptation et votre conformité à ces
          Conditions. Ces Conditions s'appliquent à tous les visiteurs,
          utilisateurs ou autres qui accèdent au Service ou utilisent le
          Service.
        </p>
        <p>
          En accédant à ce Service ou en l’utilisant, vous acceptez et consentez
          d'être lié par les Conditions de cette entente. Toute forme de
          participation dans ce service constituera une acceptation de cette
          entente. Si vous ne consentez pas à la déclaration ci-dessus ou à
          quelque partie des présentes Conditions, veuillez ne pas utiliser ce
          Service.
        </p>
        <p>
          Chaque fois que vous utilisez ce Service, vous signifiez votre
          consentement et votre acceptation, ainsi que le consentement et
          l'acceptation de toute personne que vous prétendez représenter (et
          dans le cadre de ces Conditions, « personne » inclut les personnes
          physiques ainsi que tout type d'entité incorporée ou non incorporée),
          sans limitation ou qualification, d'être lié à ces Conditions, et vous
          déclarez et garantissez d'avoir l'autorité légale d'accepter ces
          Conditions et d'y consentir en votre nom propre et au nom de toute
          personne que vous prétendez représenter.
        </p>
        <h2>Liens vers d'autres sites Web</h2>
        <p>
          Notre Service peut contenir des liens vers d'autres sites Web ou
          services qui ne sont pas détenus ou contrôlés par {cfscOpec}.
        </p>
        <p>
          {cfscOpec} n'a aucun contrôle et n'assume aucune responsabilité sur le
          contenu, les politiques de confidentialité ou les pratiques de tout
          autre site Web ou service. Ainsi, vous reconnaissez et acceptez que{' '}
          {cfscOpec} ne sera pas tenu responsable ou redevable, directement ou
          indirectement, pour tout dommage ou perte causé ou présumé avoir été
          causé par ou à la suite de l'utilisation ou du fait de se fier à tout
          contenu ou service disponible sur ou par l'entremise de tels sites Web
          ou services.
        </p>
        <p>
          Nous vous recommandons fortement de lire les conditions d'utilisation
          et les politiques de confidentialité de tous les sites Web et des
          services tiers que vous visitez.
        </p>
        <h2>Droit applicable</h2>
        <p>
          Les présentes Conditions doivent être régies et interprétées
          conformément aux lois applicables du Canada.
        </p>
        <p>
          Un éventuel manquement de notre part à faire appliquer quelque droit
          ou disposition des présentes Conditions ne sera pas interprété comme
          une renonciation de ces droits. Si une disposition de ces Conditions
          est jugée nulle ou non exécutoire par un tribunal, les autres
          dispositions des présentes Conditions resteront en vigueur. Ces
          Conditions constituent la totalité de la convention établie entre vous
          et nous quant à notre Service et ont préséance et remplacent toutes
          les conventions qui pourraient être antérieurement établies entre vous
          et nous concernant le Service.
        </p>
        <h2>Modifications</h2>
        <p>
          {cfscOpec} a le droit de modifier ou remplacer ces Conditions en tout
          temps. Dans le cas où un changement ou une modification a lieu, nous
          porterons votre attention sur les Conditions mises à jour sur nos
          sites Web. Votre décision de continuer d'utiliser nos sites Web suite
          à la mise en application de tels changements indique votre
          consentement formel aux nouvelles Conditions. Si vous n'acceptez pas
          de vous conformer, en tout ou en partie, à ce qui précède ou aux
          présentes Conditions, veuillez vous abstenir d'utiliser ce Service.
        </p>
        <h2>Exclusions et limitation de la responsabilité</h2>
        <ol type="a">
          <li>
            CE SERVICE ET TOUT LE CONTENU SUR SES SITES WEB VOUS SONT FOURNIS
            sur une base « TEL QUEL » ET « SELON LA DISPONIBILITÉ » SANS
            GARANTIE D'AUCUNE SORTE, EXPRESSE OU IMPLICITE, Y COMPRIS, MAIS SANS
            S'Y LIMITER, LES GARANTIES DE QUALITÉ MARCHANDE, D'ADÉQUATION À UN
            USAGE PARTICULIER, ET D'ABSENCE DE CONTREFAÇON. CFSC-OPEC N'OFFRE
            AUCUNE GARANTIE QUANT À L'EXACTITUDE, L'EXHAUSTIVITÉ OU LA FIABILITÉ
            DE QUELQUE CONTENU DISPONIBLE QUE CE SOIT SUR LE SITE WEB. VOUS AVEZ
            LA RESPONSABILITÉ DE VÉRIFIER TOUTE INFORMATION AVANT DE VOUS Y
            FIER. L'UTILISATION DU SERVICE ET DU CONTENU DISPONIBLE SUR SES
            SITES WEB SE FAIT À VOS SEULS RISQUES ET PÉRILS.
          </li>
          <li>
            CFSC-OPEC NE FAIT AUCUNE REPRÉSENTATION ET NE DONNE AUCUNE GARANTIE
            QUE L'UTILISATION DU SITE WEB SERA ININTERROMPUE OU EXEMPTE
            D'ERREURS. VOUS AVEZ LA RESPONSABILITÉ DE PRENDRE TOUTES LES
            PRÉCAUTIONS NÉCESSAIRES POUR ASSURER QUE TOUT CONTENU QUE VOUS
            OBTENEZ DU SITE WEB EST EXEMPT DE VIRUS OU DE TOUT AUTRE CODE
            MALVEILLANT OU NUISIBLE.
          </li>
          <li>
            DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, CFSC-OPEC
            DÉCLINE TOUTE RESPONSABILITÉ, QU'ELLE SOIT CONTRACTUELLE,
            DÉLICTUELLE (Y COMPRIS LA NÉGLIGENCE), STRICTE OU AUTRE, ET DÉCLINE
            ÉGALEMENT TOUTE PERTE, Y COMPRIS, MAIS SANS S'Y LIMITER, LES
            DOMMAGES INDIRECTS, ACCESSOIRES, CONSÉCUTIFS OU SPÉCIAUX DÉCOULANT
            DE QUELQUE MANIÈRE QUE CE SOIT DE L'ACCÈS OU DE L'UTILISATION DU
            SITE WEB, DU CONTENU, OU DES BIENS OFFERTS OU VENDUS PAR
            L'INTERMÉDIAIRE DU SITE WEB, MÊME SI CFSC-OPEC A ÉTÉ INFORMÉ DE LA
            POSSIBILITÉ DE TELS DOMMAGES.
          </li>
        </ol>
        <h2>Indemnisation </h2>
        <p>
          Vous acceptez d'indemniser et dégager de toute responsabilité
          CFSC-OPEC, ses filiales et ses sociétés affiliées, ainsi que leurs
          officiers, agents, membres, partenaires et employés respectifs, pour
          toute perte, toute responsabilité, toute demande ou réclamation, y
          compris les honoraires d'avocats dus à ou résultant de votre
          utilisation du Service et/ou de la violation de ces Conditions.
        </p>
        <h2>Accord complet : dissociabilité</h2>
        <p>
          Les présentes Conditions intègrent par référence toutes les politiques
          (incluant la Politique de confidentialité d'OPEC) contenues sur ses
          sites Web et constituent l'intégralité de l'accord relatif à votre
          accès au Service et son utilisation. Si quelque disposition de ces
          Conditions que ce soit s'avère illégale, nulle ou inapplicable, cette
          disposition sera alors considérée comme dissociable des dispositions
          restantes et n'affectera pas leur validité et leur force exécutoire.{' '}
        </p>
        <h2>Contactez-nous</h2>
        <p>
          Pour toute question concernant ces Conditions, veuillez communiquer
          avec nous à <a href="mailto:info@cfsc-opec.org">info@cfsc-opec.org</a>
          .
        </p>
      </React.Fragment>
    );

    return (
      <Container text={true}>
        <HeadTitle title={t('screens.termsAndConditions.title')} />
        {props.locale === 'en' ? en : fr}
      </Container>
    );
  }
}

export const TermsAndConditions = withNamespaces()(Component);
