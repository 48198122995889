import * as React from 'react';
import { Form } from '../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { FormikHelpers as FormikActions } from 'formik';
import { IAddress } from '../actions/api';
import { Language } from '../typings/Language';

interface IFormValues {
  locale: Language;
}

interface IOwnProps {
  values: IFormValues;
  setFieldValue: FormikActions<IAddress>['setFieldValue'];
}

type Props = IOwnProps & WithNamespaces;

class KeyboardFormInputsImpl extends React.Component<Props> {
  public render() {
    const { values, setFieldValue, t } = this.props;

    return (
      <Form.Group
        grouped={true}
        id="locale"
        role="radiogroup"
        className="keyboard-selection"
      >
        <label htmlFor="locale">
          <b>{t('addressEntry.locale')}</b>
        </label>
        <Form.Radio
          inline={true}
          label={t('addressEntry.englishKeyboard')}
          value="en"
          checked={values.locale === 'en'}
          onChange={(_, { value }) => {
            setFieldValue('locale', value);
          }}
        />
        <Form.Radio
          inline={true}
          label={t('addressEntry.frenchKeyboard')}
          value="fr"
          checked={values.locale === 'fr'}
          onChange={(_, { value }) => {
            setFieldValue('locale', value);
          }}
        />
      </Form.Group>
    );
  }
}

export const KeyboardFormInputs = withNamespaces()(KeyboardFormInputsImpl);
