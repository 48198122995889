import * as React from 'react';
import { Formik, FormikHelpers as FormikActions, FormikErrors } from 'formik';
import { Form } from '../semantic';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { isMobilePhone } from 'validator';
import { postalCodeRegex } from '../constants';
import { IAddress, IFreeComputerInfo } from '../actions/api';
import { IContactInfo } from '../actions/app';
import { Button } from './common';
import { NameFormInputs } from './NameFormInputs';
import { AddressFormInputs } from './AddressFormInputs';
import { KeyboardFormInputs } from './KeyboardFormInputs';
import {
  ContactFormInputs,
  validateEmailAndConfirmationEmail,
} from './ContactFormInputs';
import { postalMatchesProvince } from '../util';
import { Heading } from './Heading';

type IFormValues = IFreeComputerInfo & IContactInfo;

interface IProps extends WithNamespaces {
  initialValues: IFormValues;
  isLoading: boolean;
  onSubmit: (
    values: IFormValues,
    formikBag: FormikActions<IFormValues>
  ) => void;
  submitButtonText: string;
  serviceAddress?: IAddress;
}

interface IState {
  showEmailPasteWarning: boolean;
}

class ComputerFormImpl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showEmailPasteWarning: false,
    };
    this.handleEmailPaste = this.handleEmailPaste.bind(this);
  }

  public handleEmailPaste() {
    this.setState({ showEmailPasteWarning: true });
  }

  public render() {
    const { props } = this;
    const { showEmailPasteWarning } = this.state;

    return (
      <Formik<IFormValues>
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        validate={(values) => {
          this.setState({ showEmailPasteWarning: false });

          const errors: FormikErrors<IFormValues> = validateEmailAndConfirmationEmail(
            props.t,
            values,
            props.t('formValidation.emailMatch')
          );

          if (!values.firstName) {
            errors.firstName = props.t('formValidation.firstNameRequired');
          }
          if (!values.lastName) {
            errors.lastName = props.t('formValidation.lastNameRequired');
          }
          if (!values.line1) {
            errors.line1 = props.t('formValidation.line1Required');
          }
          if (!values.city) {
            errors.city = props.t('formValidation.cityRequired');
          }
          if (!values.province) {
            errors.province = props.t('formValidation.provinceRequired');
          }
          if (!values.postalCode) {
            errors.postalCode = props.t('formValidation.postalCodeRequired');
          }

          if (!postalCodeRegex.test(values.postalCode)) {
            errors.postalCode = props.t('formValidation.postalCodeInvalid');
          } else if (
            values.province &&
            !postalMatchesProvince(values.postalCode, values.province)
          ) {
            errors.postalCode = props.t(
              'formValidation.postalCodeMustMatchProvince'
            );
          }

          // Phone is required for computer purchasing.
          if (
            !values.phone ||
            (values.phone && !isMobilePhone(values.phone, 'en-CA'))
          ) {
            errors.phone = props.t('formValidation.phoneFormat');
          }

          return errors;
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setFieldError,
        }) => {
          return (
            <Form
              autoComplete="off"
              error={true}
              onSubmit={handleSubmit}
              size="large"
            >
              <Heading>
                {props.t('screens.computerAddressEntry.prompt')}
              </Heading>
              <p>{props.t('screens.computerAddressEntry.paragraph1')}</p>

              <NameFormInputs
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />

              <AddressFormInputs
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                noPoBox={false}
                setFieldValue={setFieldValue}
                serviceAddress={props.serviceAddress}
              />

              <KeyboardFormInputs
                values={values}
                setFieldValue={setFieldValue}
              />

              <ContactFormInputs
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                introMessage="addressEntry.computerEmailPhoneHelp"
                onEmailPaste={() => {
                  // clear existing validation error on paste
                  setFieldError('confirmEmail', '');
                  this.handleEmailPaste();
                }}
                setFieldValue={setFieldValue}
                showEmailPasteWarning={showEmailPasteWarning}
              />

              <Button
                disabled={isSubmitting}
                loading={props.isLoading}
                type="submit"
              >
                {props.submitButtonText}
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export const ComputerForm = withNamespaces()(ComputerFormImpl);
