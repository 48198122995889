import { IAddress, IAddressWithName, IIsp } from '../actions/api';
import { IContactInfo } from '../actions/app';
import { getTranslation } from './';

const availableIsps: IIsp[] = [
  {
    id: 'bell',
    displayType: 'show-all',
    name: 'Bell',
    phone: getTranslation('1 888 111 2222'),
    phoneTTY: '1 888 111 2223',
    shortName: 'bell',
    url: getTranslation('https://www.bell.ca'),
  },
  {
    id: 'telus',
    displayType: 'show-all',
    name: 'Telus',
    phone: getTranslation('1 888 222 3333'),
    phoneTTY: '1 888 222 3334',
    shortName: 'telus',
    url: getTranslation('https://www.telus.ca'),
  },
  {
    id: 'rogers',
    displayType: 'show-all',
    name: 'Rogers',
    phone: getTranslation('1 888 333 4444'),
    phoneTTY: '1 888 333 4445',
    shortName: 'rogers',
    url: getTranslation('https://www.rogers.ca'),
  },
];

const contactInfo: IContactInfo = {
  email: 'katherine@example.com',
  phone: '555-555-5555',
};

const mailingAddress: IAddress = {
  city: 'Ottawa',
  line1: '123 Fake St',
  line2: 'Apt 3',
  postalCode: 'K1A0A6',
  province: 'ON',
};

const computerAddress: IAddressWithName = {
  ...mailingAddress,
  firstName: 'Katherine',
  lastName: 'Kane',
};

const serviceAddress = mailingAddress;

const pin = 'A3B4C5D6';

const provider = 'telus';

const providerInternetService = false;

export const mocks = {
  availableIsps,
  computerAddress,
  contactInfo,
  mailingAddress,
  pin,
  provider,
  providerInternetService,
  serviceAddress,
};
