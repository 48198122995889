import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Container } from '../../semantic';
import { Language } from '../../typings/Language';

interface IOwnProps {
  locale: Language;
}

type Props = IOwnProps & WithNamespaces;

class Component extends React.Component<Props> {
  public render() {
    const { t } = this.props;

    const cfscCode = t('abbreviations.code.CFSC');
    const cfscName = t('abbreviations.name.CFSC');
    const opecCode = t('abbreviations.code.OPEC');
    const opecName = t('abbreviations.name.OPEC');

    const cfscOpec = (
      <React.Fragment>
        <abbr title={cfscName}>{cfscCode}</abbr>-
        <abbr title={opecName}>{opecCode}</abbr>
      </React.Fragment>
    );

    const en = (
      <React.Fragment>
        <h1>Disclosure Policy:</h1>
        <p>
          Computers for Success Canada – Ordinateurs pour l’excellence Canada (“
          {cfscOpec}
          ”) is committed to protecting your privacy. The following privacy
          policy applies to the collection, use, and disclosure (by disclosure
          we mean who this information could be shared with) by {cfscOpec} of
          information regarding identifiable individuals (your Personal
          Information) from the {cfscOpec} and “Connecting Families” – websites.
        </p>
        <p>
          For the purposes of this Policy, the term "Personal Information"
          refers to any information about an identifiable individual or any
          information that allows an individual to be identified. An
          identifiable natural person is one who can be identified, directly or
          indirectly, in particular by reference to an identifier such as a
          name, an identification number, location data, an online identifier or
          to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural or social identity of that natural
          person.
        </p>
        <h2>I. Collection of your Personal Information</h2>
        <p>
          {cfscOpec} will collect the following identifiable data and Personal
          Information:
        </p>
        <ol>
          <li>
            <b>Contact information:</b> your name, home address, email address,
            and your phone number (including mobile phone number where
            provided).
          </li>
          <li>
            <b>Demographic information:</b> demographic information relating to
            you and other inhabitants of your household, if any (by
            “demographic”, we mean information such as the age and gender of
            people who live with you).
          </li>
          <li>
            <b>Supplier data:</b> contact details and other information about
            you or your company or organization by which you provide services to
            {cfscOpec};
          </li>
          <li>
            <b>Social media:</b> any posts, Likes, tweets and other interactions
            with our social media presence;
          </li>
          <li>
            <b>Technical information:</b> [when you access this website and our
            technology services] technical information being Internet Protocol
            address, browser type and version (e.g. Internet Explorer, Firefox,
            Safari etc.), language preference, access times, time zone setting,
            browser plug-in types and versions, operating system you are using
            (e.g. Vista, Windows XP, MacOS, etc.), device type, hardware model,
            MAC address, unique identifiers and mobile network information; as
            well as any other standard information your web browser sends to
            every website you visit;
          </li>
          <li>
            <b>Online data:</b> [when you access this website and our technology
            services] information about your visit, including URL clickstream
            to, through and from our website (including date and time),
            information about your network as such as information about devices,
            nodes, configurations, connection speeds and network application
            performance; pages viewed or searched for, page response times,
            download errors, length of visits and interaction information (such
            as scrolling, clicks, mouse-overs) and whether you click on
            particular links or open our emails.
          </li>
        </ol>
        <p>
          Our collection of the information set-out in sections “Technical
          information” and “Online data” hereabove is done anonymously. We
          cannot connect (a) your Personal Information with (c) what {cfscOpec}
          websites you click on, view or consult.
        </p>
        <p>
          {cfscOpec} does not collect Personal Information about your online
          activities across third party websites or online services.
        </p>
        <p>
          {cfscOpec} does not knowingly collect information from children or
          other persons who are under 16 years old. If you are a minor under 16
          years old, please do not provide us with any Personal Information
          without the express consent of a parent or guardian. If you are a
          parent or guardian and you know that your children have provided us
          with Personal Information, please contact us. If we learn that we have
          collected personal information from minor children without
          verification of parental consent, we will take steps to remove that
          information from our servers.
        </p>
        <h2>II. Use of your Personal Information</h2>
        <p>
          {cfscOpec} uses your Personal Information to identify you so that we
          can determine if you qualify for programs and initiatives we help
          deliver. We will not share this Personal Information with any third
          parties other than those listed below. {cfscOpec} will only process
          your Personal Information after you have given us your prior consent
          or if this is necessary to comply with legal or regulatory
          obligations.
        </p>
        <p>
          {cfscOpec} uses your personal information to contact you regarding
          your participation in the “Connecting-Families” initiative. We may
          also use this information to collect anonymous demographic information
          to help {cfscOpec} determine whether the programs and initiatives we
          help deliver are working well according to their design and social
          objectives. Applicable legal bases under the GDPR: legitimate
          interests, consent, contract performance and to carry out actions for
          the conclusion of the contract.
        </p>
        <p>
          Your email will not be added to a contact or mailing list unless you
          agree and choose to sign-up – your positive consent would be required.
        </p>
        <p>
          <b>
            <u>Our reasons for using special category data </u>
          </b>
        </p>
        <p>
          “Special category data”, as said term is used in the European Union
          and certain other jurisdictions, refers to sensitive data such as your
          social demographic, racial or ethnic origin, religious beliefs or
          health data. We will process this data where:
        </p>
        <ul>
          <li>we have your explicit consent for the particular processing;</li>
          <li>this is necessary for substantial public interest;</li>
          <li>as permitted by applicable law.</li>
        </ul>
        <h2>III. Disclosure or Transfer of your Personal Information</h2>
        <p>
          {cfscOpec} shares your Personal Information with Partner
          Organizations* and Service Providers** to the extent that it is
          required for them to deliver programs and services. We require these
          organizations to also maintain the confidentiality of your information
          and respect of your privacy, just as we do.
        </p>
        <small>
          *Partner Organizations may refer to:
          <ul>
            <li>Computers for Schools program affiliates;</li>
            <li>An independent academic research committee;</li>
            <li>Innovation, Science and Economic Development Canada.</li>
          </ul>
        </small>
        <small>
          **Service Providers may refer to:
          <ul>
            <li>Internet Service Providers and/or software providers;</li>
            <li>Shipping partners;</li>
            <li>silverorange Inc./Web developer.</li>
            <li>Amazon Web Services</li>
          </ul>
        </small>
        <p>
          {cfscOpec} will not share, disclose, or otherwise provide the Personal
          Information supplied by you, the website user, to Partner
          Organizations and Service Providers for any reason other those stated
          above.
        </p>
        <h2>IV. Security of your Personal Information</h2>
        <p>
          {cfscOpec} is committed to protecting the security of your Personal
          Information and to having technical safeguards to protect your
          confidentiality. All Personal Information is stored in a highly secure
          data center in Canada. This center uses strong encryption, while in
          transit and at rest, and employs encryption in transit with Transport
          Layer Security (TLS) across all services.
        </p>
        <p>
          {cfscOpec} will protect Personal Information through appropriate
          security measures against risks such as loss, theft, unauthorized
          access, disclosure, copying, use, modification, or destruction.
        </p>
        <p>
          {cfscOpec} will protect Personal Information disclosed to Partner
          Organizations and Service Partners. All parties involved agree to sign
          a non-disclosure agreement (NDA). Parties agree that all Personal
          Information (as defined in the NDA) they have access to, will be
          treated as confidential and only used for the primary purpose intended
          – delivering programs and initiatives relative to those outlined on
          our websites.
        </p>
        <p>
          To the extent {cfscOpec} employs third-party Service Providers to
          store, handle or process Personal Information on its behalf (e.g.,
          data storage and processing, cloud computing, application hosting or
          office services), {cfscOpec} uses contractual and other means to
          provide a comparable level of protection while the information is
          being stored, handled or processed by those Service Providers.
        </p>
        <p>
          {cfscOpec}’s Service Providers are committed by contractual agreements
          to protect your Personal Information from unauthorized access,
          collection, use or disclosure. {cfscOpec}’s Service Providers are not
          permitted to disclose your information to others.
        </p>
        <p>
          Some of {cfscOpec}’s Service Providers may store your Personal
          Information in countries other than Canada. If it is required by law
          or court order, your Personal Information could be made accessible to
          legal authorities, including law enforcement and national security
          authorities of those countries.
        </p>
        <p>
          If you have questions about the collection, use, disclosure or storage
          of your Personal Information by {cfscOpec}’s Service Providers outside
          of Canada, or if you would like to obtain more information about our
          policies and practices with respect to Service Providers outside of
          Canada, please contact one of our Privacy Officers listed below.
        </p>
        <h2>V. Use of Cookies and Web Beacons</h2>
        <p>
          Cookies are small text files stored on your device (such as your
          computer or smartphone) that are uniquely assigned to you which
          collect standard Internet log information and visitor behavior
          information. For example, cookies can be used to remember information
          about you, your preferences (such as your language of choice) or your
          device information, when you return to a specific website. Similar
          technologies falling under the generic term “cookies” also include
          local objects (sometimes called flash cookies), web beacons, pixels,
          browser fingerprinting technologies or any technology that stores or
          accesses information on the user's device. This information does not
          usually allow us to identify you, but it can offer you a better user
          experience when visiting websites (including ours).
        </p>
        <p>
          Although most web browsers automatically accept cookies, you have the
          ability to accept or decline them by modifying your browser settings.{' '}
        </p>
        <p>
          {cfscOpec} uses cookies to measure how users interact with our
          websites and to gather statistics that distinguish unique users.{' '}
          {cfscOpec} also monitors website statistics for the number and time of
          previous visits, the start and end of a user session and to remember
          the traffic source information. Traffic source information tells us
          how people access our website, for instance through a search engine or
          a link they clicked on. As for many other websites, {cfscOpec} uses
          this data to remember your settings and to help improve your
          experience on our website.
        </p>
        <p>
          {cfscOpec} also uses web beacons to provide statistical information
          about visitors to our websites. This sends small amounts of data to an
          analytics server with information about your IP Address, browser URL,
          and other browsing data.
        </p>
        <p>
          This website uses Google Analytics, which uses cookies and transmits
          data to Google’s servers. Google Analytics does not identify
          individual users or associate your IP address to any other data held
          by Google. We use Google Analytics’ reports to help us understand user
          behaviours on our website in order to help us serve a better web
          experience.
        </p>
        <p>
          {cfscOpec} uses Google Adwords to advertise. Google uses cookies to
          deliver ads on websites across the internet based on your browsing
          history. You can opt out of third-party vendor’s use of cookies by
          visiting the{' '}
          <a
            href="http://optout.networkadvertising.org/?c=1#!%2F"
            target="_blank"
            rel="noreferrer"
          >
            Network Advertising Initiative opt-out page
          </a>
          .
        </p>
        <h2>VI. What cookies do we use?</h2>
        <ol type="A">
          <b>
            <li>Strictly necessary cookies</li>
          </b>
          <p>
            These are cookies that are required for the operation of our
            websites and use of its features, and therefore cannot be switched
            off in our systems. The information collected relates to the
            operation of our website (e.g. website scripting language and
            security tokens) and enables us to provide you with the service you
            have requested. You can set your browser to block or alert you about
            these cookies, but some parts of the site will not then work. These
            cookies do not store any personal data. These cookies are: used
            solely for the purpose of carrying out or facilitating the
            transmission of a communication over an electronic communications
            network; and strictly necessary to provide you with the information
            society service you have expressly requested (i.e. if these cookies
            are disabled, We will not be able to provide you with the service
            you have expressly requested). The use of these cookies for the
            purposes described above is based on our legitimate interest to
            ensure the proper technical functioning of, access to and use of our
            websites or apps and to provide you with the service(s) you have
            expressly requested.
          </p>
          <table className="ui small celled table">
            <thead>
              <tr>
                <th>Cookie Subgroup</th>
                <th>Cookies</th>
                <th>Cookies used</th>
                <th>Lifespan</th>
              </tr>
            </thead>
          </table>
          <b>
            <li>Functional cookies</li>
          </b>
          <p>
            These cookies are optional and are used to remember the choices you
            have made, for example the country you visit our website from, your
            language and any changes you have made to text size and other parts
            of the web pages that you can customise to improve your user
            experience and to make your visits more tailored and enjoyable. If
            you do not allow these cookies then some or all of these services
            may not function properly.
          </p>
          <table className="ui small collapsing celled table">
            <thead>
              <tr>
                <th>Cookie Subgroup</th>
                <th>Cookies</th>
                <th>Cookies used</th>
                <th>Lifespan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Cookie Subgroup">.linkedin.com</td>
                <td data-label="Cookies">UserMatchHistory</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">1 month</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">ads.linkedin.com</td>
                <td data-label="Cookies">lang</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">Session</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.linkedin.com</td>
                <td data-label="Cookies">bcookie</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">2 years</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.linkedin.com</td>
                <td data-label="Cookies">lidc</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">1 day</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.linkedin.com</td>
                <td data-label="Cookies">lang</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">Session</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.linkedin.com</td>
                <td data-label="Cookies">bscookie</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">2 years</td>
              </tr>
            </tbody>
          </table>
          <b>
            <li>Analytical / performance cookies</li>
          </b>
          <p>
            These cookies are optional and are used to collect information on
            how visitors use our website, for example which pages they visit
            most often, whether they receive any error messages and how they
            arrived at our website. Information collected by these cookies is
            used only to improve the use of our website by our visitors. These
            cookies are sometimes placed by third party providers of web traffic
            and analysis services. All information these cookies collect is
            aggregated and therefore anonymous. If you do not allow these
            cookies we will not know when people have visited our site and will
            not be able to monitor its performance.
          </p>
          <table className="ui small collapsing celled table">
            <thead>
              <tr>
                <th>Cookie Subgroup</th>
                <th>Cookies</th>
                <th>Cookies used</th>
                <th>Lifespan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Cookie Subgroup">.connecting-families.ca</td>
                <td data-label="Cookies">_ga</td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">2 years</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.connecting-families.ca</td>
                <td data-label="Cookies">_gid</td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">24 hours</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.connecting-families.ca</td>
                <td data-label="Cookies">_gat </td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">1 minute</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.cfsc-opec.org</td>
                <td data-label="Cookies">_gcl_au</td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">3 months</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.cfsc-opec.org</td>
                <td data-label="Cookies">_ga</td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">2 years</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.cfsc-opec.org</td>
                <td data-label="Cookies">_gid</td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">24 hours</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.cfsc-opec.org</td>
                <td data-label="Cookies">_gat_gtag</td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">1 minute</td>
              </tr>
            </tbody>
          </table>
          <b>
            <li>Targeting cookies</li>
          </b>
          <p>
            These cookies record your visit to our website, the pages you have
            visited and the links you have followed. We may use this information
            to make our website and the information (including marketing
            information) displayed on it more relevant to your interests and to
            measure the effectiveness of and marketing or information
            initiatives. We may also share this information with third parties
            for this purpose. They remember the other websites you visit and
            this information is shared with third party organizations as
            described below. These are also optional cookies. If you do not
            allow these cookies, you will experience less targeted advertising.
          </p>
          <table className="ui small collapsing celled table">
            <thead>
              <tr>
                <th>Cookie Subgroup</th>
                <th>Cookies</th>
                <th>Cookies used</th>
                <th>Lifespan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Cookie Subgroup">.cfsc-opec.org</td>
                <td data-label="Cookies">_fbp</td>
                <td data-label="Cookies used">First Party</td>
                <td data-label="Lifespan">3 months</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.doubleclick.net</td>
                <td data-label="Cookies">test_cookie</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">15 months</td>
              </tr>
              <tr>
                <td data-label="Cookie Subgroup">.doubleclick.net</td>
                <td data-label="Cookies">IDE</td>
                <td data-label="Cookies used">Third party</td>
                <td data-label="Lifespan">1 year 24 days</td>
              </tr>
            </tbody>
          </table>
        </ol>
        <h2>VII. Statistics and sharing, Links</h2>
        <p>
          {cfscOpec}’s websites, newsletters, email updates and other
          communications, may from time to time contain links to and from other
          websites not owned or operated by {cfscOpec}. {cfscOpec} does not
          claim responsibility for the privacy practices of these websites. When
          leaving a {cfscOpec} website, we encourage users to review the
          separate privacy policies of every website they visit.
        </p>
        <p>
          The privacy policy reflected on this website applies to Personal
          Information collected by {cfscOpec} websites only.
        </p>
        <p>
          If you follow a link to any other websites, please note that these
          websites have their own privacy notices which will set out how your
          information is collected and processed when visiting those sites. The
          personal data that you provide through these websites is not subject
          to this Privacy Policy and the treatment of your personal data by such
          websites is not {cfscOpec}’s responsibility.{' '}
        </p>
        <h2>VIII. Changes to Policy</h2>
        <p>
          Privacy laws are rapidly evolving and, as a result, this Policy may be
          changed by {cfscOpec} from time to time in {cfscOpec}’s full
          discretion without any prior notice or liability to you or any other
          person. The collection, use and disclosure of your personal
          information by {cfscOpec} will be governed by the version of this
          Policy in effect at that time.
        </p>
        <p>
          {cfscOpec} encourages users to review any updates or changes since
          their previous visit.
        </p>
        <h2>IX. Consent</h2>
        <p>
          At {cfscOpec}, it is important that we only collect, use or disclose
          your Personal Information where we have your positive consent to do
          so.
        </p>
        <p>
          {cfscOpec} will ensure that users of our websites understand how their
          Personal Information is being used. {cfscOpec} will typically ask for
          a user’s consent when collecting Personal Information.
        </p>
        <p>
          {cfscOpec} may disclose a user’s Personal Information where legally
          required to do so.
        </p>
        <h2>X. What are your data protection rights?</h2>
        <p>
          {cfscOpec} would like to make sure you are fully aware of all of your
          data protection rights. Every user is entitled to the following:
        </p>
        <ul>
          <li>
            <b>The right to access</b> – You are entitled to ask {cfscOpec} if
            we are processing your data and, if so, you can request access to
            your Personal Information. This enables you to receive a copy of the
            data we hold regarding your Personal Information.
          </li>
          <li>
            <b>The right to rectification</b> – You are entitled to request that
            any incomplete or inaccurate Personal Information about you held by{' '}
            {cfscOpec} is corrected. You are equally entitled to request{' '}
            {cfscOpec} to complete the Personal Information about you held by
            {cfscOpec} you believe is incomplete.
          </li>
          <li>
            <b>The right to erasure</b> – You are entitled to request that{' '}
            {cfscOpec} delete or remove any Personal Information about you held
            by {cfscOpec}, under certain conditions. There are also certain
            exceptions where {cfscOpec} may refuse a request for erasure, for
            example, where the personal data is required for compliance with law
            or in connection with claims.
          </li>
          <li>
            <b>The right to restrict processing</b> – You are entitled to
            request that {cfscOpec} restrict or suspend the processing of your
            Personal Information, under certain conditions, notably if you want{' '}
            {cfscOpec} to establish its accuracy or {cfscOpec}'s reason for
            processing said Personal Information.
          </li>
          <li>
            <b>The right to object to processing</b> – You are entitled to
            object to {cfscOpec}’s processing of your Personal Information,
            under certain conditions, where we are processing your personal data
            based on legitimate interests of {cfscOpec} (or those of a third
            party).
          </li>
          <li>
            <b>The right to data portability</b> – You are entitled to request
            that {cfscOpec} transfer the data that it has collected to another
            organization or party, or directly to you, under certain conditions.
          </li>
        </ul>
        <p>
          If you want to exercise any of these rights, please contact one of the
          Data Privacy contacts set out in this Policy below, in writing at the
          relevant email or mailing address.
        </p>
        <p>
          You also have a right to lodge a complaint with a data protection
          supervisory authority, in particular in the Province of Canada
          (provincial and/or federal level), country, or Member State in the
          European Union, where you are habitually resident, where {cfscOpec} is
          based or where an alleged infringement of any relevant Data Protection
          law has taken place.
        </p>
        <h2>XI. Contact our Privacy Officers</h2>
        <p>
          For any questions, concerns or comments about {cfscOpec}
          ’s Privacy Policy or If you wish to access information, modification
          or deletion regarding your Personal Information Personal Information,
          to exercise any applicable rights, to make a complaint, or to obtain
          information about our policies and practices with respect to any
          service providers outside Canada, our Privacy Officer can be reached
          by email at <a href="mailto:info@cfsc-opec.org">
            info@cfsc-opec.org
          </a>{' '}
          or by mail using the following contact information:
        </p>
        <Container textAlign="center">
          <p>Computers for Success – Canada Incorporated</p>
          <p>
            Attn: <b>Toby Harper Merrett</b>
          </p>
          <p>Privacy Officer</p>
          <p>1, carrefour Alexander Graham Bell, Suite 2B</p>
          <p>Verdun, Québec, H3E 3B3, Canada</p>
        </Container>
        <p>
          Subject to certain exceptions prescribed by law, you will be given
          reasonable access to your personal information and will be entitled to
          challenge the accuracy and completeness of that information and, to
          the extent that you have proven such inaccuracy or incompleteness,
          have it amended as appropriate. Examples of such exceptions include
          information that contains references to other individuals, and
          information that cannot be disclosed for legal, security or commercial
          proprietary reasons.
        </p>
        <p>
          You can help {cfscOpec} maintain the accuracy of your information by
          notifying {cfscOpec} of any changes to your Personal Information.
        </p>
        <p>
          If you have any inquiry or complaint that relates to {cfscOpec}’s
          policies or procedures relating to the handling of personal
          information, we invite you to communicate with our Privacy Officer.
        </p>
      </React.Fragment>
    );

    const fr = (
      <React.Fragment>
        <h1>Politique de divulgation:</h1>
        <p>
          Computers for Success Canada – Ordinateurs pour l’excellence Canada («
          {cfscOpec} ») s'engage à protéger votre vie privée. Cette politique de
          confidentialité s'applique à la collecte, l'utilisation et la
          divulgation (par divulgation, nous signifions ceux avec qui les
          renseignements pourraient être partagés) par {cfscOpec} de
          renseignements concernant des personnes identifiables (vos
          Renseignements personnels) à partir des sites Web de {cfscOpec}et de «
          Familles branchées »
        </p>
        <p>
          Aux fins de la présente politique, le terme « Information personnelle
          » (ou « Renseignement personnel ») se réfère à toute information
          concernant un individu identifiable ou toute information qui permet
          d'identifier un individu. Une personne physique identifiable est une
          personne qui peut être identifiée, directement ou indirectement, en
          particulier en se référant à un identifiant tel qu'un nom, un numéro
          d'identification, des données de localisation, un identifiant en ligne
          ou à l'un ou plusieurs facteurs spécifiques à l'identité physique,
          physiologique, génétique, mentale, économique, culturelle ou sociale
          de cette personne physique.
        </p>
        <h2>I. Collecte de vos Renseignements personnels</h2>
        <p>
          {cfscOpec} collectera les données et Informations personnelles
          suivantes :
        </p>
        <ol>
          <li>
            <b>Coordonnées:</b> vos nom, adresse résidentielle, adresse de
            courriel et votre numéro de téléphone (incluant le numéro de
            téléphone portable lorsque fourni).
          </li>
          <li>
            <b>Informations démographiques:</b> des renseignements
            démographiques vous concernant, et si c'est le cas, les autres
            habitants de votre foyer (par « démographique », nous entendons des
            renseignements tels que l'âge et le genre des gens qui vivent avec
            vous).
          </li>
          <li>
            <b>Données sur les fournisseurs :</b> des coordonnées et d'autres
            renseignements sur vous ou votre entreprise ou organisme par lequel
            vous fournissez des services à {cfscOpec} ;
          </li>
          <li>
            <b>Médias sociaux:</b> toutes publications, mentions « J'aime »,
            gazouillis (« tweets ») et autres interactions avec notre présence
            dans les médias sociaux;
          </li>
          <li>
            <b>Renseignements techniques :</b> [lorsque vous accédez à ce site
            Web et nos services technologiques] ces renseignements techniques
            étant l'adresse de protocole Internet (« adresse IP »), le type et
            la version du navigateur (p ex. Internet Explorer, Firefox, Safari,
            etc.), les langues de préférence, les heures d'accès, la
            configuration du fuseau horaire, les types d'applications
            d'extension (« plug-in ») et leurs versions, le système
            d'exploitation utilisé (p ex. Vista, Windows XP, Mac OS, etc.), le
            type de périphérique, le modèle de l'appareil, l'adresse MAC, les
            identifiants uniques et les renseignements du réseau mobile, ainsi
            que toute autre information standard que votre navigateur Web
            transmet à chaque site Web que vous visitez;
          </li>
          <li>
            <b>Données en ligne:</b> lorsque vous accédez à ce site Web et nos
            services technologiques] des informations sur votre visite, incluant
            le flux de clics URL vers, à travers et à partir de notre site Web,
            des informations concernant votre réseau, telles que des
            informations sur les appareils, les nœuds, les configurations, les
            vitesses de connexion et la performance de l'application réseau ;
            les pages vues ou recherchées, les délais de réponse des pages, les
            erreurs de téléchargement, la durée des visites et les
            renseignements d'interaction (tels que les déroulements d'écran, les
            clics, les déplacements de souris) et si vous cliquez sur des liens
            particuliers ou ouvrez nos courriels.
          </li>
        </ol>
        <p>
          Notre collecte des informations définies dans les sections «
          Renseignements techniques » et « Données en ligne » ci-dessus se fait
          de façon anonyme. Nous ne pouvons relier (a) vos Renseignements
          personnels avec (c) les sites Web de {cfscOpec} sur lesquels vous
          cliquez, que vous regardez ou que vous consultez.
        </p>
        <p>
          {cfscOpec} ne recueille pas de Renseignements personnels sur vos
          activités en ligne par le biais de sites Web ou de services en ligne
          tiers.
        </p>
        <p>
          {cfscOpec} ne collecte pas sciemment de renseignements sur des enfants
          ou d'autres personnes âgées de moins de 16 ans. Si vous êtes un mineur
          de moins de 16 ans, veuillez vous abstenir de nous fournir quelque
          Renseignement personnel que ce soit sans le consentement exprès d'un
          parent ou d'un tuteur. Si vous êtes un parent ou un tuteur et que vous
          savez que vos enfants nous ont fourni des Renseignements personnels,
          contactez-nous. Si nous apprenons avoir reçu d'enfants des
          informations personnelles sans vérification ou consentement parental
          préalable, nous prendrons les mesures nécessaires pour supprimer ces
          informations de nos serveurs.
        </p>
        <h2>II. Utilisation de vos Renseignements personnels</h2>
        <p>
          {cfscOpec} OPEC utilise vos Renseignements personnels pour vous
          identifier et déterminer si vous êtes admissible aux programmes et aux
          initiatives que nous aidons à fournir. Nous ne partagerons aucun de
          ces renseignements avec des tiers, excepté ceux que nous notons
          ci-dessous. {cfscOpec} traitera ces Renseignements personnels
          seulement lorsque vous nous aurez donné votre consentement préalable,
          ou s'il devient nécessaire de le faire pour se conformer à des
          obligations légales ou réglementaires.
        </p>
        <p>
          {cfscOpec} utilise vos renseignements personnels pour vous contacter
          concernant votre participation à l'initiative Familles branchées. Nous
          pouvons aussi utiliser ces renseignements pour recueillir de
          l'information démographique anonyme pour aider {cfscOpec} à évaluer le
          bon fonctionnement des programmes et initiatives que nous contribuons
          à mettre en œuvre, selon leur conception et leurs objectifs sociaux.
        </p>
        <p>
          Votre adresse courriel ne sera pas ajoutée à une liste de contacts ou
          de diffusion d'infolettres, à moins que vous acceptiez et choisissiez
          de vous inscrire. Votre permission sera alors nécessaire.
        </p>
        <p>
          <b>
            Nos raisons d'utiliser des données portant sur des catégories
            particulières :
          </b>
        </p>
        <p>
          Les « données portant sur des catégories particulières », terme
          utilisé dans l'Union européenne et certaines autres juridictions, se
          rapportent à des données personnelles telles que votre profil social
          démographique, votre origine raciale ou ethnique, vos croyances
          religieuses ou des données concernant la santé. Nous traiterons ces
          données lorsque :
        </p>
        <ul>
          <li>
            nous aurons votre accord explicite pour ce traitement particulier;
          </li>
          <li>
            cela s'avère nécessaire à des motifs d'intérêt public important ;{' '}
          </li>
          <li>dans la mesure où la loi applicable le permet. </li>
        </ul>
        <h2>III. Divulgation ou transfert de vos renseignements personnels</h2>
        <p>
          {cfscOpec} partage vos Renseignements personnels avec des
          Organisations partenaires* et des Fournisseurs de services** dans la
          limite où ils sont requis pour fournir des programmes et des services.
          Nous exigeons de ces organisations qu'elles préservent également la
          confidentialité de vos renseignements et respectent votre vie privée
          autant que nous.
        </p>
        <small>
          * Les « organisations partenaires » se reportent:
          <ul>
            <li>Aux affiliés du programme Ordinateurs pour les écoles;</li>
            <li>À un comité universitaire de recherche indépendant;</li>
            <li>À Innovation, Science et Développement économique Canada.</li>
          </ul>
        </small>
        <small>
          **Les « fournisseurs de service » se reportent:
          <ul>
            <li>
              À des Fournisseurs de services Internet ou des fournisseurs de
              logiciels;
            </li>
            <li>À des partenaires de livraison; </li>
            <li>À Silverorange Inc./développeur Web </li>
            <li>Aux services Web d'Amazon. </li>
          </ul>
        </small>
        <p>
          {cfscOpec} ne partagera, dévoilera ni ne fournira autrement les
          Renseignements personnels fournis par vous, l'utilisateur du site Web,
          à des Organisations partenaires ou des Fournisseurs de services,
          autrement que pour les raisons énoncées ci-dessus.
        </p>
        <h2>IV. Sécurité et stockage de vos Renseignements personnels</h2>
        <p>
          {cfscOpec} s'engage à protéger vos Renseignements personnels et à
          employer des mesures techniques de sécurité pour assurer leur
          confidentialité. Tous les Renseignements personnels sont enregistrés
          dans un centre de données hautement sécurisé au Canada. Ce centre
          utilise un cryptage élevé, tant en transit qu'au repos, et utilise en
          transit et parmi tous ses services un cryptage avec le protocole de
          sécurité de la couche transport (protocole TLS).
        </p>
        <p>
          {cfscOpec} utilisera des mesures de sécurité adéquates pour protéger
          les Renseignements personnels contre des risques tels que la perte, le
          vol, l'accès non autorisé, la divulgation, la reproduction,
          l'utilisation, la modification ou la destruction.
        </p>
        <p>
          {cfscOpec} protègera les Renseignements personnels communiqués à ses
          organisations partenaires et ses partenaires de services. Toutes les
          parties en cause consentent à signer une Entente de non-divulgation.
          Les parties reconnaissent que tous les Renseignements personnels (tels
          que définis dans l'Entente) auxquels ils ont accès seront traités de
          manière confidentielle et seront seulement utilisés aux fins initiales
          prévues, soit fournir des programmes et des initiatives relatives à
          ceux et celles que présentent nos sites Web.
        </p>
        <p>
          Dans la mesure où {cfscOpec} emploie des Fournisseurs de services
          tiers pour stocker, manipuler ou traiter en son nom des Renseignements
          personnels (p. ex. le stockage et le traitement de données,
          l'informatique en nuage, l'hébergement d'applications ou des services
          de bureau), {cfscOpec} utilise des moyens contractuels et autres pour
          fournir un niveau de protection comparable lorsque l'information est
          stockée, manipulée ou traitée par ces Fournisseurs de services.
        </p>
        <p>
          Les Fournisseurs de services de {cfscOpec} sont tenus par contrat de
          protéger vos Renseignements personnels contre l'accès non autorisé, la
          collecte, l'utilisation ou la divulgation. Les Fournisseurs de
          services de {cfscOpec} n'ont pas l'autorisation de divulguer vos
          informations à d'autres.
        </p>
        <p>
          Certains des Fournisseurs de services de {cfscOpec} pourraient stocker
          vos Renseignements personnels dans d'autres pays que le Canada. Si
          cela est requis par la loi ou par ordre d’un tribunal, vos
          Renseignements personnels pourraient être rendus accessibles aux
          autorités légales, incluant les autorités chargées de l'application de
          la loi et aux autorités de la sécurité nationale de ces pays.
        </p>
        <p>
          Si vous avez des questions concernant la collecte, l'utilisation, la
          divulgation ou le stockage de vos Renseignements personnels par les
          Fournisseurs de services de {cfscOpec} hors Canada, ou si vous désirez
          obtenir plus d'informations sur nos pratiques et nos politiques
          concernant les Fournisseurs de service hors Canada, contactez l'un de
          nos officiers de protection de la vie privée dans la liste ci-dessous.
        </p>
        <h2>V. Usage de témoins (« cookies ») et de pixels invisibles</h2>
        <p>
          Les témoins (« cookies ») sont de petits fichiers texte stockés dans
          votre appareil (comme votre ordinateur ou votre téléphone
          intelligent), qui vous sont uniquement attribués, et qui recueillent
          des informations standard sur la navigation et le comportement du
          visiteur sur Internet. Par exemple, les témoins peuvent être utilisés
          pour retenir des informations sur vous, vos préférences (comme votre
          langue de choix) ou des informations sur votre appareil, lorsque vous
          retournez sur un site Web spécifique. Des technologies similaires
          couvertes par le terme générique de « témoins » ou « cookies »
          comprennent aussi les technologies d'objets locaux partagés (parfois
          appelés « flash cookies »), de pixels invisibles et d'empreinte
          digitale de navigateur, ou toute autre technologie qui amasse ou
          accède à de l'information sur l'appareil de l'usager. Cette
          information ne nous permet habituellement pas de vous identifier, mais
          peut améliorer votre expérience d'utilisateur quand vous visitez des
          sites Web (incluant le nôtre).
        </p>
        <p>
          Malgré que la plupart des navigateurs web acceptent les témoins
          automatiquement, vous avez la possibilité de les accepter ou de les
          refuser en modifiant les paramètres de votre navigateur.
        </p>
        <p>
          {cfscOpec} utilise des témoins pour mesurer l'interaction des
          utilisateurs avec nos sites Web et recueillir des statistiques qui
          distinguent les utilisateurs uniques. {cfscOpec} surveille également
          les statistiques des sites Web sur le nombre et l'heure des visites
          précédentes, le début et la fin d'une session d'utilisateur, et pour
          se souvenir des sources de trafic. L'information sur les sources de
          trafic nous indique comment les visiteurs accèdent à notre site Web,
          par exemple : par un moteur de recherche ou par un lien qu'ils ont
          cliqué. Comme pour beaucoup d'autres sites Web, {cfscOpec} utilise ces
          données pour reconnaître vos paramètres et améliorer votre expérience
          sur notre site Web.
        </p>
        <p>
          {cfscOpec} emploie aussi des pixels invisibles (« Web beacons ») pour
          obtenir des données statistiques sur les visiteurs de nos sites Web.
          Ces balises envoient de petits montants de données à un serveur
          analytique, telles que votre adresse IP, l'URL du navigateur et
          d'autres données de navigation.
        </p>
        <p>
          Ce site Web utilise Google Analytics, qui utilise des témoins et
          transmet des données aux serveurs Google. Google Analytics n'identifie
          pas d'utilisateurs individuels ni n'associe votre adresse IP à
          d'autres données détenues par Google. Nous employons les rapports de
          Google Analytics afin de comprendre le comportement des utilisateurs
          et nous aider à améliorer leur expérience avec notre site Web.
        </p>
        <p>
          {cfscOpec} utilise Google Adwords à des fins publicitaires. Google
          utilise des témoins pour vous proposer des publicités basées sur votre
          historique de navigation sur les sites d'Internet. Vous pouvez bloquer
          l'utilisation de témoins par des tiers en visitant la page d'exclusion
          des initiatives de publicité{' '}
          <a
            href="http://optout.networkadvertising.org/?c=1#!%2F"
            target="_blank"
            rel="noreferrer"
          >
            Network Advertising Initiative opt-out page
          </a>{' '}
          (en anglais).
        </p>
        <h2>VI. Quels témoins utilisons-nous?</h2>
        <ol type="A">
          <b>
            <li>Témoins strictement nécessaires</li>
          </b>
          <p>
            Ce sont les témoins nécessaires au fonctionnement et l'utilisation
            des fonctionnalités de nos sites Web, et qui par conséquent ne
            peuvent pas être désactivés dans nos systèmes. L'information
            recueillie se rapporte au fonctionnement de notre site Web (comme le
            langage du script et les jetons de sécurité du site Web) et nous
            permet de fournir le service que vous avez demandé. Vous pouvez
            configurer votre navigateur pour qu'il bloque ces témoins ou vous en
            avertisse, mais dans ce cas certaines parties du site ne
            fonctionneront pas. Ces témoins n'emmagasinent aucune information
            personnelle. Ces témoins sont : utilisés dans le seul but
            d'effectuer ou de faciliter la transmission d'une communication sur
            un réseau de communications électroniques, et strictement
            nécessaires pour vous fournir le service de société d'information
            que vous avez expressément demandé (p. ex. si ces témoins sont
            désactivés, nous ne serons pas en mesure de vous fournir le service
            que vous avez expressément demandé). L'utilisation de ces témoins
            selon les objectifs décrits ci-dessus se fonde sur notre intérêt
            légitime pour assurer le bon fonctionnement, l'accessibilité et
            l'utilisation de nos sites Web et nos applications, et de vous
            fournir le(s) service(s) que vous avez expressément demandé(s).
          </p>
          <table className="ui small celled table">
            <thead>
              <tr>
                <th>Sous-groupe de témoin</th>
                <th>Témoins</th>
                <th>Témoins utilisés</th>
                <th>Durée de vie</th>
              </tr>
            </thead>
          </table>
          <b>
            <li>Témoins fonctionnels</li>
          </b>
          <p>
            Ces témoins sont facultatifs et servent à se souvenir des choix que
            vous avez faits, par exemple le pays à partir duquel vous visitez le
            site Web, votre langue de préférence et toute modification que vous
            avez faite à la taille des caractères ou à d'autres aspects des
            pages Web que vous pouvez personnaliser pour améliorer votre
            expérience d'utilisateur et rendre vos visites mieux ajustées et
            agréables. Si vous n'autorisez pas ces témoins, une partie ou la
            totalité de ces services pourraient fonctionner incorrectement.
          </p>
          <table className="ui small collapsing celled table">
            <thead>
              <tr>
                <th>Sous-groupe de témoin</th>
                <th>Témoins</th>
                <th>Témoins utilisés</th>
                <th>Durée de vie</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Sous-groupe de témoin">.linkedin.com</td>
                <td data-label="Témoins">UserMatchHistory</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">1 mois</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">ads.linkedin.com</td>
                <td data-label="Témoins">lang</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">Session</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.linkedin.com</td>
                <td data-label="Témoins">bcookie</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">2 ans</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.linkedin.com</td>
                <td data-label="Témoins">lidc</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">1 jour</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.linkedin.com</td>
                <td data-label="Témoins">lang</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">Session</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.linkedin.com</td>
                <td data-label="Témoins">bscookie</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">2 ans</td>
              </tr>
            </tbody>
          </table>
          <b>
            <li>Témoins analytiques / de performance</li>
          </b>
          <p>
            Ces témoins sont facultatifs et sont employés pour recueillir de
            l'information sur la façon dont nos visiteurs utilisent notre site
            Web, par exemple : les pages qu'ils visitent le plus, les messages
            d'erreur qu'ils peuvent avoir reçus, ou la manière dont ils sont
            parvenus sur notre site Web. L'information collectée par ces témoins
            nous sert uniquement à améliorer l'utilisation de notre site par ses
            visiteurs. Ces témoins sont parfois installés par des fournisseurs
            tiers de services d'analyse et de trafic Web. Toutes les
            informations recueillies par ces témoins sont agrégées et donc
            anonymes. Si vous n'autorisez pas ces témoins, nous ne saurons pas à
            quel moment les gens ont visité notre site et nous ne pourrons pas
            en contrôler la performance.
          </p>
          <table className="ui small collapsing celled table">
            <thead>
              <tr>
                <th>Sous-groupe de témoin</th>
                <th>Témoins</th>
                <th>Témoins utilisés</th>
                <th>Durée de vie</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Sous-groupe de témoin">
                  .connecting-families.ca
                </td>
                <td data-label="Témoins">_ga</td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">2 ans</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">
                  .connecting-families.ca
                </td>
                <td data-label="Témoins">_gid</td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">24 heures</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">
                  .connecting-families.ca
                </td>
                <td data-label="Témoins">_gat </td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">1 minute</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.cfsc-opec.org</td>
                <td data-label="Témoins">_gcl_au</td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">3 mois</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.cfsc-opec.org</td>
                <td data-label="Témoins">_ga</td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">2 ans</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.cfsc-opec.org</td>
                <td data-label="Témoins">_gid</td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">24 heures</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.cfsc-opec.org</td>
                <td data-label="Témoins">_gat_gtag</td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">1 minute</td>
              </tr>
            </tbody>
          </table>
          <b>
            <li>Témoins de ciblage</li>
          </b>
          <p>
            Ces témoins enregistrent votre visite sur notre site Web, les pages
            que vous avez visitées et les liens que vous avez suivis. Nous
            pourrions utiliser cette information pour rendre notre site Web et
            l'information qu'il présente (incluant l'information commerciale)
            mieux adaptés à vos champs d'intérêt, et pour mesurer l'efficacité
            de certaines initiatives publicitaires ou d'information. Dans ce
            but, nous pourrions également partager cette information avec des
            tiers. Ces témoins se souviennent des autres sites Web que vous
            visitez, et cette information est partagée avec des organisations
            tierces, suivant le processus décrit ci-dessous. Ces témoins sont
            également facultatifs. Si vous ne les autorisez pas, vous recevrez
            moins de publicités ciblées.
          </p>
          <table className="ui small collapsing celled table">
            <thead>
              <tr>
                <th>Sous-groupe de témoin</th>
                <th>Témoins</th>
                <th>Témoins utilisés</th>
                <th>Durée de vie</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Sous-groupe de témoin">.cfsc-opec.org</td>
                <td data-label="Témoins">_fbp</td>
                <td data-label="Témoins utilisé">Première partie</td>
                <td data-label="Durée de vie">3 mois</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.doubleclick.net</td>
                <td data-label="Témoins">test_cookie</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">15 mois</td>
              </tr>
              <tr>
                <td data-label="Sous-groupe de témoin">.doubleclick.net</td>
                <td data-label="Témoins">IDE</td>
                <td data-label="Témoins utilisé">Tierce partie</td>
                <td data-label="Durée de vie">1 an 24 jours</td>
              </tr>
            </tbody>
          </table>
        </ol>
        <h2>VII. Statistiques, liens et partage</h2>
        <p>
          Les sites Web, infolettres, mises à jour par courriel et autres
          communications de {cfscOpec} peuvent contenir de temps en temps des
          liens vers et depuis d'autres sites Web qui n'appartiennent pas ou ne
          sont pas gérés par {cfscOpec}. {cfscOpec} n'est aucunement responsable
          des pratiques de confidentialité de ces sites. Aussi nous encourageons
          les utilisateurs qui quittent un site Web de {cfscOpec} à examiner la
          politique de confidentialité propre à chacun des sites Web qu'ils
          visitent.
        </p>
        <p>
          La politique de confidentialité reflétée sur ce site Web s'applique
          aux Renseignements personnels obtenus par les sites Web de {cfscOpec}{' '}
          uniquement.
        </p>
        <p>
          Si vous suivez un lien vers n'importe quel autre site Web, veuillez
          noter que ces sites Web ont leurs propres avis de confidentialité, qui
          exposeront comment vos informations sont recueillies et traitées quand
          vous visitez ces sites. Les données personnelles que vous fournissez à
          ces sites Web ne sont pas couvertes par cette Politique de
          confidentialité, et le traitement de vos données personnelles par ces
          sites n'est pas la responsabilité de {cfscOpec}.
        </p>
        <h2>VIII. Modifications de la Politique</h2>
        <p>
          Les lois sur la protection de la vie privée évoluent rapidement et,
          par conséquent, {cfscOpec} pourrait modifier cette Politique de temps
          à autre, à son entière discrétion, sans préavis ni responsabilité à
          votre égard ou à l'égard de toute autre personne. La collecte,
          l'utilisation et la divulgation par {cfscOpec} de vos informations
          personnelles seront régies par la version la plus récente de cette
          Politique en vigueur à ce moment.
        </p>
        <p>
          {cfscOpec} encourage les utilisateurs à vérifier toutes les
          modifications et les mises à jour de cette Politique effectuées depuis
          leur dernière visite.
        </p>
        <h2>IX. Consentement</h2>
        <p>
          Chez {cfscOpec}, il est important de recueillir, utiliser ou partager
          vos Renseignements personnels uniquement quand nous avons votre
          consentement explicite pour le faire.
        </p>
        <p>
          {cfscOpec} s'assurera que les utilisateurs de nos sites Web
          comprennent comment leurs Renseignements personnels sont utilisés.{' '}
          {cfscOpec}
          demandera spécifiquement l'accord de l'utilisateur au moment de
          recueillir des Renseignements personnels.
        </p>
        <p>
          {cfscOpec} pourra divulguer les Renseignements personnels d'un
          utilisateur dans les cas où la loi l'exige.
        </p>
        <h2>X. Quels sont vos droits en matière de protection de données?</h2>
        <p>
          {cfscOpec} veut s'assurer que vous êtes entièrement conscients de tous
          vos droits en matière de protection de données personnelles:
        </p>
        <ul>
          <li>
            <b>Le droit d'accès </b> – Vous avez le droit de demander à
            {cfscOpec} si nous traitons vos données et, si oui, vous pouvez
            demander d'accéder à vos Renseignements personnels. Ceci vous permet
            d'obtenir une copie des données que nous possédons concernant vos
            Renseignements personnels.
          </li>
          <li>
            <b>Le droit à la rectification </b> – Vous avez le droit de demander
            que tout Renseignement personnel que possède {cfscOpec} qui s'avère
            inexact ou incomplet vous concernant soit corrigé. Vous avez
            également le droit de demander à {cfscOpec} de compléter les
            Renseignements personnels que vous considérez comme incomplets vous
            concernant.
          </li>
          <li>
            <b>Le droit à l'effacement</b> – Vous avez le droit de demander que
            {cfscOpec} supprime ou retire tout Renseignement personnel qu'il
            possède vous concernant, sous certaines conditions. Certaines
            exceptions existent où {cfscOpec} peut refuser une demande
            d'effacement. Par exemple, lorsque les données personnelles sont
            requises pour se conformer à la loi, ou sont liées à des
            réclamations.
          </li>
          <li>
            <b>Le droit de limiter le traitement</b> – Vous avez le droit de
            demander à {cfscOpec} de limiter ou suspendre le traitement de vos
            Renseignements personnels sous certaines conditions, notamment si
            vous voulez que {cfscOpec} établisse leur exactitude ou les raisons
            pour lesquelles {cfscOpec} traite ces informations.
          </li>
          <li>
            <b>Le droit de vos opposer au traitement </b> – Vous avez le droit
            de vous opposer au traitement de vos Renseignements personnels par
            {cfscOpec} sous certaines conditions, lorsque nous traitons vos
            données personnelles sur la base des intérêts légitimes de{' '}
            {cfscOpec} (ou ceux d'un tiers).
          </li>
          <li>
            <b>Le droit à la transférabilité des données </b> – Vous avez le
            droit de demander que {cfscOpec} transfère à une autre organisation,
            un autre parti, ou à vous-mêmes, les données qu'il a recueillies,
            sous certaines conditions.
          </li>
        </ul>
        <p>
          Si vous voulez exercer l'un ou plusieurs de ces droits, veuillez
          communiquer avec l'un de nos contacts en protection de la vie privée
          mentionnés plus au sein de cette Politique, en écrivant à l'adresse
          postale ou électronique correspondante.
        </p>
        <p>
          Vous avez également le droit de déposer une plainte auprès d'une
          autorité de contrôle de la protection des données, particulièrement
          dans la province du Canada (au niveau fédéral ou provincial), dans le
          pays, ou l'état membre de l'Union européenne où vous résidez
          habituellement, où {cfscOpec} est basé, ou à l'endroit où une
          violation alléguée de toute loi pertinente sur la protection des
          données a eu lieu.
        </p>
        <h2>XI. Contacter nos officiers de protection de la vie privée</h2>
        <p>
          Pour toute question, préoccupation ou commentaire concernant la
          Politique de confidentialité de {cfscOpec}, ou si vous voulez
          consulter, modifier ou supprimer de l'information concernant vos
          Renseignements personnels, ou exercer un droit applicable, déposer une
          plainte, ou obtenir de l'information sur nos pratiques et nos
          politiques relatives à tout fournisseur de services hors Canada, notre
          Officier de protection de la vie privée peut être joint par courriel à
          oviders outside Canada, our Privacy Officer can be reached by email at{' '}
          <a href="mailto:info@cfsc-opec.org">info@cfsc-opec.org</a> ou par la
          poste aux coordonnées suivantes:
        </p>
        <Container textAlign="center">
          <p>Computers for Success – Canada Incorporated</p>
          <p>
            Attn: <b>Toby Harper Merrett</b>
          </p>
          <p>Officier de protection de la vie privée</p>
          <p>1, carrefour Alexander Graham Bell, Suite 2B</p>
          <p>Verdun, Québec, H3E 3B3, Canada</p>
        </Container>
        <p>
          Sous réserve de certaines exceptions prévues par la loi, il vous sera
          donné un accès raisonnable à votre information personnelle et vous
          aurez le droit de contester l'exactitude et l'exhaustivité de cette
          information et, dans la limite où vous aurez prouvé cette inexactitude
          ou incomplétude, de la faire modifier comme il convient. Des exemples
          de ces exceptions comprennent les informations contenant des
          références à d'autres individus, et les informations qui ne peuvent
          être divulguées pour des raisons légales, sécuritaires, ou
          d'exclusivité commerciale.
        </p>
        <p>
          Vous pouvez aider {cfscOpec} à tenir à jour l'exactitude de votre
          information en notifiant {cfscOpec} de tout changement dans vos
          Renseignements personnels.
        </p>
        <p>
          Si vous avez des questions ou des plaintes relatives aux politiques ou
          aux procédures de {cfscOpec} concernant le maniement des
          renseignements personnels, nous vous invitons à contacter notre
          Officier de protection de la vie privée.
        </p>
      </React.Fragment>
    );

    return (
      <Container text={true}>{this.props.locale === 'en' ? en : fr}</Container>
    );
  }
}

export const DisclosurePolicy = withNamespaces()(Component);
