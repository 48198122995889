import * as React from 'react';
import ReactModal from 'react-modal';
import { IconButtonLink } from './common/IconButtonLink';
import './Modal.css';

interface IProps extends ReactModal.Props {
  closeButtonText: string;
}

export class Modal extends React.Component<IProps> {
  public render() {
    const { closeButtonText, ...modalProps } = this.props;

    const wrappedChildren = (
      <div>
        <div className="modal-close">
          <IconButtonLink
            onClick={(e) => {
              e.preventDefault();
              if (this.props.onRequestClose) {
                this.props.onRequestClose(e);
              }
            }}
            iconName="chevron left"
          >
            {this.props.closeButtonText}
          </IconButtonLink>
        </div>
        <div className="modal-so">
          <div className="modal-container">{this.props.children}</div>
        </div>
      </div>
    );

    return (
      <ReactModal className="modal-so-content" {...modalProps}>
        {wrappedChildren}
      </ReactModal>
    );
  }
}
