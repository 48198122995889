import { IAddress, IFreeComputerInfo } from './api';
import { v4 as uuidv4 } from 'uuid';

/* General Action Types and Action Creators */

export enum ActionKeys {
  SET_LOCALE = 'SET_LOCALE',
  NOOP = 'NOOP',
  CLEAR_SERVICE_ADDRESS = 'CLEAR_SERVICE_ADDRESS',
  SET_MAILING_ADDRESS = 'SET_MAILING_ADDRESS',
  SET_SERVICE_ADDRESS = 'SET_SERVICE_ADDRESS',
  SET_FREE_COMPUTER_INFO = 'SET_FREE_COMPUTER_INFO',
  SET_CONTACT_INFO = 'SET_CONTACT_INFO',
  SET_PROVIDER = 'SET_PROVIDER',
  ADD_ACCESSIBILITY_MESSAGE = 'ADD_ACCESSIBILITY_MESSAGE',
  ADD_DISABLED_COMPUTER_PROVINCE = 'ADD_DISABLED_COMPUTER_PROVINCE',
  REMOVE_ACCESSIBILITY_MESSAGE = 'REMOVE_ACCESSIBILITY_MESSAGE',
  SET_PROVIDER_INTERNET_SERVICE = 'SET_PROVIDER_INTERNET_SERVICE',
}

/* Various interfaces and types */

export interface IContactInfo {
  confirmEmail?: string;
  email?: string;
  phone?: string;
}

/* Action Types */

export interface INoopAction {
  type: ActionKeys.NOOP;
}

export interface ISetLocale {
  locale: 'en' | 'fr';
  type: ActionKeys.SET_LOCALE;
}

export interface IClearServiceAddress {
  type: ActionKeys.CLEAR_SERVICE_ADDRESS;
}

export interface ISetMailingAddress {
  type: ActionKeys.SET_MAILING_ADDRESS;
  address: IAddress;
}

export interface ISetServiceAddress {
  type: ActionKeys.SET_SERVICE_ADDRESS;
  address: IAddress;
}

export interface ISetFreeComputerInfo {
  type: ActionKeys.SET_FREE_COMPUTER_INFO;
  info: IFreeComputerInfo;
}

export interface ISetContactInfo {
  contactInfo: IContactInfo;
  type: ActionKeys.SET_CONTACT_INFO;
}

export interface ISetProvider {
  provider: string;
  type: ActionKeys.SET_PROVIDER;
}

export interface IAddAccessibilityMessage {
  key: string;
  message: string;
  type: ActionKeys.ADD_ACCESSIBILITY_MESSAGE;
}

export interface IAddDisabledComputerProvince {
  province: string;
  type: ActionKeys.ADD_DISABLED_COMPUTER_PROVINCE;
}

export interface IRemoveAccessibilityMessage {
  key: string;
  message: string;
  type: ActionKeys.REMOVE_ACCESSIBILITY_MESSAGE;
}

export interface ISetProviderInternetService {
  providerInternetService: boolean;
  type: ActionKeys.SET_PROVIDER_INTERNET_SERVICE;
}

export type ActionTypes =
  | INoopAction
  | ISetLocale
  | IClearServiceAddress
  | ISetMailingAddress
  | ISetServiceAddress
  | ISetFreeComputerInfo
  | ISetContactInfo
  | ISetProvider
  | IAddDisabledComputerProvince
  | IAddAccessibilityMessage
  | IRemoveAccessibilityMessage
  | ISetProviderInternetService;

/* Action Creators */

export const noop = (): INoopAction => ({
  type: ActionKeys.NOOP,
});

export const setLocale = (locale: 'en' | 'fr'): ISetLocale => {
  return {
    locale,
    type: ActionKeys.SET_LOCALE,
  };
};

export const clearServiceAddress = (): IClearServiceAddress => ({
  type: ActionKeys.CLEAR_SERVICE_ADDRESS,
});

export const setMailingAddress = (address: IAddress): ISetMailingAddress => ({
  address,
  type: ActionKeys.SET_MAILING_ADDRESS,
});

export const setServiceAddress = (address: IAddress): ISetServiceAddress => ({
  address,
  type: ActionKeys.SET_SERVICE_ADDRESS,
});

export const setFreeComputerInfo = (
  info: IFreeComputerInfo
): ISetFreeComputerInfo => ({
  info,
  type: ActionKeys.SET_FREE_COMPUTER_INFO,
});

export const setContactInfo = (contactInfo: IContactInfo): ISetContactInfo => {
  const updatedContactInfo: IContactInfo = {};
  if (contactInfo.email && contactInfo.email !== '') {
    updatedContactInfo.email = contactInfo.email;
  }
  if (contactInfo.confirmEmail && contactInfo.confirmEmail !== '') {
    updatedContactInfo.confirmEmail = contactInfo.confirmEmail;
  }
  if (contactInfo.phone && contactInfo.phone !== '') {
    updatedContactInfo.phone = contactInfo.phone;
  }
  return {
    contactInfo: updatedContactInfo,
    type: ActionKeys.SET_CONTACT_INFO,
  };
};

export const setProvider = (provider: string): ISetProvider => ({
  provider,
  type: ActionKeys.SET_PROVIDER,
});

export const setProviderInternetService = (
  providerInternetService: boolean
): ISetProviderInternetService => ({
  providerInternetService,
  type: ActionKeys.SET_PROVIDER_INTERNET_SERVICE,
});

export const addDisabledComputerProvince = (
  province: string
): IAddDisabledComputerProvince => ({
  province,
  type: ActionKeys.ADD_DISABLED_COMPUTER_PROVINCE,
});

export const addAccessibilityMessage = (
  message: string
): IAddAccessibilityMessage => ({
  key: uuidv4(),
  message,
  type: ActionKeys.ADD_ACCESSIBILITY_MESSAGE,
});

export const removeAccessibilityMessage = (
  message: string,
  key: string
): IRemoveAccessibilityMessage => ({
  key,
  message,
  type: ActionKeys.REMOVE_ACCESSIBILITY_MESSAGE,
});
