import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Container } from '../../semantic';
import get from 'lodash/get';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { setContactInfo, IContactInfo } from '../../actions/app';
import { BackButton } from '../common';
import { IAppState } from '../../reducers';
import { addAccessibilityMessage } from '../../actions/app';
import { ContactForm } from '../ContactForm';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import { ButtonLink } from '../common/ButtonLink';

import './ContactInfo.css';

interface IStateProps {
  contactInfo?: IContactInfo;
  addAccessibilityMessage: typeof addAccessibilityMessage;
  sufficientState: boolean;
}

interface IDispatchProps {
  push: typeof push;
  setContactInfo: typeof setContactInfo;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.contactInfo')
    );
    return;
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.contactInfo.title')} />
        <BackButton />
        <div>
          <Heading>{props.t('screens.contactInfo.prompt')}</Heading>
        </div>
        <ContactForm
          initialValues={{
            confirmEmail: get(props, 'contactInfo.confirmEmail', ''),
            email: get(props, 'contactInfo.email', ''),
            phone: get(props, 'contactInfo.phone', ''),
          }}
          onSubmit={(values) => {
            props.addAccessibilityMessage(
              props.t('aria.messages.contactInfoSubmit')
            );
            props.setContactInfo(values);
            props.push(props.t('paths.mailingAddressEntry'));
          }}
          submitButtonText={props.t('buttons.continue')}
          confirmEmailError={
            <Trans i18nKey="formValidation.emailMatchSkip">
              Sorry, the email address and confirmation email address don’t
              match. Please make them match or{' '}
              <Link to={props.t('paths.mailingAddressEntry')}>
                proceed without providing your contact details
              </Link>
            </Trans>
          }
        />

        <p className="skip-this-step">
          <span>{props.t('screens.contactInfo.span1')}</span>
          {', '}
          <strong>
            <ButtonLink
              onClick={(e) => {
                e.preventDefault();
                props.push(props.t('paths.mailingAddressEntry'));
              }}
            >
              {props.t('screens.contactInfo.skipThisStep')}
            </ButtonLink>
          </strong>
          .
        </p>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin);
};

const mapStateToProps = (state: IAppState) => {
  return {
    contactInfo: state.contactInfo,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { push, addAccessibilityMessage, setContactInfo },
    dispatch
  );
};

export const ContactInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Component));
