import * as React from 'react';
import { Container } from '../../semantic';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { BackButton } from '../common';
import { addAccessibilityMessage } from '../../actions/app';
import { IAppState } from '../../reducers';
import letterImage from './cfsc-letter.png';
import letterImage2x from './cfsc-letter@2x.png';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';

interface IStateProps {
  sufficientState: boolean;
}

interface IDispatchProps {
  push: typeof push;
  addAccessibilityMessage: typeof addAccessibilityMessage;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.noPermission')
    );
    return;
  }

  public render() {
    const { props } = this;
    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.noPermission.title')} />
        <BackButton />
        <div>
          <Heading>{props.t('screens.noPermission.heading')}</Heading>
          <p>
            <Trans i18nKey="screens.noPermission.paragraph1">
              To start over, just return to{' '}
              <Link to="/">connecting-families.ca</Link>.{' '}
              <strong>You will need the original letter</strong> you received in
              the mail, so make sure to keep it in a safe place.
            </Trans>
          </p>
          <p>{props.t('screens.noPermission.paragraph2')}</p>
        </div>
        <div className="letter-image-container">
          <img
            src={letterImage}
            srcSet={`${letterImage}, ${letterImage2x} 2x`}
            width="375"
            height="350"
            alt={props.t('general.letterImage')}
          />
        </div>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ addAccessibilityMessage, push }, dispatch);
};

export const NoPermission = withNamespaces()(
  connect<{}, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
