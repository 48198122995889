import * as React from 'react';
import { IAddress } from '../actions/api';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import './AddressBubble.css';

interface IAddressBubbleProps {
  address: IAddress;
}

export const AddressBubbleImpl = (
  props: IAddressBubbleProps & WithNamespaces
) => {
  const { address, t } = props;
  const { line1, line2, city, province, postalCode } = address;
  const fullProvince = t(`provinces.${province}`);

  return (
    <div className="address-bubble">
      {line1}
      <br />
      {line2 && (
        <React.Fragment>
          {line2}
          <br />
        </React.Fragment>
      )}
      {`${city}, `}
      <span aria-hidden="true">{province}</span>
      <span className="screen-reader-only">{fullProvince}</span>
      {`, ${postalCode}`}
    </div>
  );
};

export const AddressBubble = withNamespaces()(AddressBubbleImpl);
